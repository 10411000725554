import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Card, CardBody, CardTitle, Col, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, Table } from "reactstrap";
import SidebarNav from "./SidebarNav";
import Translate from "../../shared/components/Translate";
import userActions from "../../redux/user/actions";
import { appConfig } from "../../config/app";
import Highlight from 'react-highlight';
import 'highlight.js/styles/dark.css';

const {
  userGenerateApiKey,
  userUpdateSettings,
} = userActions;

class API extends Component {
  state = {
    api_key: null,
    activeTab: 0,
  }

  render() {
    const { userData } = this.props;

    return (
      <div className="main-content view-api-key">
        <div className="page-content">
          <div className="settings-area">
            <div className="settings-menu">
              <SidebarNav />
            </div>
            <div className="settings-content">
              <Card>
                <CardBody>
                  <CardTitle className="mb-0"><Translate>API Access</Translate></CardTitle>
                  <p><Translate special_data="api_key_desc">Generate your API key to get access to Rating Fish API.</Translate></p>
                  <Row>
                    <Col xl={6}>
                    {userData?.settings?.api_key ?
                    <React.Fragment>
                      <FormGroup>
                        <Label><Translate>Your API Key</Translate></Label>
                        <Input
                          value={userData?.settings?.api_key}
                          readOnly
                          onFocus={(e) => { e.target.select() }}
                        />
                      </FormGroup>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => this.props.userGenerateApiKey()}
                      ><Translate>Regenerate API key</Translate></Button>
                    </React.Fragment>
                    :
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => this.props.userGenerateApiKey()}
                    ><Translate>Generate API key</Translate></Button>
                    }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle>API Documentation</CardTitle>
                  <div className="mb-2 text-dark">
                    <span>Version: {appConfig.api_documentation.version}</span><br/>
                    <span>Last Updated: {appConfig.api_documentation.updated}</span>
                  </div>
                    <p>Rating Fish API provide programmatic access to functionalities as listed below. To use this API, you need to <b>generate API key</b>.<br/>
                    API key need to be sent with every request made to API, as query parameter <b>key</b>.</p>
                    <p>API base URL: <b>https://backend.ratingfish.com/api/v1.0/external</b><br/>
                    Example: <b>GET https://backend.ratingfish.com/api/v1.0/external/website?key={userData?.settings?.api_key}</b></p>
                  <div className="d-flex gap-2 mt-4">
                    <div>
                      <Nav vertical>
                        {Object.keys(appConfig.api_documentation.endpoints).map((endpoint_id, idx) => {
                          return (
                            <NavItem key={`api_nav_${idx}`}>
                              <NavLink
                                className="text-nowrap ps-0 pt-0"
                                role="button"
                                active={this.state.activeTab === idx}
                                onClick={() => this.setState({ activeTab: idx })}
                              >{endpoint_id}</NavLink>
                            </NavItem>
                          )
                        })}
                      </Nav>
                    </div>
                    <div style={{ flex: "1 0 auto"}}>
                      <TabContent activeTab={this.state.activeTab}>
                        {Object.keys(appConfig.api_documentation.endpoints).map((endpoint_id, idx) => {
                          let endpoint = appConfig.api_documentation.endpoints[endpoint_id];
                          return (
                            <TabPane tabId={idx} key={`tab-pane-${endpoint_id}`}>
                              <h5 className="h6">{endpoint_id}</h5>
                              <p className="mb-2">{endpoint.desc}</p>
                              {Object.keys(endpoint.methods).map((method_id, m_idx) => {
                                let method = endpoint.methods[method_id];
                                return (
                                  <React.Fragment key={`method-id-${endpoint_id}-${method_id}`}>
                                    <p><code>{method_id} {endpoint.endpoint}</code></p>
                                    {method.params.query?.length > 0 &&
                                      <React.Fragment>
                                        <strong className="d-block">Query Parameters</strong>
                                        <Table>
                                          <thead>
                                            <tr>
                                              <th>Field</th>
                                              <th>Type</th>
                                              <th>Description</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {method.params.query.map(param => {
                                              return (
                                                <tr key={`method-id-${endpoint_id}-${method_id}-${param.param}`}>
                                                  <td><code>{param.param}</code></td>
                                                  <td>{param.type}</td>
                                                  <td>{param.desc}</td>
                                                </tr>
                                              )
                                            })}
                                          </tbody>
                                        </Table>
                                      </React.Fragment>
                                    }
                                    {method?.sample &&
                                      <Highlight className="bash mt-2">
                                        {method.sample}
                                      </Highlight>
                                    }
                                  </React.Fragment>
                                )
                              })}
                            </TabPane>
                          )
                        })}
                      </TabContent>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    user_data_saving: state.User.get("user_data_saving"),
  }),
  {
    userGenerateApiKey,
    userUpdateSettings,
  }
)(API);
