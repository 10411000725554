import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Router,
  Route,
  Switch,
  Redirect,
  withRouter
} from 'react-router-dom';
import { appConfig } from './config/app';
import { Container } from 'reactstrap';
import LeftNavigation from './shared/components/LeftNavigation';
import TopBar from './shared/components/TopBar';
import authActions from './redux/auth/actions';

// Views
import Dashboard from './views/Dashboard';
import ForgotPassword from './views/ForgotPassword';
import Login from './views/Login';
import Register from './views/Register';
import Operators from './views/Operators';
import AuthSlack from './views/AuthSlack';
import AuthIntercom from './views/AuthIntercom';
import MyAccount from './views/Settings/MyAccount';
//import MyPlan from './views/Settings/MyPlan';
import Integrations from './views/Settings/Integrations';
import OperatorReview from './views/Operators/OperatorReview';
import ExcludedOperators from './views/Operators/ExcludedOperators';
import Segmentation from './views/Settings/Segmentation';
import Admin from './views/Admin';
import Surveys from './views/Surveys';
import NewPassword from './views/NewPassword';
import Survey from './views/Settings/Survey';
import EmployeeAccounts from './views/Settings/EmployeeAccounts';
import FilterSources from './views/Settings/FilterSources';
import Reviews from "./views/Reviews";
import CreateReview from "./views/Reviews/CreateReview";
import API from "./views/Settings/API";
import Webhooks from './views/Settings/Webhooks';
import ExternalSurvey from "./views/ExternalSurvey";

const { userRefreshToken, userLogout } = authActions;

const RouteUser = ({ component: Component, isLoggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        isLoggedIn ? (
          <TemplateUser>
            <Component {...matchProps} />
          </TemplateUser>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: matchProps.location }
            }}
          />
        )
      )}
    />
  );
}

class TemplateUser extends Component {
  render() {
    const {
      children
    } = this.props;

    return (
      <div className="app">
        <div className="content">
          <TopBar />
          <Container fluid className="content-bottom">
            <LeftNavigation />
            <div className="right-content">
              {children}
            </div>
          </Container>
        </div>
      </div>
    )
  }
}

TemplateUser = withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
    }),
    {
    }
  )(TemplateUser)
);

class AppPrivateRoutes extends Component {

  componentDidMount(){
    //this.props.userRefreshToken();
    setInterval(() => {
      this.props.userRefreshToken();},
      appConfig.auth.refreshTokenFrequency
    );
  }

  componentDidUpdate(prevProps) {
  }

  render(){
    return (this.props.children);
  }
}

AppPrivateRoutes = withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
    }),
    {
      userRefreshToken,
    }
  )(AppPrivateRoutes)
);

class AppRoutes extends Component {
  render() {
    const {
      history,
      isLoggedIn
    } = this.props;

    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/forgot_password" component={ForgotPassword} />
          <Route exact path="/reset/password" component={NewPassword} />
          <Route exact path="/slack/auth" component={AuthSlack} />
          <Route exact path="/intercom/auth" component={AuthIntercom} />
          <Route exact path="/survey/email" component={ExternalSurvey} />
          <Route path="/rating/">
            <AppPrivateRoutes location={this.props.location}>
              <RouteUser
                exact path="/rating/dashboard"
                component={Dashboard}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/rating/operators"
                component={Operators}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/rating/operators/review/:website_id/:operator_id"
                component={OperatorReview}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/rating/operators/excluded"
                component={ExcludedOperators}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/rating/settings/my_account"
                component={MyAccount}
                isLoggedIn={isLoggedIn}
              />
              {/*
              <RouteUser
                exact path="/rating/my_plan"
                component={MyPlan}
                isLoggedIn={isLoggedIn}
              />
              */}
              <RouteUser
                exact path="/rating/settings/integrations"
                component={Integrations}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/rating/settings/segmentation"
                component={Segmentation}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/rating/settings/survey"
                component={Survey}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/rating/settings/employee_accounts"
                component={EmployeeAccounts}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/rating/settings/filter_sources"
                component={FilterSources}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/rating/admin"
                component={Admin}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/rating/all_surveys"
                component={Surveys}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/rating/reviews"
                component={Reviews}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/rating/reviews/create"
                component={CreateReview}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/rating/settings/api"
                component={API}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/rating/settings/webhooks"
                component={Webhooks}
                isLoggedIn={isLoggedIn}
              />
            </AppPrivateRoutes>
          </Route>
          <Redirect from="/" to="/login" />
        </Switch>
      </Router>
    )
  }
}

export default connect(
  state => ({
    isLoggedIn: state.Auth.get("access_token") !== null,
  }),
  {
    userRefreshToken,
    userLogout,
  }
)(AppRoutes);
