import { Component } from 'react';
import { connect } from "react-redux";
import { translate_text } from "../../../helpers/translation/utils";

class Translate extends Component {

  translate = (text) => {
    const { userData, special_data } = this.props;

    return translate_text(text, userData?.settings?.language, special_data);
  };


  render(){
    const { children } = this.props;
    return this.translate(children);
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
  }),
  {
  }
)(Translate);
