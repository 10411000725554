import RS from "./languages/RS.json"
import FR from "./languages/FR.json"
import DE from "./languages/DE.json"
import PT from "./languages/PT.json"
import ES from "./languages/ES.json"
import AE from "./languages/AE.json"
import KR from "./languages/KR.json"

const languages = {
  "RS": RS,
  "FR": FR,
  "DE": DE,
  "PT": PT,
  "ES": ES,
  "KR": KR,
  "AE": AE,
}

export default languages;

