import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap';
import adminActions from "../../redux/admin/actions";

const { adminUpdateAppSettings } = adminActions;

class Transparency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
      transparency: {},
      yyyy: "",
      mm: "",
      mmr: "",
    };
    this.ref = {};
  }

  addChartData = () => {
    const { yyyy, mm, mmr } = this.state;
    if(!yyyy || !mm || !mmr){
      return;
    }
    const { userData } = this.props;
    var transparency_data = {...userData?.app_settings?.transparency_data};

    const data = {
      "id": Date.now(),
      "yyyy_mm": `${yyyy}_${mm}`,
      "yyyy": yyyy,
      "mm": mm,
      "mmr": mmr,
    }

    if(!transparency_data?.chart_data){
      transparency_data['chart_data'] = [{ ...data }];
    } else {
      transparency_data?.chart_data.push({ ...data })
    }

    this.props.adminUpdateAppSettings({transparency_data: transparency_data})
    this.setState({
      yyyy: "",
      mm: "",
      mmr: "",
    });
  }

  removeChartData = (id) => {
    const { userData } = this.props;
    var transparency_data = {...userData?.app_settings?.transparency_data};

    transparency_data.chart_data = transparency_data.chart_data.filter(item => item?.id !== id);

    this.props.adminUpdateAppSettings({transparency_data: transparency_data})
  }

  updateTransparency = (key, data) => {
    if (!/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(data) && data) {
      return
    }

    var transparency = {...this.state.transparency};
    transparency[key] = data;
    this.setState({ transparency: transparency });
  }

  saveData = () => {
    const { userData } = this.props;
    const { transparency } = this.state;
    var transparency_data = {...userData?.app_settings?.transparency_data};
    ["subscribers", "monthly_reccuring_revenue", "annual_run_rate",
      "team_size", "monthly_growth", "chrun_rate", "total_csr_helped",
      "survey_count"
    ].forEach(key => {
        if(!!transparency[key]){
          transparency_data[key] = transparency[key];
        }
      })
    this.props.adminUpdateAppSettings({transparency_data: transparency_data})
    this.setState({ transparency: {} });
  }

  updateMMR = (data) => {
    if (!/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(data) && data) {
      return
    }
    this.setState({ mmr: data });
  }

  updateNumber = (key, data) => {
    if (!/^\d+$/.test(data) && data) {
      return
    }
    this.setState({ [key]: data });
  }

  render() {
    const { userData } = this.props;
    const { transparency } = this.state;

    return (
      <div className="admin-transparency">
        <section>
          <h6>Metrics Data Input</h6>
          <Row>
            <FormGroup row>
              <Col sm={4}>
                <Label>Subscribers</Label>
                <Input
                  type="text"
                  value={transparency?.subscribers || transparency?.subscribers === ""
                    ? transparency?.subscribers
                    : userData?.app_settings?.transparency_data?.subscribers
                      ? userData?.app_settings?.transparency_data?.subscribers
                      : ""
                  }
                  //onChange={(e) => this.updateTransparency('subscribers', e.target.value)}
                  disabled={true}
                />
              </Col>
              <Col sm={4}>
                <Label>Monthly Recurring Revenue</Label>
                <InputGroup>
                  <InputGroupText>$</InputGroupText>
                  <Input
                    type="text"
                    value={transparency?.monthly_reccuring_revenue || transparency?.monthly_reccuring_revenue === ""
                      ? transparency?.monthly_reccuring_revenue
                      : userData?.app_settings?.transparency_data?.monthly_reccuring_revenue
                        ? userData?.app_settings?.transparency_data?.monthly_reccuring_revenue
                        : ""
                    }
                    //onChange={(e) => this.updateTransparency('monthly_reccuring_revenue', e.target.value)}
                    disabled={true}
                  />
                </InputGroup>
              </Col>
              <Col sm={4}>
                <Label>Annual Run Rate</Label>
                <InputGroup>
                  <InputGroupText>$</InputGroupText>
                  <Input
                    type="text"
                    value={transparency?.annual_run_rate || transparency?.annual_run_rate === ""
                      ? transparency?.annual_run_rate
                      : userData?.app_settings?.transparency_data?.annual_run_rate
                        ? userData?.app_settings?.transparency_data?.annual_run_rate
                        : ""
                    }
                    //onChange={(e) => this.updateTransparency('annual_run_rate', e.target.value)}
                    disabled={true}
                  />
                </InputGroup>
              </Col>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup row>
              <Col sm={4}>
                <Label># of Surveys Sent</Label>
                <Input
                  type="text"
                  value={transparency?.survey_count || transparency?.survey_count === ""
                    ? transparency?.survey_count
                    : userData?.app_settings?.transparency_data?.survey_count
                      ? userData?.app_settings?.transparency_data?.survey_count
                      : ""
                  }
                  //onChange={(e) => this.updateTransparency('team_size', e.target.value)}
                  disabled={true}
                />
              </Col>
              <Col sm={4}>
                <Label>Total CSRs Helped</Label>
                <InputGroup>
                  <Input
                    type="text"
                    value={transparency?.total_csr_helped || transparency?.total_csr_helped === ""
                      ? transparency?.total_csr_helped
                      : userData?.app_settings?.transparency_data?.total_csr_helped
                        ? userData?.app_settings?.transparency_data?.total_csr_helped
                        : ""
                    }
                    //onChange={(e) => this.updateTransparency('total_csr_helped', e.target.value)}
                    disabled={true}
                  />
                </InputGroup>
              </Col>
              <Col sm={4}>
                <Label>Churn Rate</Label>
                <InputGroup>
                  <Input
                    type="text"
                    value={transparency?.chrun_rate || transparency?.chrun_rate === ""
                      ? transparency?.chrun_rate
                      : userData?.app_settings?.transparency_data?.chrun_rate
                        ? userData?.app_settings?.transparency_data?.chrun_rate
                        : ""
                    }
                    onChange={(e) => this.updateTransparency('chrun_rate', e.target.value)}
                  />
                  <InputGroupText>%</InputGroupText>
                </InputGroup>
              </Col>
            </FormGroup>
          </Row>
        </section>

        <section className="mt-4">
          <h6>Chart Data Input</h6>
            {userData?.app_settings?.transparency_data?.chart_data
                && userData?.app_settings?.transparency_data?.chart_data.map((item, idx) => (
            <FormGroup key={idx} className="hstack gap-2" >
              <InputGroup style={{ width: "12rem" }}>
                <Input
                  disabled
                  value={item?.yyyy}
                />
                <InputGroupText>-</InputGroupText>
                <Input
                  disabled
                  value={item?.mm}
                />
              </InputGroup>
              <InputGroup style={{ width: "16rem" }}>
                <InputGroupText>$</InputGroupText>
                <Input
                  disabled
                  value={item?.mmr}
                />
              </InputGroup>
              <Button
                color="danger"
                onClick={() => this.removeChartData(item?.id)}
              ><span className="icon icon-trash bg-white" /></Button>
            </FormGroup>
          ))}

          <FormGroup className="hstack gap-2">
            <InputGroup style={{ width: "12rem" }}>
              <Input
                type="text"
                placeholder="yyyy"
                value={this.state.yyyy}
                onChange={(e) => this.updateNumber("yyyy", e.target.value)}
              />
              <InputGroupText>-</InputGroupText>
              <Input
                type="text"
                placeholder="MM"
                value={this.state.mm}
                onChange={(e) => this.updateNumber("mm", e.target.value)}
              />
            </InputGroup>
            <InputGroup style={{ width: "16rem" }}>
              <InputGroupText>$</InputGroupText>
              <Input
                type="text"
                placeholder="MRR"
                value={this.state.mmr}
                onChange={(e) => this.updateMMR(e.target.value)}
              />
            </InputGroup>
            <Button
              color="success"
              onClick={() => this.addChartData()}
            >Add</Button>
          </FormGroup>
        </section>
        <div className="mt-5">
          <Button
            color="primary"
            onClick={() => this.saveData()}
            disabled={this.props.app_settings_loading}
          >Save changes</Button>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    app_settings_loading: state.Admin.get("app_settings_loading"),
    userData: state.User.get("userData"),
  }),
  {
    adminUpdateAppSettings,
  }
)(Transparency);
