import React, { Component } from 'react';
import { connect } from "react-redux";
import Translate from "../../shared/components/Translate";
import { Button, Card, CardBody, Col, FormGroup, Label, Row, Spinner } from "reactstrap";
import Select from 'react-select';
import { amazing, bad, great, ok, terrible } from '../../assets/images';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import './style.css';
import dashboardActions from "../../redux/dashboard/actions";
import moment from "moment";
import { convertToHTML } from "draft-convert";
import { NavLink } from "react-router-dom";
import DateIntervalDropdown from '../../shared/components/DateIntervalDropdown';

const {
  dashboardOperators,
  dashboardTopStats,
  dashboardOperatorsRatio,
  operatorReviewGenerate,
  operatorReviewSendEmail,
  operatorReviewScheduledCreate,
} = dashboardActions;

const ratingMap = {
  1 : { emoji: terrible, text: "Terrible" },
  2 : { emoji: bad, text: "Bad" },
  3 : { emoji: ok, text: "OK" },
  4 : { emoji: great, text: "Great" },
  5 : { emoji: amazing, text: "Amazing" }
}

class CreateReview extends Component {
  state = {
    defaultPrompt: "Default prompt text to generate review",
    agent: null,
    editorState: EditorState.createEmpty(),
    interval: null,
    deliver_to: null,
    repeat: null,
    period: {
      value: null,
      label: <span className="option-placeholder"><Translate>Select...</Translate></span>
    },
    showIntervalDropdown: false,
    range: {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 0),
      key: 'selection'
    },
  }

  toggleIntervalDropdown = () => {
    this.setState({
      showIntervalDropdown: !this.state.showIntervalDropdown
    })
  }

  customRangesChange = (ranges) => {
    this.setState({
      range: ranges['selection'],
    })
  }

  applyRange = () => {
    const { range } = this.state;

    const start = moment(range.startDate).format("YYYYMMDD");
    const end = moment(range.endDate).format("YYYYMMDD");
    this.selectChangeSearch(
      "period",
      {value: `${start}-${end}`, label: <Translate>Custom</Translate>}
    );
  }

  createTimeDropdown = () => {
    const options = [
      {value: `last_month`, label: <Translate>Last Month</Translate>},
      {value: `last_quarter`, label: <Translate>Last Quarter</Translate>},
    ]
    return options;
  }

  operatorReviewScheduledCreate = () => {
    const { agent, period, deliver_to, repeat } = this.state;
    if(!agent || !period?.value || !deliver_to || !repeat){
      return;
    }
    this.props.operatorReviewScheduledCreate({
      review_period: period.value,
      deliver_to: deliver_to.value,
      repeat_frequency: repeat.value,
      operator_id: agent.value,
    });
  }

  componentDidMount = () => {
    this.props.dashboardOperators({filter_option: "active"})
  }

  createOperatorDropdown = () => {
    const { operators_data } = this.props;
    var data = [];
    if(operators_data){
      operators_data.forEach(item => {
        data.push({
          value: item.user_id,
          label: item.first_name,
        })
      })
    }
    return data
  }

  selectChangeSearch = (field, value) => {
    const { agent, period } = this.state;
    this.setState({ [field]: value })
    if(field === "agent" && !period?.value){ return; }
    if(field === "period" && !agent){ return; }
    if(field !== "agent" && field !== "period"){ return; }

    let interval = null;
    if(!["last_month", "last_quarter"].includes(value?.value) && field === "period" && value?.value){
      interval = value?.value;
    }
    if(!["last_month", "last_quarter"].includes(period?.value) && field === "agent" && period?.value){
      interval = value?.value;
    }

    if(value?.value === "last_month" || (period?.value === "last_month" && field === "agent")){
      const start_month = moment().startOf('month');
      const start_previous_month = moment().startOf('month').add(-1, 'months');
      interval = `${start_previous_month.format("YYYYMMDD")}-${start_month.format("YYYYMMDD")}`
    } else if (value?.value === "last_quarter" || (period?.value === "last_quarter" && field === "agent")){
      const start_qtr = moment().startOf('quarter');
      const start_previous_qtr = moment().startOf('quarter').add(-1, 'quarter');
      interval = `${start_previous_qtr.format("YYYYMMDD")}-${start_qtr.format("YYYYMMDD")}`
    }
    this.setState({ interval: interval })

    this.props.dashboardTopStats({
      user_id: agent.id,
      interval: interval,
    });
  }

  editorChange = (editorState) => {
    this.setState({ editorState })
  }

  generateReview = () => {
    this.props.operatorReviewGenerate({
      user_id: this.state.agent?.value,
      period: this.state.interval,
    })
  }

  componentDidUpdate = (pp) => {
    const { operator_review } = this.props;
    if(pp.operator_review !== operator_review && pp.operator_review === null){
      this.setState({
        editorState: EditorState.createWithContent(
          ContentState.createFromText(operator_review?.review ? operator_review?.review : "")
        )
      });
    }
  }

  sendReview = () => {
    if(this.state.deliver_to?.value === "email"){
      const html = convertToHTML(this.state.editorState.getCurrentContent());
      const blocks = convertToRaw(this.state.editorState.getCurrentContent()).blocks;
      const raw = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
      this.props.operatorReviewSendEmail({
        user_id: this.state.agent?.value,
        email: html,
        raw: raw,
      })
    }
  }

  render() {
    const {
      dashboard_top_data,
      dashboard_top_data_loading,
      operator_review,
      operator_review_loading,
      operator_review_email_load,
    } = this.props;

    const { agent, period, repeat, deliver_to } = this.state;

    return (
      <div className="main-content view-reviews">
        <div className="mb-3">
          <h2 className="page-title mb-0"><Translate>Create Review</Translate></h2>
          <NavLink to="/rating/reviews" className="hstack gap-1 mt-1"><span className="icon icon-back bg-primary" /> <span><Translate>Back</Translate></span></NavLink>
        </div>
        <div className="page-content">
          <Row>
            <Col ms={5}>
              <div className="mb-4">
                Create, schedule and send review for your agents automatically with the help of AI.
              </div>
              <FormGroup row className="align-items-center">
                <Col sm={4}>
                  <Label>Agent</Label>
                </Col>
                <Col sm={8}>
                  <Select
                    options={this.createOperatorDropdown()}
                    onChange={(value) => this.selectChangeSearch('agent', value) }
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Col sm={4}>
                  <Label>Review Period</Label>
                </Col>
                <Col sm={8}>
                  {/* <Select
                    options={[
                      {value: "last_month", label: 'Last Month'},
                      {value: "last_quarter", label: 'Last Quarter'},
                    ]}
                    onChange={(value) => this.selectChangeSearch('period', value) }
                    isDisabled={!agent}
                  /> */}
                  <DateIntervalDropdown
                    isOpen={this.state.showIntervalDropdown}
                    toggle={this.toggleIntervalDropdown}
                    selected={this.state.period}
                    options={this.createTimeDropdown()}
                    onSelect={(value) => this.selectChangeSearch('period', value)}
                    showCustom
                    customRanges={[this.state.range]}
                    customRangesChange={this.customRangesChange}
                    applyRange={this.applyRange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Col sm={4}>
                  <Label>Deliver to</Label>
                </Col>
                <Col sm={8}>
                  <Select
                    options={[
                      {value: "email", label: 'Email'},
                      //{value: "slack", label: 'Slack'},
                    ]}
                    isDisabled={!agent || !period?.value}
                    onChange={(value) => this.selectChangeSearch('deliver_to', value) }
                    isSearchable={false}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Col sm={4}>
                  <Label>Repeat this</Label>
                </Col>
                <Col sm={8}>
                  <Select
                    options={[
                      {value: 'month', label: 'Monthly'},
                      {value: 'qtr', label: 'Quarterly'},
                    ]}
                    isDisabled={!agent || !period?.value}
                    isClearable={true}
                    onChange={(value) => this.selectChangeSearch('repeat', value) }
                    isSearchable={false}
                  />
                </Col>
              </FormGroup>
              {/*
              <FormGroup>
                <Input
                  type="textarea"
                  rows="4"
                  value={this.state.defaultPrompt}
                  onChange={(e) => this.setState({ defaultPrompt: e.target.value })}
                />
              </FormGroup>
              */}
              <div className="hstack gap-3 justify-content-between">
                <Button
                  color="primary"
                  onClick={this.generateReview}
                  disabled={operator_review_loading}
                >Generate Review</Button>
                {(agent
                  && ['last_month', 'last_quarter'].includes(period?.value)
                  && repeat
                  && deliver_to) &&
                  <Button
                    onClick={() => this.operatorReviewScheduledCreate()}
                    disabled={operator_review_loading || !this.state.repeat}
                  >Schedule Review</Button>
                }
              </div>
            </Col>
            <Col sm={7}>
              <div className="review-stats-area">
                {dashboard_top_data_loading &&
                <Card>
                  <CardBody>
                    <div className="loading-indicator">
                      <Spinner color="secondary" /> <span><Translate>Loading...</Translate></span>
                    </div>
                  </CardBody>
                </Card>
                }
                {(dashboard_top_data && period?.value && agent) &&
                <div className="review-stats">
                  {/*
                  <div className="metric-simple-group">
                    <Card>
                      <CardBody>
                        <div className="metric-simple">
                          <h6 className="title"><Translate>Number of Surveys</Translate></h6>
                          <span className="value">123</span>
                        </div>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <div className="metric-simple">
                          <h6 className="title"><Translate>Comment Ratio</Translate></h6>
                          <span className="value">23%</span>
                        </div>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <div className="metric-simple">
                          <h6 className="title"><Translate>Rating Ratio</Translate></h6>
                          <span className="value">44%</span>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  */}
                  <Card>
                    <CardBody>
                      <div className="metric-rating-group">
                      {dashboard_top_data?.map((item, idx) =>
                        <div className="metric-rating" key={`rating-${idx}`}>
                          <img
                            src={ratingMap[item.rating].emoji}
                            alt={ratingMap[item.rating].text}
                            title={ratingMap[item.rating].text}
                          />
                          <span className="percentage">{item.percentage}%</span>
                          <span className="number">{item.count}</span>
                        </div>
                      )}
                      </div>
                    </CardBody>
                  </Card>
                </div>
                }
              </div>

              <div className="review-editor-area">
                {operator_review_loading &&
                  <div className="loading-indicator">
                    <Spinner color="secondary" /> <span><Translate>Generating review...</Translate></span>
                  </div>
                }
                {operator_review &&
                <React.Fragment>
                <Editor
                  editorState={this.state.editorState}
                  toolbar={{
                    options: [
                    'inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign',
                    'link', 'emoji', 'history'],
                    link: {
                      inDropdown: false,
                    },
                  }}
                  onEditorStateChange={this.editorChange}
                  wrapperClassName="review-editor-wrapper"
                />
                <Button
                  color="primary"
                  onClick={() => this.sendReview()}
                  disabled={operator_review_email_load}
                >Send Review</Button>
                </React.Fragment>
                }
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    dashboard_top_data: state.Dashboard.get("dashboard_top_data"),
    dashboard_top_data_loading: state.Dashboard.get("dashboard_top_data_loading"),
    operators_data: state.Dashboard.get("operators_data"),
    operators_data_loading: state.Dashboard.get("operators_data_loading"),
    operators_data_ratio: state.Dashboard.get("operators_data_ratio"),
    operators_data_ratio_loading: state.Dashboard.get("operators_data_ratio_loading"),
    userData: state.User.get("userData"),
    user_data_saving: state.User.get("user_data_saving"),
    operator_review: state.Dashboard.get("operator_review"),
    operator_review_loading: state.Dashboard.get("operator_review_loading"),
    operator_review_email_loading: state.Dashboard.get("operator_review_email_loading"),
  }),
  {
    dashboardOperators,
    dashboardTopStats,
    dashboardOperatorsRatio,
    operatorReviewGenerate,
    operatorReviewSendEmail,
    operatorReviewScheduledCreate,
  }
)(CreateReview);
