import React, { Component } from 'react';
import { Button, FormGroup, FormText, Input, InputGroup,
  InputGroupText, Label, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { amazing, bad, great, ok, terrible } from '../../assets/images';
import Translate from '../../shared/components/Translate';
import './style.css';
import { isObjectEmpty } from "../../helpers/utils";
import Select from 'react-select';
import { FlagIcon } from "react-flag-kit";
import { appConfig } from "../../config/app";

const lang_options = [
  {
    value: "US",
    label: <span><FlagIcon code="US" size={16} />&nbsp; <Translate>English</Translate></span>
  },
  {
    value: "RS",
    label: <span><FlagIcon code="RS" size={16} />&nbsp; <Translate>Serbian</Translate></span>
  },
  {
    value: "FR",
    label: <span><FlagIcon code="FR" size={16} />&nbsp; <Translate>French</Translate></span>
  },
  {
    value: "DE",
    label: <span><FlagIcon code="DE" size={16} />&nbsp; <Translate>German</Translate></span>
  },
  {
    value: "PT",
    label: <span><FlagIcon code="PT" size={16} />&nbsp; <Translate>Portuguese</Translate></span>
  },
  {
    value: "ES",
    label: <span><FlagIcon code="ES" size={16} />&nbsp; <Translate>Spanish</Translate></span>
  },
  {
    value: "AE",
    label: <span><FlagIcon code="AE" size={16} />&nbsp; <Translate>Arabic</Translate></span>
  },
  {
    value: "KR",
    label: <span><FlagIcon code="KR" size={16} />&nbsp; <Translate>Korean</Translate></span>
  },
];

class CustomizeSurveys extends Component {
  state = {
    activeTab: "1",
    request_rating: {},
    request_rating_comment: {},
    request_recommendation: {},
    follow_up_options: {},
  }

  toggleTab = (id) => {
    this.setState({ activeTab: id })
  }

  updateTemplates = (type) => {
    const { userData } = this.props;
    var survey_templates_data = {};
    if(userData?.website?.survey_templates?.[type]){
      survey_templates_data = {...userData?.website?.survey_templates[type]}
    }
    survey_templates_data = {...survey_templates_data, ...this.state[type]}
    var survey_templates = {};
    if(userData?.website?.survey_templates){
      survey_templates = {...userData?.website?.survey_templates}
    }
    survey_templates = {[type]: survey_templates_data}
    var update_data = {
      website_id: userData?.website?.website_id,
      survey_templates: survey_templates,
    }
    this.props.userWebsiteUpdate(update_data)
    this.setState({request_rating: {}});

  }

  translateCustomUpdate = (language) => {
    this.setState({
      request_rating: appConfig.messages_default_text[language].request_rating,
      request_rating_comment: appConfig.messages_default_text[language].request_rating_comment,
      request_recommendation: appConfig.messages_default_text[language].request_recommendation,
    });
  }

  updateFollowUp = (key, value) => {
    var follow_up_options = {...this.state.follow_up_options};
    follow_up_options[`rating_${key}`] = value ? value : null;
    this.setState({follow_up_options: follow_up_options});
  }

  updateFollowUpDB = (key) => {
    var follow_up_options = {...(this.props.userData?.website?.follow_up_options || {})};
    follow_up_options[`rating_${key}`] = this.state.follow_up_options[`rating_${key}`];
    this.props.userWebsiteUpdate({
      website_id: this.props.userData?.website?.website_id,
      follow_up_options: follow_up_options,
    })
  }

  render() {
    const {
      request_rating,
      request_rating_comment,
      request_recommendation,
      follow_up_options,
    } = this.state;

    const {
      userData,
      website_loading,
      survey_templates_active
    } = this.props;

    //console.log("website_loading", website_loading, request_recommendation, request_rating,)
    //console.log("follow_up_options:", follow_up_options);

    return (
      <div className="customize-surveys vertical-tabbed">
        {!survey_templates_active && <div className="disable-overlay"></div>}
        <Nav pills vertical>
          <NavItem>
            <NavLink
              active={this.state.activeTab === "1"}
              onClick={() => this.toggleTab("1")}
            ><Translate>Support Rating</Translate></NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={this.state.activeTab === "2"}
              onClick={() => this.toggleTab("2")}
            ><Translate>Support Comment</Translate></NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={this.state.activeTab === "3"}
              onClick={() => this.toggleTab("3")}
            ><Translate>Recommendation</Translate></NavLink>
          </NavItem>
          <NavItem className="mt-3">
            <Label>Translate customization</Label>
            <Select
              options={lang_options}
              isSearchable={false}
              isDisabled={!userData?.website?.survey_templates_active}
              onChange={(e) => this.translateCustomUpdate(e.value)}
            />
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            {this.state.activeTab === '1' &&
              <React.Fragment>
                <FormGroup>
                  <Label><Translate>Question/Message</Translate></Label>
                  <FormText className="ms-2"><Translate special_data="question_msg_label">Provide your custom text message</Translate></FormText>
                  <br />
                  <Input
                    type="checkbox"
                    onChange={() => this.props.userWebsiteUpdate({
                      website_id: userData?.website?.website_id,
                      display_full_name: !userData?.website?.display_full_name,
                    })}
                    checked={userData?.website?.display_full_name ? true : false}
                    disabled={website_loading}
                  />
                  <Label>&nbsp;Display full name in Question/Message</Label>
                  <Input
                    type="textarea"
                    value={
                      request_rating?.content_text === undefined
                        ? userData?.website?.survey_templates?.request_rating?.content_text
                        : request_rating?.content_text
                    }
                    onChange={(e) => this.setState({
                      request_rating: {
                        ...request_rating,
                        ...{content_text: e.target.value}
                      }
                    })}
                    invalid={
                      request_rating?.content_text
                        ? !request_rating?.content_text.includes("{name}")
                        : userData?.website?.survey_templates?.request_rating?.content_text
                          ? false
                          : true
                    }
                    disabled={website_loading}
                  />
                  <FormText><Translate>Must contain</Translate> <code>{`{name}`}</code> <Translate special_data="question_msg_help">in place where we will update name of your agent</Translate></FormText>
                </FormGroup>
                <FormGroup>
                  <Label><Translate>Options</Translate></Label>
                  <FormText className="ms-2"><Translate special_data="options_label">Provide your custom text for each option and follow-up comments to it</Translate></FormText>
                  <br/>
                  <small>You need to enable follow-up comments at Survey settings above to auto-response received rating.</small>
                  {(!userData?.website?.rating_symbol || userData?.website?.rating_symbol === 'face') ?
                  <React.Fragment>
                  <InputGroup className="mb-1 mt-1">
                    <InputGroupText><img src={amazing} alt="" /></InputGroupText>
                    <Input
                      value={
                        request_rating?.content_choices_5 === undefined
                          ? userData?.website?.survey_templates?.request_rating?.content_choices_5
                          : request_rating?.content_choices_5
                      }
                      onChange={(e) => this.setState({
                        request_rating: {
                          ...request_rating,
                          ...{content_choices_5: e.target.value}
                        }
                      })}
                      disabled={website_loading}
                      style={{ maxWidth: '10rem' }}
                    />
                    <Input
                      placeholder="Your response/comment or leave it blank to not response"
                      onChange={(e) => this.updateFollowUp("5", e.target.value)}
                      value={follow_up_options?.rating_5 === undefined ? userData?.website?.follow_up_options?.rating_5 : follow_up_options?.rating_5}
                    />
                    <Button
                      color="primary"
                      onClick={() => this.updateFollowUpDB("5")}
                      disabled={follow_up_options?.rating_5 === undefined}
                    >Update</Button>
                  </InputGroup>
                  <InputGroup className="mb-1">
                    <InputGroupText><img src={great} alt="" /></InputGroupText>
                    <Input
                      value={
                        request_rating?.content_choices_4 === undefined
                          ? userData?.website?.survey_templates?.request_rating?.content_choices_4
                          : request_rating?.content_choices_4
                      }
                      onChange={(e) => this.setState({
                        request_rating: {
                          ...request_rating,
                          ...{content_choices_4: e.target.value}
                        }
                      })}
                      disabled={website_loading}
                      style={{ maxWidth: '10rem' }}
                    />
                    <Input
                      placeholder="Your response/comment or leave it blank to not response"
                      onChange={(e) => this.updateFollowUp("4", e.target.value)}
                      value={follow_up_options?.rating_4 === undefined ? userData?.website?.follow_up_options?.rating_4 : follow_up_options?.rating_4}
                    />
                    <Button
                      color="primary"
                      onClick={() => this.updateFollowUpDB("4")}
                      disabled={follow_up_options?.rating_4 === undefined}
                    >Update</Button>
                  </InputGroup>
                  <InputGroup className="mb-1">
                    <InputGroupText><img src={ok} alt="" /></InputGroupText>
                    <Input
                      value={
                        request_rating?.content_choices_3 === undefined
                          ? userData?.website?.survey_templates?.request_rating?.content_choices_3
                          : request_rating?.content_choices_3
                      }
                      onChange={(e) => this.setState({
                        request_rating: {
                          ...request_rating,
                          ...{content_choices_3: e.target.value}
                        }
                      })}
                      disabled={website_loading}
                      style={{ maxWidth: '10rem' }}
                    />
                    <Input
                      placeholder="Your response/comment or leave it blank to not response"
                      onChange={(e) => this.updateFollowUp("3", e.target.value)}
                      value={follow_up_options?.rating_3 === undefined ? userData?.website?.follow_up_options?.rating_3 : follow_up_options?.rating_3}
                    />
                    <Button
                      color="primary"
                      onClick={() => this.updateFollowUpDB("3")}
                      disabled={follow_up_options?.rating_3 === undefined}
                    >Update</Button>
                  </InputGroup>
                  <InputGroup className="mb-1">
                    <InputGroupText><img src={bad} alt="" /></InputGroupText>
                    <Input
                      value={
                        request_rating?.content_choices_2 === undefined
                          ? userData?.website?.survey_templates?.request_rating?.content_choices_2
                          : request_rating?.content_choices_2
                      }
                      onChange={(e) => this.setState({
                        request_rating: {
                          ...request_rating,
                          ...{content_choices_2: e.target.value}
                        }
                      })}
                      disabled={website_loading}
                      style={{ maxWidth: '10rem' }}
                    />
                    <Input
                      placeholder="Your response/comment or leave it blank to not response"
                      onChange={(e) => this.updateFollowUp("2", e.target.value)}
                      value={follow_up_options?.rating_2 === undefined ? userData?.website?.follow_up_options?.rating_2 : follow_up_options?.rating_2}
                    />
                    <Button
                      color="primary"
                      onClick={() => this.updateFollowUpDB("2")}
                      disabled={follow_up_options?.rating_2 === undefined}
                    >Update</Button>
                  </InputGroup>
                  <InputGroup>
                    <InputGroupText><img src={terrible} alt="" /></InputGroupText>
                    <Input
                      value={
                        request_rating?.content_choices_1 === undefined
                          ? userData?.website?.survey_templates?.request_rating?.content_choices_1
                          : request_rating?.content_choices_1
                      }
                      onChange={(e) => this.setState({
                        request_rating: {
                          ...request_rating,
                          ...{content_choices_1: e.target.value}
                        }
                      })}
                      disabled={website_loading}
                      style={{ maxWidth: '10rem' }}
                    />
                    <Input
                      placeholder="Your response/comment or leave it blank to not response"
                      onChange={(e) => this.updateFollowUp("1", e.target.value)}
                      value={follow_up_options?.rating_1 === undefined ? userData?.website?.follow_up_options?.rating_1 : follow_up_options?.rating_1}
                    />
                    <Button
                      color="primary"
                      onClick={() => this.updateFollowUpDB("1")}
                      disabled={follow_up_options?.rating_1 === undefined}
                    >Update</Button>
                  </InputGroup>
                  </React.Fragment>
                  :
                  <React.Fragment>
                  <InputGroup className="mb-1">
                    <InputGroupText style={{ minWidth: 131 }}>⭐️⭐️⭐️⭐️⭐️</InputGroupText>
                    <Input
                      value={
                        request_rating?.content_choices_5 === undefined
                          ? userData?.website?.survey_templates?.request_rating?.content_choices_5
                          : request_rating?.content_choices_5
                      }
                      onChange={(e) => this.setState({
                        request_rating: {
                          ...request_rating,
                          ...{content_choices_5: e.target.value}
                        }
                      })}
                      disabled={website_loading}
                      style={{ maxWidth: '7rem'}}
                    />
                    <Input
                      placeholder="Your response/comment or leave it blank to not response"
                      onChange={(e) => this.updateFollowUp("5", e.target.value)}
                      value={follow_up_options?.rating_5 === undefined ? userData?.website?.follow_up_options?.rating_5 : follow_up_options?.rating_5}
                    />
                    <Button
                      color="primary"
                      onClick={() => this.updateFollowUpDB("5")}
                      disabled={follow_up_options?.rating_5 === undefined}
                    >Update</Button>
                  </InputGroup>
                  <InputGroup className="mb-1">
                    <InputGroupText style={{ minWidth: 131 }}>⭐️⭐️⭐️⭐️</InputGroupText>
                    <Input
                      value={
                        request_rating?.content_choices_4 === undefined
                          ? userData?.website?.survey_templates?.request_rating?.content_choices_4
                          : request_rating?.content_choices_4
                      }
                      onChange={(e) => this.setState({
                        request_rating: {
                          ...request_rating,
                          ...{content_choices_4: e.target.value}
                        }
                      })}
                      disabled={website_loading}
                      style={{ maxWidth: '7rem' }}
                    />
                    <Input
                      placeholder="Your response/comment or leave it blank to not response"
                      onChange={(e) => this.updateFollowUp("4", e.target.value)}
                      value={follow_up_options?.rating_4 === undefined ? userData?.website?.follow_up_options?.rating_4 : follow_up_options?.rating_4}
                    />
                    <Button
                      color="primary"
                      onClick={() => this.updateFollowUpDB("4")}
                      disabled={follow_up_options?.rating_4 === undefined}
                    >Update</Button>
                  </InputGroup>
                  <InputGroup className="mb-1">
                    <InputGroupText style={{ minWidth: 131 }}>⭐️⭐️⭐️</InputGroupText>
                    <Input
                      value={
                        request_rating?.content_choices_3 === undefined
                          ? userData?.website?.survey_templates?.request_rating?.content_choices_3
                          : request_rating?.content_choices_3
                      }
                      onChange={(e) => this.setState({
                        request_rating: {
                          ...request_rating,
                          ...{content_choices_3: e.target.value}
                        }
                      })}
                      disabled={website_loading}
                      style={{ maxWidth: '7rem' }}
                    />
                    <Input
                      placeholder="Your response/comment or leave it blank to not response"
                      onChange={(e) => this.updateFollowUp("3", e.target.value)}
                      value={follow_up_options?.rating_3 === undefined ? userData?.website?.follow_up_options?.rating_3 : follow_up_options?.rating_3}
                    />
                    <Button
                      color="primary"
                      onClick={() => this.updateFollowUpDB("3")}
                      disabled={follow_up_options?.rating_3 === undefined}
                    >Update</Button>
                  </InputGroup>
                  <InputGroup className="mb-1">
                    <InputGroupText style={{ minWidth: 131 }}>⭐️⭐️</InputGroupText>
                    <Input
                      value={
                        request_rating?.content_choices_2 === undefined
                          ? userData?.website?.survey_templates?.request_rating?.content_choices_2
                          : request_rating?.content_choices_2
                      }
                      onChange={(e) => this.setState({
                        request_rating: {
                          ...request_rating,
                          ...{content_choices_2: e.target.value}
                        }
                      })}
                      disabled={website_loading}
                      style={{ maxWidth: '7rem' }}
                    />
                    <Input
                      placeholder="Your response/comment or leave it blank to not response"
                      onChange={(e) => this.updateFollowUp("2", e.target.value)}
                      value={follow_up_options?.rating_2 === undefined ? userData?.website?.follow_up_options?.rating_2 : follow_up_options?.rating_2}
                    />
                    <Button
                      color="primary"
                      onClick={() => this.updateFollowUpDB("2")}
                      disabled={follow_up_options?.rating_2 === undefined}
                    >Update</Button>
                  </InputGroup>
                  <InputGroup className="mb-1">
                    <InputGroupText style={{ minWidth: 131 }}>⭐️</InputGroupText>
                    <Input
                      value={
                        request_rating?.content_choices_1 === undefined
                          ? userData?.website?.survey_templates?.request_rating?.content_choices_1
                          : request_rating?.content_choices_1
                      }
                      onChange={(e) => this.setState({
                        request_rating: {
                          ...request_rating,
                          ...{content_choices_1: e.target.value}
                        }
                      })}
                      disabled={website_loading}
                      style={{ maxWidth: '7rem' }}
                    />
                    <Input
                      placeholder="Your response/comment or leave it blank to not response"
                      onChange={(e) => this.updateFollowUp("1", e.target.value)}
                      value={follow_up_options?.rating_1 === undefined ? userData?.website?.follow_up_options?.rating_1 : follow_up_options?.rating_1}
                    />
                    <Button
                      color="primary"
                      onClick={() => this.updateFollowUpDB("1")}
                      disabled={follow_up_options?.rating_1 === undefined}
                    >Update</Button>
                  </InputGroup>
                  </React.Fragment>
                  }
                </FormGroup>
                <Button
                  color="primary"
                  onClick={() => this.updateTemplates("request_rating")}
                  disabled={
                    website_loading || isObjectEmpty(request_rating) ||
                    (
                    request_rating?.content_text
                      ? !request_rating?.content_text.includes("{name}")
                      : userData?.website?.survey_templates?.request_rating?.content_text
                        ? false
                      : true
                    )
                  }
                ><Translate>Save changes</Translate></Button>
              </React.Fragment>
            }
          </TabPane>
          <TabPane tabId="2">
            {this.state.activeTab === '2' &&
              <React.Fragment>
                <FormGroup>
                  <Label><Translate>Question/Message</Translate></Label>
                  <FormText className="ms-2"><Translate>Provide your custom text message</Translate></FormText>
                  <Input
                    type="textarea"
                    value={
                      request_rating_comment?.content_text === undefined
                        ? userData?.website?.survey_templates?.request_rating_comment?.content_text
                        : request_rating_comment?.content_text
                    }
                    onChange={(e) => this.setState({
                      request_rating_comment: {
                        ...request_rating_comment,
                        ...{content_text: e.target.value}
                      }
                    })}
                    disabled={website_loading}
                  />
                </FormGroup>
                <FormGroup>
                  <Label><Translate>Input Placeholder</Translate></Label>
                  <FormText className="ms-2"><Translate special_data="input_placeholder_label">Provide your custom text for input placeholder</Translate></FormText>
                  <Input
                    value={
                      request_rating_comment?.content_explain === undefined
                        ? userData?.website?.survey_templates?.request_rating_comment?.content_explain
                        : request_rating_comment?.content_explain
                    }
                    onChange={(e) => this.setState({
                      request_rating_comment: {
                        ...request_rating_comment,
                        ...{content_explain: e.target.value}
                      }
                    })}
                    disabled={website_loading}
                  />
                </FormGroup>
                <Button
                  color="primary"
                  onClick={() => this.updateTemplates("request_rating_comment")}
                  disabled={website_loading || isObjectEmpty(request_rating_comment)}
                >
                  <Translate>Save changes</Translate>
                </Button>
              </React.Fragment>
            }
          </TabPane>
          <TabPane tabId="3">
            {this.state.activeTab === '3' &&
              <React.Fragment>
                <FormGroup>
                  <Label><Translate>Question/Message</Translate></Label>
                  <FormText className="ms-2"><Translate special_data="question_msg_label">Provide your custom text message</Translate></FormText>
                  <Input
                    type="textarea"
                    value={
                      request_recommendation?.content_text === undefined
                        ? userData?.website?.survey_templates?.request_recommendation?.content_text
                        : request_recommendation?.content_text
                    }
                    onChange={(e) => this.setState({
                      request_recommendation: {
                        ...request_recommendation,
                        ...{content_text: e.target.value}
                      }
                    })}
                    invalid={
                      request_recommendation?.content_text
                        ? !request_recommendation?.content_text.includes("{name}")
                        : userData?.website?.survey_templates?.request_recommendation?.content_text
                          ? false
                          : true
                    }
                    disabled={website_loading}
                  />
                  <FormText><Translate>Must contain</Translate> <code>{`{name}`}</code> <Translate special_data="question_msg_help">in place where we will update name of your business</Translate></FormText>
                </FormGroup>
                <FormGroup>
                  <Label><Translate>Options</Translate></Label>
                  <FormText className="ms-2"><Translate special_data="options_label">Provide your custom text for each option below</Translate></FormText>
                  <InputGroup className="mb-1">
                    <InputGroupText><Translate>Recommend</Translate></InputGroupText>
                    <Input
                      value={
                        request_recommendation?.content_choices_10 === undefined
                          ? userData?.website?.survey_templates?.request_recommendation?.content_choices_10
                          : request_recommendation?.content_choices_10
                      }
                      onChange={(e) => this.setState({
                        request_recommendation: {
                          ...request_recommendation,
                          ...{content_choices_10: e.target.value}
                        }
                      })}
                      disabled={website_loading}
                    />
                  </InputGroup>
                  <InputGroup className="mb-1">
                    <InputGroupText><Translate>Maybe</Translate></InputGroupText>
                    <Input
                      value={
                        request_recommendation?.content_choices_9 === undefined
                          ? userData?.website?.survey_templates?.request_recommendation?.content_choices_9
                          : request_recommendation?.content_choices_9
                      }
                      onChange={(e) => this.setState({
                        request_recommendation: {
                          ...request_recommendation,
                          ...{content_choices_9: e.target.value}
                        }
                      })}
                      disabled={website_loading}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupText><Translate>Not recommend</Translate></InputGroupText>
                    <Input
                      value={
                        request_recommendation?.content_choices_5 === undefined
                          ? userData?.website?.survey_templates?.request_recommendation?.content_choices_5
                          : request_recommendation?.content_choices_5
                      }
                      onChange={(e) => this.setState({
                        request_recommendation: {
                          ...request_recommendation,
                          ...{content_choices_5: e.target.value}
                        }
                      })}
                      disabled={website_loading}
                    />
                  </InputGroup>
                </FormGroup>
                <Button
                  color="primary"
                  onClick={() => this.updateTemplates("request_recommendation")}
                  disabled={website_loading || isObjectEmpty(request_recommendation)}
                >
                <Translate>Save changes</Translate></Button>
              </React.Fragment>
            }
          </TabPane>
        </TabContent>
      </div>
    )
  }
}

export default CustomizeSurveys;
