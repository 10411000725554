import languages from '../../helpers/translation/languages';

export function translate_text(text, language, special_data){
  if(Object.keys(languages).includes(language)){
    if(special_data){
      return languages[language][special_data] || text
    } else {
      return languages[language][text] || text;
    }
  }
  return text;
}
