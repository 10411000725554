import React, { Component } from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import { Button, Modal } from "reactstrap";
import './style.css';
import userActions from "../../../redux/user/actions";

const {
  userAnnouncementDismiss,
} = userActions;

class Announcement extends Component {
  render() {
    const {
      isOpen,
      toggle,
      data
    } = this.props;

    return (
      <Modal isOpen={isOpen} size="lg" className="announcement-modal" centered>
        <div role="button" onClick={toggle} className="announcement-modal-close">
          <span className="icon icon-close bg-light" />
        </div>
        <div className="announcement-modal-media">
          {data.media_type === 'image' ?
          <img src={data.media_url} className="img-fluid" alt="" />
          :
          <video src={data.media_url} className="object-fit-cover" width={720} controls autoPlay muted loop></video>
          }
        </div>
        <div className="announcement-modal-text">
          <h6 className="announcement-modal-title">{data.title}</h6>
          <div className="announcement-modal-message">
            {data.message}
          </div>
        </div>
        <div className="announcement-modal-footer">
          <Button
            onClick={() => {
              toggle();
            }}
          >Close</Button>
          {data.primary_app_route?.includes("://")
            ? <a
                href={data.primary_app_route ? data.primary_app_route : ""}
                target="_blank"
                rel="noopenner noreferrer"
                className="btn btn-primary"
              >
                {data.primary_button_text ? data.primary_button_text : 'Try now'}
              </a>
            : <NavLink
                to={data.primary_app_route ? data.primary_app_route : ""}
                className="btn btn-primary"
                onClick={() => {
                  this.props.userAnnouncementDismiss();
                }}
              >
                {data.primary_button_text ? data.primary_button_text : 'Try now'}
              </NavLink>
          }
        </div>
      </Modal>
    )
  }
}

export default connect(
  state => ({
  }),
  {
    userAnnouncementDismiss,
  }
)(Announcement);
