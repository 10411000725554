import React, { Component } from 'react';
import { connect } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import Select from 'react-select';
import Announcement from "../../shared/components/Announcement";
import ConfirmModal from "../../shared/components/ConfirmModal";
import adminActions from "../../redux/admin/actions";

const {
  adminFileUpload,
  adminAnnouncementCreate,
  adminAnnouncementAll,
  adminAnnouncementUpdate,
  adminAnnouncementDelete,
} = adminActions;

const statusOptions = [
  {value: true, label: 'Published'},
  {value: false, label: 'Unpublished'}
]

class AnnouncementAdmin extends Component {
  state = {
    loading: false,
    previewId: null,
    showCreate: false,
    deleteId: null,
    title: null,
    message: null,
    media_type: "image",
    media_url: null,
    primary_button_text: null,
    primary_app_route: null,
    published: null,
    showUpdate: false,
    announcement: null,
  }

  componentDidMount = () => {
    this.props.adminAnnouncementAll();
  }

  resetFormData = () => {
    this.setState({
      title: null,
      message: null,
      media_type: "image",
      media_url: null,
      primary_button_text: null,
      primary_app_route: null,
      published: null,
      announcement: null,
    })
  }

  generateColumns = () => {
    const columns = [
      {
        dataField: 'title',
        text: 'Title',
      },
      {
        dataField: 'primary_app_route',
        text: 'Primary Internal Route/External Link',
      },
      {
        dataField: 'published',
        text: 'Status',
        formatter: (cell, row) => (
          <Select
            options={statusOptions}
            value={statusOptions.filter(item => item.value === cell)}
            onChange={(selected) => this.props.adminAnnouncementUpdate({
              id: row.id,
              published: selected.value,
            })}
          />
        )
      },
      {
        dataField: 'df',
        isDummyFiled: true,
        text: 'Actions',
        formatter: (cell, row) => (
          <div className="hstack gap-2">
            <Button size="sm" onClick={() => this.setState({ previewId: row.id })}>Preview</Button>
            <Button
              size="sm"
              onClick={() => {
                this.resetFormData();
                this.setState({
                  showUpdate: true,
                  announcement: row,
                });
              }}
            >Edit</Button>
            <Button
              size="sm"
              color="danger"
              onClick={() => this.setState({ deleteId: row.id })}
            >Delete</Button>
          </div>
        )
      },
    ]

    return columns
  }

  saveAnnouncement = () => {
    const {
      title,
      message,
      media_type,
      primary_button_text,
      primary_app_route,
      published,
    } = this.state;
    this.props.adminAnnouncementCreate({
      title: title,
      message: message,
      media_type: media_type,
      media_url: this.props.file_url,
      primary_button_text: primary_button_text,
      primary_app_route: primary_app_route,
      published: published,
    })
    this.resetFormData();
    this.setState({showCreate: false});
  }

  updateAnnouncement = () => {
    const {
      title,
      message,
      media_type,
      primary_button_text,
      primary_app_route,
      published,
      media_url,
    } = this.state;
    let update_data = {
      id: this.state.announcement.id,
    }
    if(title){ update_data['title'] = title};
    if(message){ update_data['message'] = message};
    if(media_type && media_url){ update_data['media_type'] = media_type};
    if(media_url){ update_data['media_url'] = media_url};
    if(primary_button_text){ update_data['primary_button_text'] = primary_button_text};
    if(primary_app_route){ update_data['primary_app_route'] = primary_app_route};
    if(published){ update_data['published'] = published};
    this.props.adminAnnouncementUpdate(update_data)
    this.resetFormData();
    this.setState({showUpdate: false});
  }

  render() {
    const {
      title,
      message,
      primary_button_text,
      primary_app_route,
      published,
      announcement,
    } = this.state;

    return (
      <div className="admin-announcements">
        <div className="hstack justify-content-end mb-4">
          <Button
            color="primary"
            onClick={() => this.setState({ showCreate: true })}
          >Create announcement</Button>
        </div>
        <div className="table-wrapper">
          {this.state.loading &&
          <div className="card-loading">
            <div className="loading-indicator">
              <Spinner color="secondary" /> <span>Loading...</span>
            </div>
          </div>
          }
          <BootstrapTable
            remote
            keyField="id"
            columns={this.generateColumns()}
            bordered={false}
            data={this.props.announcements ? this.props.announcements : []}
            wrapperClasses="table-responsive"
          />
        </div>
        {this.state.previewId !== null &&
        <Announcement
          isOpen={true}
          toggle={() => this.setState({ previewId: null })}
          data={this.props.announcements.find(item => item.id === this.state.previewId)}
        />
        }
        {this.state.showCreate &&
        <Modal isOpen={true} centered>
          <ModalHeader toggle={() => this.setState({ showCreate: false })}>Create announcement</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Title</Label>
              <Input
                value={title}
                onChange={(e) => this.setState({title: e.target.value})}
              />
            </FormGroup>
            <FormGroup>
              <Label>Message</Label>
              <Input
                type="textarea"
                rows="3"
                value={message}
                onChange={(e) => this.setState({message: e.target.value})}
              />
            </FormGroup>
            <FormGroup>
              <Label>Media:</Label>
              <FormGroup check inline className="ms-2">
                <Label check>
                  <Input
                    type="radio"
                    name="mediaType"
                    checked={this.state.media_type === 'image'}
                    onChange={() => this.setState({ media_type: 'image' })}
                  /> Image
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    name="mediaType"
                    checked={this.state.media_type === 'video'}
                    onChange={() => this.setState({ media_type: 'video' })}
                  /> Video
                </Label>
              </FormGroup>
              <br/>
              <Label>Upload <span className="text-capitalize">{this.state.media_type}</span> File</Label>
              <Input
                type="file"
                onChange={e => this.props.adminFileUpload({ file: e.target.files[0] })}
                disabled={this.props.file_uploading}
              />
              {this.props.file_uploading &&
                <div>Uploading media...</div>
              }
            </FormGroup>
            <FormGroup>
              <Label>Primary Button Text</Label>
              <Input
                placeholder="Leave empty for default: 'Try Now'"
                value={primary_button_text}
                onChange={(e) => this.setState({primary_button_text: e.target.value})}
              />
            </FormGroup>
            <FormGroup>
              <Label>Primary Internal Route/External Link</Label>
              <Input
                placeholder="Ex: /rating/reviews or https://www.domain.com"
                value={primary_app_route}
                onChange={(e) => this.setState({primary_app_route: e.target.value})}
              />
            </FormGroup>
            <FormGroup>
              <Label>Status</Label>
              <Select
                options={statusOptions}
                value={statusOptions.find(item => item.value === published)}
                onChange={(selected) => this.setState({published: selected.value})}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => this.setState({ showCreate: false })}
            >Cancel</Button>
            <Button
              color="primary"
              onClick={() => this.saveAnnouncement()}
              disabled={
                this.props.file_uploading
                  || !title
                  || !message
                  || !this.props.file_url
                  || !primary_button_text
                  || !primary_app_route
              }
            >Save</Button>
          </ModalFooter>
        </Modal>
        }
        {this.state.showUpdate &&
        <Modal isOpen={true} centered>
          <ModalHeader toggle={() => this.setState({ showUpdate: false })}>Create announcement</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Title</Label>
              <Input
                value={title === null ? announcement.title : title}
                onChange={(e) => this.setState({title: e.target.value})}
              />
            </FormGroup>
            <FormGroup>
              <Label>Message</Label>
              <Input
                type="textarea"
                rows="3"
                value={message === null ? announcement.message : message}
                onChange={(e) => this.setState({message: e.target.value})}
              />
            </FormGroup>
            <FormGroup>
              <Label>Media:</Label>
              <FormGroup check inline className="ms-2">
                <Label check>
                  <Input
                    type="radio"
                    name="mediaType"
                    checked={this.state.media_type === null
                        ? announcement.media_type === 'image'
                        : this.state.media_type === 'image'
                    }
                    onChange={() => this.setState({ media_type: 'image' })}
                  /> Image
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    name="mediaType"
                    checked={this.state.media_type === null
                        ? announcement.media_type === 'video'
                        : this.state.media_type === 'video'
                    }
                    onChange={() => this.setState({ media_type: 'video' })}
                  /> Video
                </Label>
              </FormGroup>
              <br/>
              <Label>Upload <span className="text-capitalize">{this.state.media_type}</span> File</Label>
              <Input
                type="file"
                onChange={e => this.props.adminFileUpload({ file: e.target.files[0] })}
                disabled={this.props.file_uploading}
              />
              {this.props.file_uploading &&
                <div>Uploading media...</div>
              }
            </FormGroup>
            <FormGroup>
              <Label>Primary Button Text</Label>
              <Input
                placeholder="Leave empty for default: 'Try Now'"
                value={primary_button_text === null ? announcement.primary_button_text : primary_button_text}
                onChange={(e) => this.setState({primary_button_text: e.target.value})}
              />
            </FormGroup>
            <FormGroup>
              <Label>Primary Internal Route/External Link</Label>
              <Input
                placeholder="Ex: /rating/reviews or https://www.domain.com"
                value={primary_app_route === null ? announcement.primary_app_route : primary_app_route}
                onChange={(e) => this.setState({primary_app_route: e.target.value})}
              />
            </FormGroup>
            <FormGroup>
              <Label>Status</Label>
              <Select
                options={statusOptions}
                value={published === null
                  ? statusOptions.find(item => item.value === announcement.published)
                  : statusOptions.find(item => item.value === published)
                }
                onChange={(selected) => this.setState({published: selected.value})}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => this.setState({ showUpdate: false })}
            >Cancel</Button>
            <Button
              color="primary"
              onClick={() => this.updateAnnouncement()}
              disabled={
                this.props.file_uploading
              }
            >Update</Button>
          </ModalFooter>
        </Modal>
        }
        <ConfirmModal
          message="Are you sure want to delete this announcement?"
          yesColor="danger"
          isOpen={this.state.deleteId}
          toggle={() => this.setState({ deleteId: null })}
          onConfirm={() => {
              this.props.adminAnnouncementDelete({ announcement: this.state.deleteId });
              this.setState({ deleteId: null });
          }}
        />
      </div>
    )
  }
}


export default connect(
  state => ({
    file_url: state.Admin.get("file_url"),
    file_uploading: state.Admin.get("file_uploading"),
    announcements: state.Admin.get("announcements"),
    announcements_working: state.Admin.get("announcements_working"),
  }),
  {
    adminFileUpload,
    adminAnnouncementCreate,
    adminAnnouncementAll,
    adminAnnouncementUpdate,
    adminAnnouncementDelete,
  }
)(AnnouncementAdmin);
