import React, { Component } from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button,
  FormGroup, Table, InputGroup, InputGroupText, //Input
} from 'reactstrap';
import Select from 'react-select';
import { countries } from './countries';
import { languages } from './languages';
import DateDropdown from '../../shared/components/DateDropdown';
import Translate from "../../shared/components/Translate";

class OperatorEditInfoModal extends Component {
  state = {
    calendarOpen: false,
    activeSince: null,
    slack_id: null,
    language: null,
    location_country: null,
  }

  onCalendarChange = (value) => {
    this.setState({
      activeSince: value,
      calendarOpen: false
    })
    this.updateOperator("active_since", value)
  }

  toggleCalendar = () => {
    this.setState({ calendarOpen: !this.state.calendarOpen })
  }

  updateOperator = (key, value) => {
    const data = {
      user_id: this.props.operator.user_id,
      operator_data: {
        [key]: value,
      }
    }
    this.props.dashboardOperatorReviewUpdate(data);
  }

  render() {
    const {
      isOpen,
      toggle,
      slack_users,
      slack_users_loading,
      operator,
    } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}><Translate>Edit agent info</Translate></ModalHeader>
        <ModalBody>
          <FormGroup>
            <Table borderless size="sm" className="align-middle">
              <tbody>
                <tr>
                  <th><Translate>Name</Translate>:</th>
                  <td>{operator?.first_name}</td>
                </tr>
                <tr>
                  <th><Translate>Slack handle</Translate>:</th>
                  <td>
                    <InputGroup>
                      <InputGroupText>@</InputGroupText>
                      <div className="flex-fill">
                        <Select
                          options={slack_users}
                          isClearable
                          onChange={(e) => this.updateOperator("slack_id", e ? e.value : "")}
                          value={slack_users.find(item => item.value === operator?.slack_id)}
                          disabled={slack_users_loading}
                          placeholder={<Translate>Select...</Translate>}
                        />
                      </div>
                    </InputGroup>
                  </td>
                </tr>
                <tr>
                  <th><Translate>Location</Translate>:</th>
                  <td>
                    <Select
                      options={countries}
                      isClearable
                      onChange={(e) => this.updateOperator("location_country", e ? e.label : "")}
                      value={countries.find(item => item.label === operator?.location_country)}
                      disabled={slack_users_loading}
                      placeholder={<Translate>Select...</Translate>}
                    />
                  </td>
                </tr>
                <tr>
                  <th><Translate>Active since</Translate>:</th>
                  <td>
                    <DateDropdown
                      value={
                        this.state.activeSince
                          ? this.state.activeSince
                          : operator?.active_since
                            ? new Date(operator?.active_since)
                            : null
                      }
                      isOpen={this.state.calendarOpen}
                      toggle={this.toggleCalendar}
                      onChange={this.onCalendarChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th><Translate>Language</Translate>:</th>
                  <td>
                    <Select
                      options={languages}
                      isClearable
                      onChange={(e) => this.updateOperator("language", e ? e.label : "")}
                      value={languages.find(item => item.label === operator?.language)}
                      disabled={slack_users_loading}
                      placeholder={<Translate>Select...</Translate>}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="light"
            onClick={toggle}
          ><Translate>Close</Translate></Button>
        </ModalFooter>

      </Modal>
    )
  }
}

export default OperatorEditInfoModal;
