import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Card, CardBody, CardTitle, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import SidebarNav from "./SidebarNav";
import Translate from "../../shared/components/Translate";
import Select from 'react-select';
import dashboardActions from "../../redux/dashboard/actions";

const {
  webhooksGet,
  webhookCreate,
  webhookDelete,
} = dashboardActions;

class Webhooks extends Component {
  state = {
    showEndpointModal: false,
    endpoint: "",
    event: null,
  }

  componentDidMount = () => {
    this.props.webhooksGet({id_website: this.props.userData?.website_id});
  }

  render() {
    console.log("WEBHOOKS:", this.props.webhooks);
    return (
      <div className="main-content view-webhooks">
        <div className="page-content">
          <div className="settings-area">
            <div className="settings-menu">
              <SidebarNav />
            </div>
            <div className="settings-content">
              <Card>
                <CardBody>
                  <CardTitle className="mb-0"><Translate>Webhooks</Translate></CardTitle>
                  <p><Translate special_data="api_key_desc">Create webhook endpoint, so that Rating Fish can notify your integrations when events occur.</Translate></p>
                  <Table>
                    <thead>
                      <tr>
                        <th>URL</th>
                        <th>Listening for</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.webhooks.map((item, idx) => {
                        return (
                          <tr key={`webhooks-${idx}`}>
                            <td>{item.endpoint}</td>
                            <td>{item.event}</td>
                            <td>
                              <Button
                                size="sm"
                                color="danger"
                                onClick={() => this.props.webhookDelete({
                                  id: item.id,
                                  id_website: this.props.userData?.website_id,
                                })}
                                disabled={this.props.webhooks_working}
                              >Delete</Button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    <div className="mt-3">
                      <h6>Waiting for events...</h6>
                    </div>
                  </Table>
                  <Button
                    color="primary"
                    onClick={() => this.setState({ showEndpointModal: true })}
                  >Add an endpoint</Button>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.showEndpointModal} centered>
          <ModalHeader toggle={() => this.setState({ showEndpointModal: !this.state.showEndpointModal })}>Add an endpoint</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Endpoint URL</Label>
              <Input
                type="url"
                placeholder="https://"
                value={this.state.endpoint}
                onChange={(e) => this.setState({endpoint: e.target.value})}
              />
            </FormGroup>
            <FormGroup>
              <Label>Select events to listen to</Label>
              <Select
                options={[
                  {label: 'rating.update', value: 'rating.update' },
                ]}
                value={this.state.event}
                onChange={(e) => this.setState({event: e})}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="light"
              onClick={() => this.setState({ showEndpointModal: !this.state.showEndpointModal })}
            >Cancel</Button>
            <Button
              color="primary"
              onClick={() => {
                this.setState({
                  showEndpointModal: !this.state.showEndpointModal,
                  endpoint: "",
                  event: null,
                });
                this.props.webhookCreate({
                  webhook_data: {
                    endpoint: this.state.endpoint,
                    event: this.state.event?.value,
                    id_website: this.props.userData?.website_id,
                  }
                })
              }}
              disabled={
                !this.state.endpoint
                  || !this.state.event
                  || this.props.webhooks_working
              }
            >Save</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default connect(
	state => ({
    userData: state.User.get("userData"),
    webhooks: state.Dashboard.get("webhooks"),
    webhooks_working: state.Dashboard.get("webhooks_working"),
	}),
  {
    webhooksGet,
    webhookCreate,
    webhookDelete,
  }
)(Webhooks);
