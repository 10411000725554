import { Map } from "immutable";
import actions from "./actions";

const initState = new Map({
  dashboard_top_data: [],
  dashboard_top_data_loading: false,
  operators: {},
  operators_loading: false,
  operators_data: [],
  operators_data_loading: false,
  operators_data_top_performers: [],
  operators_data_top_performers_loading: false,
  operators_data_ratio: {},
  operators_data_ratio_loading: false,
  operators_data_review: {},
  operators_data_review_loading: false,
  operators_data_word_cloud: [],
  operator_review: null,
  operator_review_loading: false,
  operator_review_email_loading: false,
  operator_review_scheduled: [],
  operator_review_scheduled_loading: false,
  webhooks: [],
  webhooks_working: false,
  external_rating: {},
  external_rating_working: false,
  external_rating_finished: false,
});

export default function dashboardReducer(state = initState, action) {
	switch (action.type) {

		case "OPERATOR_RATING_EXTERNAL_UPDATE":
			return state
				.set("external_rating_working", true)
		case "OPERATOR_RATING_EXTERNAL_UPDATE_SUCCESS":
			return state
				.set("external_rating_working", false)
				.set("external_rating_finished", true)
		case "OPERATOR_RATING_EXTERNAL_UPDATE_FAILED":
			return state
				.set("external_rating_working", false)

		case "OPERATOR_RATING_EXTERNAL_GET":
			return state
				.set("external_rating", {})
				.set("external_rating_working", true)
				.set("external_rating_finished", false)
		case "OPERATOR_RATING_EXTERNAL_GET_SUCCESS":
			return state
				.set("external_rating", {...action.data.data})
				.set("external_rating_working", false)
				.set("external_rating_finished", action.data?.data?.rating ? true : false)
		case "OPERATOR_RATING_EXTERNAL_GET_FAILED":
			return state
				.set("external_rating", {})
				.set("external_rating_working", false)
				.set("external_rating_finished", false)

		case "OPERATOR_RATING_UPDATE":
			return state
				.set("operators_loading", true)
		case "OPERATOR_RATING_UPDATE_SUCCESS":
      let rating_update = {...state.get("operators")}
      rating_update.items = rating_update.items.map(item => {
        if(item.id === action.data.data.id){
          return {...item, ...action.data.data};
        }
        return item;
      })
			return state
				.set("operators", {...rating_update})
				.set("operators_loading", false)
		case "OPERATOR_RATING_UPDATE_FAILED":
			return state
				.set("operators_loading", false)

		case "WEBHOOK_DELETE":
			return state
				.set("webhooks_working", true)
    case "WEBHOOK_DELETE_SUCCESS":
      let webhook_delete = state.get("webhooks");
			return state
        .set("webhooks", [...webhook_delete.filter(item => item.id !== action.data.data.id)])
				.set("webhooks_working", false)
		case "WEBHOOK_DELETE_FAILED":
			return state
				.set("webhooks_working", false)

		case "WEBHOOK_CREATE":
			return state
				.set("webhooks_working", true)
    case "WEBHOOK_CREATE_SUCCESS":
      let webhook_create = state.get("webhooks");
      webhook_create.push(action.data.data)
			return state
				.set("webhooks", [...webhook_create])
				.set("webhooks_working", false)
		case "WEBHOOK_CREATE_FAILED":
			return state
				.set("webhooks_working", false)

		case "WEBHOOKS_GET":
			return state
				.set("webhooks_working", true)
		case "WEBHOOKS_GET_SUCCESS":
			return state
				.set("webhooks", [...action.data.data])
				.set("webhooks_working", false)
		case "WEBHOOKS_GET_FAILED":
			return state
				.set("webhooks_working", false)

		case "OPERATOR_REVIEW_SCHEDULED_UPDATE":
			return state
				.set("operator_review_scheduled_loading", true)
		case "OPERATOR_REVIEW_SCHEDULED_UPDATE_SUCCESS":
      let scheduled_update = [...state.get("operator_review_scheduled")]
      scheduled_update = scheduled_update.map(item => {
        if(item.id === action.data.data.id){
          return {...item, ...action.data.data};
        }
        return item;
      })
			return state
				.set("operator_review_scheduled", [...scheduled_update])
				.set("operator_review_scheduled_loading", false)
		case "OPERATOR_REVIEW_SCHEDULED_UPDATE_FAILED":
			return state
				.set("operator_review_scheduled_loading", false)

		case "OPERATOR_REVIEW_SCHEDULED_DELETE":
			return state
				.set("operator_review_scheduled_loading", true)
		case "OPERATOR_REVIEW_SCHEDULED_DELETE_SUCCESS":
      let scheduled_delete = [...state.get("operator_review_scheduled")]
      scheduled_delete = scheduled_delete.filter(item => item.id !== action.data.data.id)
			return state
				.set("operator_review_scheduled", [...scheduled_delete])
				.set("operator_review_scheduled_loading", false)
		case "OPERATOR_REVIEW_SCHEDULED_DELETE_FAILED":
			return state
				.set("operator_review_scheduled_loading", false)

		case "OPERATOR_REVIEW_SCHEDULED_CREATE":
			return state
				.set("operator_review_scheduled_loading", true)
		case "OPERATOR_REVIEW_SCHEDULED_CREATE_SUCCESS":
      let scheduled = [...state.get("operator_review_scheduled")]
      scheduled.unshift(action.data.data)
			return state
				.set("operator_review_scheduled", [...scheduled])
				.set("operator_review_scheduled_loading", false)
		case "OPERATOR_REVIEW_SCHEDULED_CREATE_FAILED":
			return state
				.set("operator_review_scheduled_loading", false)

		case "OPERATOR_REVIEW_SCHEDULED_GET_ALL":
			return state
				.set("operator_review_scheduled_loading", true)
		case "OPERATOR_REVIEW_SCHEDULED_GET_ALL_SUCCESS":
			return state
				.set("operator_review_scheduled", [...action.data.data])
				.set("operator_review_scheduled_loading", false)
		case "OPERATOR_REVIEW_SCHEDULED_GET_ALL_FAILED":
			return state
				.set("operator_review_scheduled_loading", false)

		case "OPERATOR_REVIEW_SEND_EMAIL":
			return state
				.set("operator_review_email_loading", true)
		case "OPERATOR_REVIEW_SEND_EMAIL_SUCCESS":
			return state
				.set("operator_review_email_loading", false)
		case "OPERATOR_REVIEW_SEND_EMAIL_FAILED":
			return state
				.set("operator_review_email_loading", false)

		case "OPERATOR_REVIEW_GENERATE":
			return state
				.set("operator_review", null)
				.set("operator_review_loading", true)
		case "OPERATOR_REVIEW_GENERATE_SUCCESS":
			return state
				.set("operator_review", {...action.data.data})
				.set("operator_review_loading", false)
		case "OPERATOR_REVIEW_GENERATE_FAILED":
			return state
				.set("operator_review", null)
				.set("operator_review_loading", false)

		case "DASHBOARD_EXPORT_OPERATORS":
			return state
				.set("operators_loading", true)
		case "DASHBOARD_EXPORT_OPERATORS_SUCCESS":
			return state
				.set("operators_loading", false)
		case "DASHBOARD_EXPORT_OPERATORS_FAILED":
			return state
				.set("operators_loading", false)

		case "DASHBOARD_OPERATOR_REVIEW_UPDATE":
			return state
				.set("operators_data_review_loading", true)
		case "DASHBOARD_OPERATOR_REVIEW_UPDATE_SUCCESS":
      var update_operators_data_review = state.get("operators_data_review");
      update_operators_data_review.operator = {
        ...update_operators_data_review.operator,
        ...action.data.data,
      }
      var update_operators_data_top_performers = state.get("operators_data_top_performers");
      update_operators_data_top_performers = update_operators_data_top_performers.map(item => {
        if(item.id === action.data.data.id){
          item = {
            ...item,
            ...action.data.data,
          }
        }
        return item;
      })

			return state
        .set("operators_data_top_performers", [...update_operators_data_top_performers])
        .set("operators_data_review", {...update_operators_data_review})
				.set("operators_data_review_loading", false)
		case "DASHBOARD_OPERATOR_REVIEW_UPDATE_FAILED":
			return state
				.set("operators_data_review_loading", false)

		case "DASHBOARD_OPERATOR_WORD_CLOUD":
			return state
				.set("operators_data_word_cloud", {})
				.set("operators_data_review_loading", true)
		case "DASHBOARD_OPERATOR_WORD_CLOUD_SUCCESS":
			return state
				.set("operators_data_word_cloud", [...action.data])
				.set("operators_data_review_loading", false)
		case "DASHBOARD_OPERATOR_WORD_CLOUD_FAILED":
			return state
				.set("operators_data_word_cloud", {})
				.set("operators_data_review_loading", false)

		case "DASHBOARD_OPERATOR_REVIEW":
			return state
				.set("operators_data_review", {})
				.set("operators_data_review_loading", true)
		case "DASHBOARD_OPERATOR_REVIEW_SUCCESS":
			return state
				.set("operators_data_review", {...action.data.data})
				.set("operators_data_review_loading", false)
		case "DASHBOARD_OPERATOR_REVIEW_FAILED":
			return state
				.set("operators_data_review", {})
				.set("operators_data_review_loading", false)

		case actions.DASHBOARD_OPERATORS_EXCLUDE:
			return state
				.set("operators_data_top_performers_loading", true)
    case actions.DASHBOARD_OPERATORS_EXCLUDE_SUCCESS:
      var top_performers_exclude = state.get("operators_data_top_performers");
      top_performers_exclude = top_performers_exclude.filter(item => item.user_id !== action.data.exclude_id);
			return state
				.set("operators_data_top_performers", [...top_performers_exclude])
				.set("operators_data_top_performers_loading", false)
		case actions.DASHBOARD_OPERATORS_EXCLUDE_FAILED:
			return state
				.set("operators_data_top_performers_loading", false)

		case "DASHBOARD_OPERATORS_RATIO":
			return state
				.set("operators_data_ratio", {})
				.set("operators_data_ratio_loading", true)
		case "DASHBOARD_OPERATORS_RATIO_SUCCESS":
			return state
				.set("operators_data_ratio", {...action.data.data})
				.set("operators_data_ratio_loading", false)
		case "DASHBOARD_OPERATORS_RATIO_FAILED":
			return state
				.set("operators_data_ratio", {})
				.set("operators_data_ratio_loading", false)

		case "DASHBOARD_OPERATORS_TOP_PERFORMERS":
			return state
				.set("operators_data_top_performers", [])
				.set("operators_data_top_performers_loading", true)
		case "DASHBOARD_OPERATORS_TOP_PERFORMERS_SUCCESS":
			return state
				.set("operators_data_top_performers", [...action.data.data])
				.set("operators_data_top_performers_loading", false)
		case "DASHBOARD_OPERATORS_TOP_PERFORMERS_FAILED":
			return state
				.set("operators_data_top_performers", [])
				.set("operators_data_top_performers_loading", false)

		case "DASHBOARD_OPERATORS":
			return state
				.set("operators_data", [])
				.set("operators_data_loading", true)
		case "DASHBOARD_OPERATORS_SUCCESS":
			return state
				.set("operators_data", [...action.data.data])
				.set("operators_data_loading", false)
		case "DASHBOARD_OPERATORS_FAILED":
			return state
				.set("operators_data", [])
				.set("operators_data_loading", false)

		case "DASHBOARD_LIST_OPERATORS":
			return state
				.set("operators", {})
				.set("operators_loading", true)
		case "DASHBOARD_LIST_OPERATORS_SUCCESS":
			return state
				.set("operators", {...action.data.data})
				.set("operators_loading", false)
		case "DASHBOARD_LIST_OPERATORS_FAILED":
			return state
				.set("operators", {})
				.set("operators_loading", false)

		case "DASHBOARD_TOP_STATS":
			return state
				.set("dashboard_top_data", [])
				.set("dashboard_top_data_loading", true)
		case "DASHBOARD_TOP_STATS_SUCCESS":
			return state
				.set("dashboard_top_data", [...action.data.data])
				.set("dashboard_top_data_loading", false)
		case "DASHBOARD_TOP_STATS_FAILED":
			return state
				.set("dashboard_top_data", [])
				.set("dashboard_top_data_loading", false)

		default:
			return state;
  }
}
