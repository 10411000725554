import { Map } from "immutable";

const initState = new Map({
  website_available_languages: [],
  website_available_languages_loading: false,
});

export default function settingsReducer(state = initState, action) {
	switch (action.type) {

		case "SETTINGS_AVAILABLE_LANGUAGES_GET":
			return state
				.set("website_available_languages", [])
				.set("website_available_languages_loading", true)
		case "SETTINGS_AVAILABLE_LANGUAGES_GET_SUCCESS":
			return state
				.set("website_available_languages", [...action.data.data])
				.set("website_available_languages_loading", false)
		case "SETTINGS_AVAILABLE_LANGUAGES_GET_FAILED":
			return state
				.set("website_available_languages", [])
				.set("website_available_languages_loading", false)

		default:
			return state;
  }
}
