import React, { Component } from 'react';
import { connect } from "react-redux";
import { Card, CardBody, CardTitle, FormGroup, FormText, Row, Col, Input, Button, CardFooter, Label, Alert } from 'reactstrap';
import SidebarNav from './SidebarNav';
import userActions from "../../redux/user/actions";
import Translate from "../../shared/components/Translate";

const {
  userWebsiteUpdateSegmentation,
  userWebsiteDeleteSegmentation,
} = userActions;

class Segmentation extends Component {
  state = {
    segmentation: "",
  }

  updateSegmentation = () => {
    const data = {
      segmentation: this.state.segmentation
    }
    this.props.userWebsiteUpdateSegmentation(data);
    this.setState({segmentation: ""})
  }

  deleteSegmentation = (segmentation) => {
    const data = {
      segmentation: segmentation
    }
    this.props.userWebsiteDeleteSegmentation(data);
  }

  isListValid = () => {
    const { segmentation } = this.state;
    if(segmentation.split(',').length > 15){
      return false;
    }
    return true;
  }

  addAllToSegmentation = () => {
    const { userData } = this.props;
    var segmentation = userData?.website?.segmentation;
    if(!segmentation){ return; }
    segmentation = segmentation.split(',');
    if(segmentation.length >= 15){ return }
    userData?.website?.segmentation_data_options_list.forEach(item => {
      if(!segmentation.includes(item.toLowerCase())){
        segmentation.push(item.toLowerCase());
      }
    })
    const data = {
      segmentation: segmentation.join(',')
    }
    this.props.userWebsiteUpdateSegmentation(data);
    this.setState({segmentation: ""})
  }

  addSegment = (item) => {
    const { userData } = this.props;
    var segmentation = userData?.website?.segmentation;
    if(!segmentation){ return; }
    segmentation = segmentation.split(',');
    if(segmentation.length >= 15){ return }
    if(!segmentation.includes(item.toLowerCase())){
      segmentation.push(item.toLowerCase());
    }
    const data = {
      segmentation: segmentation.join(',')
    }
    this.props.userWebsiteUpdateSegmentation(data);
    this.setState({segmentation: ""})
  }

  render() {
    const { userData, website_segmentation_loading } = this.props;

    return (
      <div className="main-content view-segmentation">
        <div className="page-content">
          <div className="settings-area">
            <div className="settings-menu">
              <SidebarNav />
            </div>
            <div className="settings-content">
              <Card>
                <CardBody>
                  <CardTitle><Translate>Add your segments</Translate></CardTitle>
                  <Row>
                    <Col sm={5}>
                      <Label>
                        <Translate
                          special_data="segmentation_1"
                        >
                            Add words to be tracked for auto segmentation on Crisp.
                        </Translate>
                      </Label>
                      <FormGroup>
                        <Input
                          type="textarea"
                          rows={5}
                          value={
                            this.state.segmentation
                              ? this.state.segmentation
                              : userData?.website?.segmentation
                          }
                          disabled={website_segmentation_loading}
                          invalid={!this.isListValid()}
                          onChange={(e) => this.setState({segmentation: e.target.value})}
                        />
                        <FormText>
                          <Translate
                            special_data="segmentation_2"
                          >
                            Words must be in comma-separated format.
                          </Translate>
                        </FormText>
                      </FormGroup>
                    </Col>
                    <Col sm={7}>
                      <Label><Translate>Add suggested words</Translate></Label>
                      <div className="segment-suggestions">
                        {userData?.website?.segmentation_data_options_list.map((item, idx) => {
                          const added =
                            userData?.website?.segmentation
                              ? userData?.website?.segmentation?.toLowerCase().split(',').includes(item)
                                ? " added"
                                : ""
                              : ""
                          return (
                            !userData?.website?.segmentation_deleted_list.includes(item) &&
                              <span className="segment-item">
                                <span
                                  key={`segmentastion-span-${idx}`}
                                  className={`segment-tag${added}`}
                                  onClick={() => this.addSegment(item)}
                              >{item}</span>
                            <span
                              role="button"
                              className="icon icon-close btn-delete"
                              onClick={() => this.deleteSegmentation(item)}
                            />
                            </span>
                          )
                        })}
                      </div>
                      {userData?.website?.segmentation?.split(',').length >= 15 ?
                        <Alert color="warning" className="mt-2">
                          We currently have segmented words capped at 15, so you won't be able to include
                          any other words unless you remove some in this case.
                        </Alert>
                        : null
                      }
                      {/*
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => this.addAllToSegmentation()}
                        disabled={website_segmentation_loading}
                      >Add all</Button>
                      */}
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button
                    color="primary"
                    disabled={
                      website_segmentation_loading
                        || !this.state.segmentation
                        || !this.isListValid()
                    }
                    onClick={() => this.updateSegmentation()}
                  ><Translate>Save changes</Translate></Button>
                </CardFooter>
              </Card>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
	state => ({
    userData: state.User.get("userData"),
    website_segmentation_loading: state.User.get("website_segmentation_loading"),
	}),
  {
    userWebsiteUpdateSegmentation,
    userWebsiteDeleteSegmentation,
  }
)(Segmentation);
