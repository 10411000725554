import React, { Component } from 'react';
import { connect } from "react-redux";
import { Input, InputGroup, Button, Label, Card, CardBody, UncontrolledTooltip, InputGroupText, FormGroup } from 'reactstrap';
import Select from 'react-select';
import { amazing, bad, great, ok, terrible } from '../../assets/images';
import './style.css';
import dashboardActions from "../../redux/dashboard/actions";
import moment from "moment";
import DateIntervalDropdown from '../../shared/components/DateIntervalDropdown';
import { NavLink } from 'react-router-dom';
import OperatorEditInfoModal from './OperatorEditInfoModal';
import userActions from "../../redux/user/actions";
import Translate from "../../shared/components/Translate";

const {
  userSlackGetUsers,
  userWebsiteUpdate,
} = userActions;

const {
  dashboardOperatorsTopPerformers,
  dashboardOperatorsExclude,
  dashboardOperatorReviewUpdate,
} = dashboardActions;

class Operators extends Component {
  state = {
    operators: [],
    search: "",
    sort_by: {value: "top", label: <Translate>Top Performing</Translate>},
    selectedInterval: {value: 'current_month', label: <Translate>This Month</Translate>},
    showIntervalDropdown: false,
    range: {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      key: 'selection'
    },
    showEditOperatorModal: false
  }

  componentDidMount = () => {
    const today = moment().format("YYYYMMDD");
    const start_month = moment().startOf('month').format("YYYYMMDD");
    const interval = `${start_month}-${today}`;

    this.props.dashboardOperatorsTopPerformers({
      interval: interval,
      filter_option: "active",
    })
    this.props.userSlackGetUsers();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if(
      this.state.operators.length === 0
      && this.props.operators_data_top_performers.length > 0
      && prevProps?.operators_data_top_performers.length === 0){
        this.setState({ operators: this.props.operators_data_top_performers});
    }

    if(this.props.operators_data_top_performers.length !== prevProps?.operators_data_top_performers.length){
      this.setState({ operators: this.props.operators_data_top_performers});
    }

    if(this.props.operators_data_top_performers !== prevProps?.operators_data_top_performers){
      this.setState({ operators: this.props.operators_data_top_performers});
    }
  }

  updateSearch = (search) => {
    const searchLowerCase = search.toLowerCase()
    const operators = this.props.operators_data_top_performers.filter(item => {
      if(
        item.first_name.toLowerCase().includes(searchLowerCase)
          || item.last_name.toLowerCase().includes(searchLowerCase)
          || item.email.toLowerCase().includes(searchLowerCase)
      ){
        return true;
      }
      return false;
    })
    this.setState({
      search: search,
      operators: operators,
    });
  }

  sortBy = (sort) => {
    var op = [...this.state.operators];

    if(sort.value === 'top'){
      op = this.props.operators_data_top_performers
    } else if(sort.value === 'name_asc'){
      op = op.sort((a, b) => a.first_name > b.first_name ? 1 : -1)
    } else if(sort.value === 'name_desc'){
      op = op.sort((a, b) => a.first_name < b.first_name ? 1 : -1)
    }

    this.setState({
      operators: op,
      sort_by: sort,
    });
  }

  toggleIntervalDropdown = () => {
    this.setState({
      showIntervalDropdown: !this.state.showIntervalDropdown
    })
  }

  customRangesChange = (ranges) => {
    this.setState({
      range: ranges['selection'],
    })
  }

  selectInterval = (selected) => {
    this.setState({ selectedInterval: selected })
    this.props.dashboardOperatorsTopPerformers({
      interval: selected.value,
      filter_option: "active",
    })
    this.props.userSlackGetUsers();
  }

  applyRange = () => {
    const { range } = this.state;

    const start = moment(range.startDate).format("YYYYMMDD");
    const end = moment(range.endDate).format("YYYYMMDD");
    this.selectInterval({value: `${start}-${end}`, label: <Translate>Custom</Translate>});
  }

  createTimeDropdown = () => {
    const today = moment().format("YYYYMMDD");
    const start_month = moment().startOf('month').format("YYYYMMDD");
    const start_year = moment().startOf('year').format("YYYYMMDD");
    const start_quarter = moment().startOf('quarter').format("YYYYMMDD");
    const end_quarter = moment().endOf('quarter').format("YYYYMMDD");

    const options = [
      {value: `${start_month}-${today}`, label: <Translate>This Month</Translate>},
      {value: `${start_quarter}-${end_quarter}`, label: <Translate>This Quarter</Translate>},
      {value: `${start_year}-${today}`, label: <Translate>This Year</Translate>},
    ]
    return options;
  }

  editOperator = (operator) => {
    this.setState({
      selected_operator: operator,
      showEditOperatorModal: true
    })
  }

  updateNoSurveySend = (user_id) => {
    const { userData } = this.props;
    var action = "add";
    if(userData?.website?.users_no_survey_send_list.includes(user_id)){
      action = "delete";
    }
    this.props.userWebsiteUpdate({
      website_id: userData?.website_id,
      users_no_survey_send: {
        action: action,
        data: user_id,
      }
    })
  }

  render() {
    const {
      operators_data_top_performers,
      userData,
      website_loading,
    } = this.props;

    return (
      <div className="main-content view-dashboard main-content-fixed-width">
        <div className="page-header">
          <h2 className="page-title mb-0"><Translate>Support Agents</Translate></h2>
          <div className="controls">
            <div>
              <Label><Translate>Search agents</Translate></Label>
              <InputGroup>
                <InputGroupText><span className="icon icon-search" /></InputGroupText>
                <Input
                  value={this.state.search}
                  onChange={(e) => this.updateSearch(e.target.value)}
                />
              </InputGroup>
            </div>

            <div style={{ minWidth: "12rem" }}>
              <Label><Translate>Sort by</Translate></Label>
              <Select
                options={[
                  {value: "top", label: <Translate>Top Performing</Translate>},
                  {value: "name_asc", label: <Translate>Name (A to Z)</Translate>},
                  {value: "name_desc", label: <Translate>Name (Z to A)</Translate>}
                ]}
                defaultValue={{value: "top", label: <Translate>Top Performing</Translate>}}
                onChange={(e) => this.sortBy(e)}
                value={this.state.sort_by}
              />
            </div>

            <div>
              <Label><Translate>Period</Translate></Label>
              <DateIntervalDropdown
                isOpen={this.state.showIntervalDropdown}
                toggle={this.toggleIntervalDropdown}
                selected={this.state.selectedInterval}
                options={this.createTimeDropdown()}
                onSelect={this.selectInterval}
                showCustom
                customRanges={[this.state.range]}
                customRangesChange={this.customRangesChange}
                applyRange={this.applyRange}
                rightDropdown
              />
            </div>
          </div>
        </div>
        <div className="page-content">
            <div className="mb-3"><strong>{operators_data_top_performers ? operators_data_top_performers?.length : 0}</strong> <span className="text-dark"><Translate special_data="operators_total_text"> total support agents found in the selected timeframe</Translate>.</span></div>
            {this.state.operators.map((item, idx) => (
              <div
                className="card-operator-wrapper"
                key={`card-operator-${idx}`}
              >
                <Card className="card-operator">
                  <CardBody>
                    <div className="profile-operator gap-2">
                      <span
                        className="avatar"
                        style={{ backgroundImage: `url(${item?.avatar})` }}
                      >
                      </span>
                      <div className="text">
                        <div className="d-flex gap-2">
                          <NavLink to={`/rating/operators/review/${item?.website_id}/${item?.user_id}`}><span className="name">{item?.first_name} {item?.last_name}</span></NavLink>
                          {item?.slack_id &&
                            <span className="slack"><span className="icon icon-slack" /></span>
                          }
                        </div>
                        <span className="email">{item?.email}</span>
                        <FormGroup switch>
                          <Label check>
                            <Input
                              type="switch"
                              checked={userData?.website?.users_no_survey_send_list.includes(item?.user_id)}
                              onChange={() => this.updateNoSurveySend(item?.user_id)}
                              disabled={website_loading}
                            />
                            Disable sending survey
                          </Label>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="achievement">
                      <div className={`achievement-item ${item?.stats["5_avg"] === 0 ? "zero" : ""}`}>
                        <img src={amazing} alt="Amazing" />
                          <span className="percentage">{item?.stats["5_avg"]}%</span>
                      </div>
                      <div className={`achievement-item ${item?.stats["4_avg"] === 0 ? "zero" : ""}`}>
                        <img src={great} alt="Great" />
                        <span className="percentage">{item?.stats["4_avg"]}%</span>
                      </div>
                      <div className={`achievement-item ${item?.stats["3_avg"] === 0 ? "zero" : ""}`}>
                        <img src={ok} alt="OK" />
                        <span className="percentage">{item?.stats["3_avg"]}%</span>
                      </div>
                      <div className={`achievement-item ${item?.stats["2_avg"] === 0 ? "zero" : ""}`}>
                        <img src={bad} alt="Bad" />
                        <span className="percentage">{item?.stats["2_avg"]}%</span>
                      </div>
                      <div className={`achievement-item ${item?.stats["1_avg"] === 0 ? "zero" : ""}`}>
                        <img src={terrible} alt="Terrible" />
                        <span className="percentage">{item?.stats["1_avg"]}%</span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="card-operator-controls">
                  <div>
                    <Button
                      color="link"
                      className="text-secondary"
                      id={`tooltip-${idx}`}
                      onClick={(e) => this.props.dashboardOperatorsExclude({exclude_id: item?.user_id})}
                      disabled={this.props.operators_data_top_performers_loading}
                    ><span className="icon icon-close" /></Button>
                        <UncontrolledTooltip target={`tooltip-${idx}`}><Translate>Exclude from rankings</Translate></UncontrolledTooltip>
                  </div>
                  <div>
                    <Button
                      color="link"
                      className="text-secondary"
                      id={`tooltip-edit-${idx}`}
                      onClick={() => this.editOperator(item)}
                    ><span className="icon icon-edit" /></Button>
                        <UncontrolledTooltip target={`tooltip-edit-${idx}`}><Translate>Edit agent info</Translate></UncontrolledTooltip>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <OperatorEditInfoModal
          isOpen={this.state.showEditOperatorModal}
          toggle={() => this.setState({ showEditOperatorModal: !this.state.showEditOperatorModal })}
          slack_users={this.props.slack_users}
          slack_users_loading={this.props.slack_users_loading}
          operator={this.state.selected_operator}
          dashboardOperatorReviewUpdate={this.props.dashboardOperatorReviewUpdate}
        />
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    operators_data_top_performers: state.Dashboard.get("operators_data_top_performers"),
    operators_data_top_performers_loading: state.Dashboard.get("operators_data_top_performers_loading"),
    slack_users: state.User.get("slack_users"),
    slack_users_loading: state.User.get("slack_users_loading"),
    operators_data_review: state.Dashboard.get("operators_data_review"),
    website_loading: state.User.get("website_loading"),
  }),
  {
    dashboardOperatorsTopPerformers,
    dashboardOperatorsExclude,
    userSlackGetUsers,
    dashboardOperatorReviewUpdate,
    userWebsiteUpdate,
  }
)(Operators);
