import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import {
  FormGroup, Input, Button, Card, CardBody, Row, Col,
  InputGroup, InputGroupText, FormFeedback, Label
} from 'reactstrap';
import { logo } from '../../assets/images';
import authActions from "../../redux/auth/actions";
import { withRouter } from "react-router";
import queryString from 'query-string';

const { userPasswordForgotReset } = authActions;

class NewPassword extends Component {
  state = {
    new_pw: "",
    new_pw_confirm: "",
    has_min_chars: false,
    has_uppercase: false,
    has_number: false,
    has_symbol: false,
    show_new_pw: false,
  }

  toggleShowPassword = (name) => {
    this.setState({ [name]: !this.state[name] })
  }

  passwordRequirementsCheck = (value) => {
    this.setState({ new_pw: value })

    // check min chars
    if(value.length >= 8) {
      this.setState({ has_min_chars: true })
    } else {
      this.setState({ has_min_chars: false })
    }

    // check if has uppercase
    if(value.toLowerCase() !== value) {
      this.setState({ has_uppercase: true })
    } else {
      this.setState({ has_uppercase: false })
    }

    // check if has number
    const matches = value.match(/\d+/g);
    if (matches !== null) {
      this.setState({ has_number: true })
    } else {
      this.setState({ has_number: false })
    }

    // check if has symbol
    const matches2 = value.match(/[!-/:-@[-`{-~]/gm);
    if (matches2 !== null) {
      this.setState({ has_symbol: true })
    } else {
      this.setState({ has_symbol: false })
    }
  }

  checkPW = () => {
    const {
      has_min_chars,
      has_uppercase,
      has_number,
      has_symbol,
    } = this.state;
    return has_min_chars && has_uppercase && has_number && has_symbol;
  }

  changePassword = () => {
    const { location } = this.props;
    const { new_pw, new_pw_confirm } = this.state;
    let params = queryString.parse(location?.search);
    this.props.userPasswordForgotReset({
      token: params?.token,
      code: params?.code,
      password: new_pw,
      confirm_password: new_pw_confirm,
    })
  }

  render() {
    const {
      new_pw,
      new_pw_confirm,
      has_min_chars,
      has_uppercase,
      has_number,
      has_symbol,
      show_new_pw,
    } = this.state;

    return (
      <main>
        <div className="centered-content-wrapper">
          <div className="text-center mb-4">
            <img src={logo} alt="Logo" />
            <h1 className="h5 mt-2">Create new password</h1>
          </div>
          <Card>
            <CardBody>
              <Row>
                <Col lg={6}>
                  <div className="auth-box">
                    <FormGroup>
                      <Label>New Password</Label>
                      <InputGroup>
                        <Input
                          type={show_new_pw ? "text" : "password"}
                          value={new_pw}
                          onChange={e => this.passwordRequirementsCheck(e.target.value)}
                          invalid={!this.checkPW()}
                        />
                        <InputGroupText
                          className="cursor-pointer"
                          onClick={() => this.toggleShowPassword("show_new_pw")}
                        >
                          <span className={`icon icon-${show_new_pw ? 'invisible' : 'visible'}`} />
                        </InputGroupText>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <Label>Confirm New Password</Label>
                      <InputGroup className="has-validation">
                        <Input
                          type={show_new_pw ? "text" : "password"}
                          value={new_pw_confirm}
                          onChange={e => this.setState({ new_pw_confirm: e.target.value })}
                          invalid={new_pw_confirm !== new_pw || !this.checkPW()}
                        />
                        <InputGroupText
                          className="cursor-pointer"
                          onClick={() => this.toggleShowPassword("show_new_pw")}
                        >
                          <span className={`icon icon-${show_new_pw ? 'invisible' : 'visible'}`} />
                        </InputGroupText>
                        <FormFeedback>
                        {new_pw_confirm !== new_pw ?
                            "Passwords need to match" : null}
                        </FormFeedback>
                      </InputGroup>
                    </FormGroup>
                    <Button
                      color="primary"
                      block
                      disabled={
                        new_pw_confirm !== new_pw
                          || !this.checkPW()
                          || this.props.reset_password_working
                      }
                      onClick={() => this.changePassword()}
                    >Reset Password</Button>
                  </div>
                </Col>
                <Col lg={6}>
                  <p><strong>In order to protect your account, make sure your password:</strong></p>
                  <ul className="password-tips-check">
                    <li className={has_min_chars ? "check" : ""}>Has minimum 8 characters</li>
                    <li className={has_uppercase ? "check" : ""}>Contains at least 1 uppercase letter</li>
                    <li className={has_number ? "check" : ""}>Contains at least 1 number</li>
                    <li className={has_symbol ? "check" : ""}>Contains at least 1 symbol</li>
                  </ul>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <div className="text-center mt-3">
            <Link to="/login">Sign In</Link>
          </div>
        </div>
      </main>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      reset_password_working: state.Auth.get("reset_password_working"),
    }),
    {
      userPasswordForgotReset,
    }
  )(NewPassword)
);
