import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import userSagas from "./user/saga";
import dashboardSagas from "./dashboard/saga";
import adminSagas from "./admin/saga";
import settingsSaga from "./settings/saga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    userSagas(),
    dashboardSagas(),
    adminSagas(),
    settingsSaga(),
  ]);
}
