import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  Card, CardBody, CardTitle
} from 'reactstrap';
import SidebarNav from './SidebarNav';
import Translate from '../../shared/components/Translate';
import Select from 'react-select';
import userActions from "../../redux/user/actions";

const {
  userWebsiteUpdate,
} = userActions;

class FilterSources extends Component {

  updateSources = (sources) => {
    const data = {
      website_id: this.props.userData.website.website_id,
      blocked_sources: sources.map(item => { return item.value; }).join(',')
    }
    this.props.userWebsiteUpdate(data);
  }

  calculateOptions = () => {
    const { userData } = this.props;
    const options = [
      { value: "contact", label: "Contact" },
      { value: "discord", label: "Discord" },
      { value: "instagram", label: "Instagram" },
      { value: "messenger", label: "Messenger" },
      { value: "teams", label: "Teams" },
      { value: "telegram", label: "Telegram" },
      { value: "ticket", label: "Ticket" },
      { value: "sms", label: "SMS" },
      { value: "twitter", label: "Twitter" },
      { value: "whatsapp", label: "WhatsApp" },
      { value: "email", label: "Email" },
    ];
    if(userData?.website?.blocked_sources_list){
      const { blocked_sources_list } = userData.website;
      return options.filter(item => { return !blocked_sources_list.includes(item.value); });
    }

    return options;
  }

  render() {
    const { userData, website_loading } = this.props;

    return (
      <div className="main-content view-filter-sources">
        <div className="page-content">
          <div className="settings-area">
            <div className="settings-menu">
              <SidebarNav />
            </div>
            <div className="settings-content">
              <Card>
                <CardBody>
                  <CardTitle className="mb-0"><Translate>Filter Sources</Translate></CardTitle>
                  <p><Translate special_data="filter_sources_desc">Filter out customer messages from certain sources, so that Rating Fish doesn't send a survey to these customers.</Translate></p>
                  <div style={{ width: "30rem" }}>
                    <Select
                      options={this.calculateOptions()}
                      isMulti
                      onChange={(e) => this.updateSources(e)}
                      value={userData?.website?.blocked_sources_list
                          ? userData?.website?.blocked_sources_list.map(item => {
                              return { value: item, label: item.charAt(0).toUpperCase() + item.slice(1) }
                            })
                        : []
                      }
                      disabled={website_loading}
                      placeholder={<Translate>Select...</Translate>}
                    />
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
	state => ({
    userData: state.User.get("userData"),
    website_loading: state.User.get("website_loading"),
	}),
  {
    userWebsiteUpdate,
  }
)(FilterSources);
