import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  Card, CardBody, CardTitle, Button, Table, FormGroup, Input, Label, FormText 
} from 'reactstrap';
import { iconSlack, amazing, terrible } from '../../assets/images';
import SidebarNav from './SidebarNav';
import Select from 'react-select';
import userActions from "../../redux/user/actions";
import Translate from "../../shared/components/Translate";

const {
  userSlackAuthStart,
  userSlackGetChannels,
  userSlackUpdateChannel,
  userSlackAuthDelete,
  userSlackGetUsers,
  userSlackUpdateUser,
} = userActions;

class Integrations extends Component {
  state = {
    slack_channels_loaded: false,
  }

  componentDidUpdate = (pp) => {
    const { userData } = this.props;
    if(userData.website.slack_access_token && !this.state.slack_channels_loaded){
      this.setState({slack_channels_loaded: true})
      this.props.userSlackGetChannels({website_id: userData?.website?.website_id});
      this.props.userSlackGetUsers();
    }
  }

  generateSlackChannelsSelect = () => {
    return this.props.slack_channels.map(item => {
      return {value: item.id, label: item.name}
    })
  }

  slackUpdateChannel = (update_point, update_value) => {
    const { userData } = this.props;
    const data = {
      website_id: userData?.website?.website_id,
      update_point: update_point,
      update_value: update_value ? update_value.value : "",
    }

    this.props.userSlackUpdateChannel(data);
  }

  slackUpdateUser = (update_point, update_value) => {
    const { userData } = this.props;
    const data = {
      website_id: userData?.website?.website_id,
      update_point: update_point,
      update_value: update_value ? update_value.value : "",
    }

    this.props.userSlackUpdateUser(data);
  }

  render() {
    const {
      slack_auth_loading,
      userData,
      slack_users,
      slack_users_loading,
      slack_channels_loading,
    } = this.props;

    const slack_channels_select = this.generateSlackChannelsSelect();

    return (
      <div className="main-content view-my-account">
        <div className="page-content">
          <div className="settings-area">
            <div className="settings-menu">
              <SidebarNav />
            </div>
            <div className="settings-content">
              <Card>
                <CardBody>
                  <CardTitle><Translate>Slack Integration</Translate></CardTitle>
                  <p>
                    <Translate
                      special_data="integrations_1"
                    >
                      Connect to Slack to enable sharing survey result to chosen Slack channel.
                    </Translate>
                  </p>
                  {!userData?.website?.slack_access_token ?
                  <Button
                    className="btn btn-slack"
                    onClick={(e) => this.props.userSlackAuthStart({website_id: userData?.website?.website_id})}
                    disabled={slack_auth_loading || userData?.website?.slack_access_token}
                  >
                    <img src={iconSlack} alt="" className="icon me-2" />
                    <Translate>Connect to Slack</Translate>
                  </Button>
                  :
                  <div className="mb-4 hstack gap-3">
                    <strong className="hstack  gap-1 text-success"><span className="icon icon-check-square bg-success" /> <Translate>Connected to Slack</Translate></strong>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={(e) => this.props.userSlackAuthDelete()}
                      disabled={slack_auth_loading || !userData?.website?.slack_access_token}
                    ><Translate>Disconnect</Translate></Button>
                  </div>
                  }
                  {userData?.website?.slack_access_token &&
                  <React.Fragment>
                    <CardTitle><Translate>Settings</Translate></CardTitle>
                    <Table className="table-settings" borderless>
                      <tbody>
                        <tr>
                          <td>
                            <div className="slack-setting">
                              <Translate>Share all reviews to</Translate>
                            </div>
                          </td>
                          <td colSpan={3}>
                            <div style={{ width: "16rem"}}>
                              <Select
                                isClearable
                                options={slack_channels_select}
                                onChange={(e) => this.slackUpdateChannel("slack_rating_channel", e)}
                                value={slack_channels_select.find(item => item.value === userData?.website?.slack_rating_channel)}
                                disabled={slack_channels_loading}
                                placeholder={<Translate>Select...</Translate>}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="slack-setting">
                              <Translate>Share</Translate> <img src={amazing} alt="" className="emoji" /> <strong><Translate>amazing</Translate></strong> <Translate>review to</Translate>
                            </div>
                          </td>
                          <td>
                            <FormGroup check>
                              <Input
                                type="checkbox"
                                id="dm1"
                                checked={userData?.website?.slack_rating_assignee_five}
                                onChange={(e) => this.slackUpdateChannel("slack_rating_assignee_five", {value: !userData?.website?.slack_rating_assignee_five})}
                                disabled={slack_channels_loading}
                              />
                              <Label check for="dm1">
                                <Translate>Agent</Translate> <br/>
                                  <FormText><Translate>As Slack DM</Translate></FormText>
                              </Label>
                            </FormGroup>
                          </td>
                          <td>
                            <Select
                              isClearable
                              options={slack_channels_select}
                              onChange={(e) => this.slackUpdateChannel("slack_rating_channel_five", e)}
                              value={slack_channels_select.find(item => item.value === userData?.website?.slack_rating_channel_five)}
                              disabled={slack_channels_loading}
                              placeholder={<Translate>Select...</Translate>}
                            />
                          </td>
                          <td>
                            <Select
                              isClearable
                              options={slack_users}
                              onChange={(e) => this.slackUpdateUser("slack_rating_user_five", e)}
                              value={slack_users.find(item => item.value === userData?.website?.slack_rating_user_five)}
                              disabled={slack_users_loading}
                              placeholder={<Translate>Select...</Translate>}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="slack-setting">
                              <Translate>Share</Translate> <img src={terrible} alt="" className="emoji" /> <strong><Translate>terrible</Translate></strong> <Translate>review to</Translate>
                            </div>
                          </td>
                          <td>
                            <FormGroup check>
                              <Input
                                type="checkbox"
                                id="dm2"
                                checked={userData?.website?.slack_rating_assignee_one}
                                onChange={(e) => this.slackUpdateChannel("slack_rating_assignee_one", {value: !userData?.website?.slack_rating_assignee_one})}
                                disabled={slack_channels_loading}
                              />
                              <Label check for="dm2">
                                <Translate>Assignee</Translate> <br/>
                                  <FormText><Translate>As Slack DM</Translate></FormText>
                              </Label>
                            </FormGroup>
                          </td>
                          <td>
                            <Select
                              isClearable
                              options={slack_channels_select}
                              onChange={(e) => this.slackUpdateChannel("slack_rating_channel_one", e)}
                              value={slack_channels_select.find(item => item.value === userData?.website?.slack_rating_channel_one)}
                              disabled={slack_channels_loading}
                              placeholder={<Translate>Select...</Translate>}
                            />
                          </td>
                          <td>
                            <Select
                              isClearable
                              options={slack_users}
                              onChange={(e) => this.slackUpdateUser("slack_rating_user_one", e)}
                              value={slack_users.find(item => item.value === userData?.website?.slack_rating_user_one)}
                              disabled={slack_users_loading}
                              placeholder={<Translate>Select...</Translate>}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </React.Fragment>
                  }
                </CardBody>
              </Card>
              {/*
              <Card>
                <CardBody>
                  <CardTitle>Testimonial.to</CardTitle>
                  <p>Provide link to your testimonial.co page</p>
                  <Row>
                    <Col lg={6} sm={12}>
                      <InputGroup>
                        <Input type="url" placeholder="Paste URL here" />
                        <Button color="primary">Save</Button>
                      </InputGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
	state => ({
    slack_auth_loading: state.User.get("slack_auth_loading"),
    userData: state.User.get("userData"),
    slack_channels: state.User.get("slack_channels"),
    slack_channels_loading: state.User.get("slack_channels_loading"),
    slack_users: state.User.get("slack_users"),
    slack_users_loading: state.User.get("slack_users_loading"),
	}),
  {
    userSlackAuthStart,
    userSlackGetChannels,
    userSlackUpdateChannel,
    userSlackAuthDelete,
    userSlackGetUsers,
    userSlackUpdateUser,
  }
)(Integrations);
