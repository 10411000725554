import React, { Component } from 'react';
import { connect } from 'react-redux';
import { amazing, bad, great, ok, terrible } from "../../assets/images";
import './style.css';
import { Button, ButtonGroup, Card, CardBody, Input, Spinner } from "reactstrap";
import dashboardActions from "../../redux/dashboard/actions";
import queryString from 'query-string';

const {
  operatorRatingExternalGet,
  operatorRatingExternalUpdate,
} = dashboardActions;

const settings = {
  symbol_type: 'emoji', // 'emoji' or 'stars'
  question_text: `Please leave a quick rating for {}.
  Help them understand how your experience was with this interaction.`,
  rating_options: [
    { rating: 1, emoji: terrible, text: "Terrible" },
    { rating: 2, emoji: bad, text: "Bad" },
    { rating: 3, emoji: ok, text: "OK" },
    { rating: 4, emoji: great, text: "Great" },
    { rating: 5, emoji: amazing, text: "Amazing" }
  ],
  input_comment_placeholder: 'Your comment here...',
  recommendation_text: `How likely are you to recommend {} to the seller community?`,
  recommendation_options: [
    {value: 5, label: 'Not recommend'},
    {value: 9, label: 'Maybe'},
    {value: 10, label: 'Yes recommend'}
  ]
}

class ExternalSurvey extends Component {
  state = {
    selectedRating: null,
    selectedRecommendation: null,
    comment: null,
  }

  componentDidMount = () => {
    const { location } = this.props;
    let params = queryString.parse(location?.search);
    this.props.operatorRatingExternalGet(params);
  }

  disabled = (rating) => {
    if(rating || this.props.external_rating_working){
      return {pointerEvents: "none",  opacity: 0.6};
    }
    return {};
  }

  operatorRatingExternalUpdate = () => {
    const { location } = this.props;
    let params = queryString.parse(location?.search);
    params.rating = this.state.selectedRating;
    params.comment = this.state.comment;
    params.reccomendation_rating = this.state.selectedRecommendation;
    this.props.operatorRatingExternalUpdate(params);
  }

  render() {
    const {
      external_rating,
      external_rating_working,
      external_rating_finished,
    } = this.props;

    return (
      <main className="external-survey">
        <div className="centered-content-wrapper" style={this.disabled(external_rating?.rating)}>
          <div className="external-survey-heading">
            {external_rating?.company_logo ?
            <img src={external_rating.company_logo} alt={external_rating.company} className="company-logo" />
            :
            <h6 className="company-name">{external_rating.company}</h6>
            }
            {!external_rating_finished ?
            <React.Fragment>
            <h1 className="page-title">Your conversation has ended.</h1>
            <p>We'd love your feedback to get us better.</p>
            </React.Fragment>
            :
            <React.Fragment>
              <h1 className="page-title">Thank you!</h1>
              <p>Thank you for sharing your thoughts. We appreciate your feedback.</p>
            </React.Fragment>
            }
          </div>

          {!external_rating_finished &&
          <React.Fragment>
          {/* Rate conversation */}
          <Card>
            <CardBody>
              <h2 className="card-title">Rate your conversation with {external_rating.operator}</h2>
              <p className="card-subtitle">
                {settings.question_text.replace("{}", external_rating?.operator)}
              </p>
              <div className="external-rating">
                {settings.rating_options.map((item, idx) => (
                <div
                  key={`rating-item-${idx}`}
                  className={external_rating?.rating
                      ? external_rating?.rating === item.rating
                        ? "external-rating-item selected"
                        : "external-rating-item"
                      : this.state.selectedRating === item.rating
                        ? "external-rating-item selected"
                        : "external-rating-item"
                  }
                  role="button"
                  onClick={() => this.setState({ selectedRating: item.rating })}
                >
                  <div className="external-rating-item-symbol">
                    {settings.symbol_type === 'emoji' ?
                    <img src={item.emoji} alt={item.text} />
                    :
                    <Stars rating={item.rating} />
                    }
                  </div>
                  <span className="external-rating-item-text">{item.text}</span>
                </div>
                ))}
              </div>
              <Input
                type="textarea"
                placeholder={settings.input_comment_placeholder}
                className="mt-3"
                defaultValue={external_rating?.comment}
                onChange={(e) => this.setState({comment: e.target.value})}
              />
            </CardBody>
          </Card>

          {/* Recommendation survey */}
          <Card>
            <CardBody>
              <h2 className="card-title">Rate Us</h2>
              <p className="card-subtitle">
                {settings.recommendation_text.replace("{}", external_rating.company)}
              </p>
              <ButtonGroup size="sm" className="mt-3">
                {settings.recommendation_options.map((item, idx) => (
                <Button
                  key={`recommendation-item-${idx}`}
                  color={external_rating?.reccomendation_rating
                    ? external_rating?.reccomendation_rating === item.value
                      ? "primary"
                      : "secondary"
                    : this.state.selectedRecommendation === item.value
                      ? "primary"
                      : "secondary"
                  }
                  onClick={() => this.setState({ selectedRecommendation: item.value })}
                >{item.label}</Button>
                ))}
              </ButtonGroup>
            </CardBody>
          </Card>

          {/* Submit */}
          <Button
            color="primary"
            size="lg"
            block
            onClick={() => this.operatorRatingExternalUpdate()}
            disabled={external_rating_working || !this.state.selectedRating}
          >
            {external_rating_working ? <Spinner size="sm" /> : 'Submit'}
          </Button>
          </React.Fragment>
          }
        </div>
      </main>
    )
  }
}

const Stars = ({ rating }) => {
  let stars = [];

  for (let i = 0; i < rating; i++) {
    stars.push(<span className="icon-duo icon-duo-star" key={`star-${i}`} />)
  }
  return stars;
}

export default connect(
  state => ({
    external_rating: state.Dashboard.get("external_rating"),
    external_rating_working: state.Dashboard.get("external_rating_working"),
    external_rating_finished: state.Dashboard.get("external_rating_finished"),
  }),
  {
    operatorRatingExternalGet,
    operatorRatingExternalUpdate,
  }
)(ExternalSurvey);
