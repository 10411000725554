import {
  all,
  fork,
} from "redux-saga/effects";
//import { push } from "react-router-redux";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
//import { sdMessage } from "../../helpers/utils";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* settingsGetAvailableLanguages() {
  yield commonSaga(
    actions.SETTINGS_AVAILABLE_LANGUAGES_GET,
    apiCalls.get,
    apiEndpoints.website.languages,
    {...default_config, ...{success_show: false}}
  );
}

export default function* settingsSaga() {
	yield all([
    fork(settingsGetAvailableLanguages),
	]);
}
