import { Component } from 'react';
import { connect } from "react-redux";
import userActions from "../../redux/user/actions";

const { userIntercomAuthDance } = userActions;

class AuthSlack extends Component {

	componentDidMount(){
		var state = this.props.location.search;
		state = state.split('state=');
		state = state[state.length-1].split('&')[0];
		state = state.replace("?", "");
		const data = {
			state: state,
			path: this.props.location.pathname + this.props.location.search,
		}
    console.log("AUTH DATA INTERCOM:", data);
    this.props.userIntercomAuthDance(data);
	}

	render() {
		return ( null )
	}
}

export default connect(
	state => ({
	}),
  {
		userIntercomAuthDance,
  }
)(AuthSlack);
