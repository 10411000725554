import React, { Component } from 'react';
import { connect } from "react-redux";
import { Alert, Button, Card, CardBody } from 'reactstrap';
import { amazing, bad, great, ok, terrible } from '../../assets/images';
import './style.css';
import dashboardActions from "../../redux/dashboard/actions";
import Translate from "../../shared/components/Translate";

const {
  dashboardOperatorsTopPerformers,
  dashboardOperatorsExclude,
} = dashboardActions;

class ExcludedOperators extends Component {

  componentDidMount = () => {
    this.props.dashboardOperatorsTopPerformers({
      filter_option: "excluded",
    })
  }

  render() {
    const {
      operators_data_top_performers,
    } = this.props;

    return (
      <div className="main-content view-dashboard main-content-fixed-width">
        <div className="page-header d-block">
          <h2 className="page-title mb-1"><Translate>Excluded Support Agents</Translate></h2>
            <span className="text-dark">
              <Translate
                special_data="operators_exluded_1"
              >
                  Excluded support agents are still active in support team but will not be considered in rankings
              </Translate>
            </span>
        </div>
        <div className="page-content">
          {(!operators_data_top_performers || !operators_data_top_performers.length) &&
          <Alert color="info">
            <Translate
              special_data="operators_exluded_2"
            >
              There are no excluded support agents
            </Translate>
          </Alert>
          }

            {operators_data_top_performers.map((item, idx) => (
              <div
                className="card-operator-wrapper"
                key={`card-operator-${idx}`}
              >
                <Card className="card-operator">
                  <CardBody>
                    <div className="profile-operator gap-2">
                      <span
                        className="avatar"
                        style={{ backgroundImage: `url(${item?.avatar})` }}
                      >
                      </span>
                      <div className="text">
                        <span className="name">{item?.first_name} {item?.last_name}</span>
                        <span className="email">{item?.email}</span>
                      </div>
                    </div>
                    <div className="achievement">
                      <div className={`achievement-item ${item?.stats["5_avg"] === 0 ? "zero" : ""}`}>
                        <img src={amazing} alt="Amazing" />
                          <span className="percentage">{item?.stats["5_avg"]}%</span>
                      </div>
                      <div className={`achievement-item ${item?.stats["4_avg"] === 0 ? "zero" : ""}`}>
                        <img src={great} alt="Great" />
                        <span className="percentage">{item?.stats["4_avg"]}%</span>
                      </div>
                      <div className={`achievement-item ${item?.stats["3_avg"] === 0 ? "zero" : ""}`}>
                        <img src={ok} alt="OK" />
                        <span className="percentage">{item?.stats["3_avg"]}%</span>
                      </div>
                      <div className={`achievement-item ${item?.stats["2_avg"] === 0 ? "zero" : ""}`}>
                        <img src={bad} alt="Bad" />
                        <span className="percentage">{item?.stats["2_avg"]}%</span>
                      </div>
                      <div className={`achievement-item ${item?.stats["1_avg"] === 0 ? "zero" : ""}`}>
                        <img src={terrible} alt="Terrible" />
                        <span className="percentage">{item?.stats["1_avg"]}%</span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="card-operator-controls">
                  <Button
                    color="success"
                    id={`tooltip-${idx}`}
                    onClick={(e) => this.props.dashboardOperatorsExclude({exclude_id: item?.user_id})}
                    disabled={this.props.operators_data_top_performers_loading}
                  ><Translate>Include</Translate></Button>
                </div>
              </div>
            ))}
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    operators_data_top_performers: state.Dashboard.get("operators_data_top_performers"),
    operators_data_top_performers_loading: state.Dashboard.get("operators_data_top_performers_loading"),
  }),
  {
    dashboardOperatorsTopPerformers,
    dashboardOperatorsExclude,
  }
)(ExcludedOperators);
