import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  Card, CardBody, CardTitle, FormGroup, Label, Input, UncontrolledTooltip,
  FormText, InputGroup, Row, Col, Button, InputGroupText, Badge } from 'reactstrap';
import SidebarNav from './SidebarNav';
import settingsActions from "../../redux/settings/actions";
import Translate from "../../shared/components/Translate";
import Select from 'react-select';
import userActions from "../../redux/user/actions";
import CustomizeSurveys from './CustomizeSurveys';

const {
  settingsGetAvailableLanguages,
} = settingsActions;

const {
  userWebsiteUpdate,
} = userActions;

const survey_type_options = [
  {value: 'transactional', label: 'Transactional'},
  {value: 'nps', label: 'NPS'}
]

class Survey extends Component {
  state = {
    rating_symbol: 'face',
    rating_cooldown: null,
  }

  componentDidMount = () => {
    this.props.settingsGetAvailableLanguages();
  }

  surveyLanguagesOptions = () => {
    const { website_available_languages } = this.props;
    var data = [];
    if(website_available_languages){
      data = website_available_languages.map(item => {
        return {
          label: <span>{item?.name}</span>,
          value: item?.language?.toUpperCase(),
          language: item?.language.toUpperCase(),
        }
      })
    }
    return data;
  }

  updateSurveyLanguage = (language) => {
    this.props.userWebsiteUpdate(
      {
        website_id: this.props.userData?.website?.website_id,
        forced_survey_language: language ? language : "",
      }
    )
  }

  toggleWebsiteSurveyTranslateActive = () => {
    this.props.userWebsiteUpdate({
      website_id: this.props.userData?.website?.website_id,
      survey_templates_active: !this.props.userData?.website?.survey_templates_active
    })
  }

  ratingSurveyDisableToggle = () => {
    this.props.userWebsiteUpdate({
      website_id: this.props.userData?.website?.website_id,
      rating_survey_disable: !this.props.userData?.website?.rating_survey_disable
    })
  }

  ratingSurveyDisableCommentToggle = () => {
    this.props.userWebsiteUpdate({
      website_id: this.props.userData?.website?.website_id,
      rating_survey_comment_disable: !this.props.userData?.website?.rating_survey_comment_disable
    })
  }

  ratingSurveyAgentDisableToggle = () => {
    this.props.userWebsiteUpdate({
      website_id: this.props.userData?.website?.website_id,
      no_operator_survey_disable: !this.props.userData?.website?.no_operator_survey_disable
    })
  }

  ratingSurveyTypeUpdate = (e) => {
    this.props.userWebsiteUpdate({
      website_id: this.props.userData?.website?.website_id,
      rating_survey_type: e.value
    })
  }

  ratingRecommendationDisableToggle = () => {
    this.props.userWebsiteUpdate({
      website_id: this.props.userData?.website?.website_id,
      rating_recommendation_disable: !this.props.userData?.website?.rating_recommendation_disable
    })
  }

  ratingSendByEmailToggle = () => {
    this.props.userWebsiteUpdate({
      website_id: this.props.userData?.website?.website_id,
      rating_survey_by_email: !this.props.userData?.website?.rating_survey_by_email
    })
  }

  render() {
    const {
      userData,
      website_available_languages_loading,
      website_loading,
    } = this.props;

    return (
      <div className="main-content view-segmentation">
        <div className="page-content">
          <div className="settings-area">
            <div className="settings-menu">
              <SidebarNav />
            </div>
            <div className="settings-content">
              <Card>
                <CardBody>
                  <CardTitle><Translate>Survey settings</Translate></CardTitle>
                  <Row className="gx-5">
                    <Col xl={6}>
                      <FormGroup>
                        <Label><Translate>Force Survey Language</Translate></Label>
                        <div style={{ width: "10rem" }}>
                          <Select
                            options={this.surveyLanguagesOptions()}
                            defaultValue={null}
                            isClearable={true}
                            isSearchable={false}
                            onChange={e => this.updateSurveyLanguage(e?.language)}
                            value={
                              userData?.website?.forced_survey_language
                                ? this.surveyLanguagesOptions().find(item => item?.language === userData?.website?.forced_survey_language)
                                : null
                            }
                            disabled={website_available_languages_loading || website_loading}
                          />
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <Label>
                          <Translate>Survey type delivered</Translate>
                          <span className="icon icon-info bg-secondary" id="survey-type-popover" />
                        </Label>
                        <Select
                          options={survey_type_options}
                          onChange={(e) => this.ratingSurveyTypeUpdate(e)}
                          value={survey_type_options.find(item => item.value === userData?.website?.rating_survey_type)}
                        />
                        <UncontrolledTooltip target={`survey-type-popover`}>
                          Choose type of survey delivered when conversation is resolved
                        </UncontrolledTooltip>
                      </FormGroup>

                      <FormGroup>
                        <Label><Translate>Rating Request</Translate></Label>
                        <FormGroup check>
                          <Input
                            type="checkbox"
                            id="check_disable_rating_request"
                            checked={userData?.website?.rating_survey_disable ? true : false}
                            onChange={() => this.ratingSurveyDisableToggle()}
                            disabled={website_loading}
                          />
                          <Label check for="check_disable_rating_request" className="text-danger">
                            <Translate>Disable rating request flow</Translate>
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input
                            type="checkbox"
                            id="check_disable_rating_comment"
                            checked={userData?.website?.rating_survey_comment_disable ? true : false}
                            onChange={() => this.ratingSurveyDisableCommentToggle()}
                            disabled={website_loading}
                          />
                          <Label check for="check_disable_rating_comment" className="text-danger">
                            <Translate>Disable rating comment</Translate>
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input
                            type="checkbox"
                            id="check_disable_rating_no_agent"
                            checked={userData?.website?.no_operator_survey_disable ? true : false}
                            onChange={() => this.ratingSurveyAgentDisableToggle()}
                            disabled={website_loading}
                          />
                          <Label check for="check_disable_rating_no_agent" className="text-danger">
                            <Translate>Disable rating request if no agent assigned</Translate>
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                    <Col xl={6}>
                      <FormGroup>
                        <Label><Translate>NPS Request</Translate></Label>
                        <FormGroup check>
                          <Input
                            type="checkbox"
                            id="check_disable_nps"
                            checked={userData?.website?.rating_recommendation_disable ? true : false}
                            onChange={() => this.ratingRecommendationDisableToggle()}
                            disabled={website_loading}
                          />
                          <Label check for="check_disable_nps" className="text-danger">
                            <Translate>Disable NPS</Translate>
                          </Label>
                        </FormGroup>
                      </FormGroup>
                      <FormGroup>
                        <Label>
                          <Translate>Resend rating cooldown</Translate>
                          <span className="icon icon-info bg-secondary" id="resend-rating-cooldown" />
                        </Label>
                        <InputGroup style={{ maxWidth: '13rem' }}>
                          <Input
                            type="number"
                            value={this.state.rating_cooldown !== null
                              ? parseInt(this.state.rating_cooldown, 10)
                              : parseInt(userData?.website?.rating_cooldown, 10)
                            }
                            onChange={(e) => {
                              if(isNaN(parseInt(e.target.value, 10)) || parseInt(e.target.value, 10) >= 0){
                                this.setState({rating_cooldown: parseInt(e.target.value, 10) ? parseInt(e.target.value, 10) : 0})
                              }
                            }}
                          />
                          <InputGroupText>mins</InputGroupText>
                          <Button
                            color="primary"
                            disabled={website_loading || this.state.rating_cooldown === null || this.state.rating_cooldown === userData?.website?.rating_cooldown}
                            onClick={
                              () => this.props.userWebsiteUpdate({
                                website_id: this.props.userData?.website?.website_id,
                                rating_cooldown: parseInt(this.state.rating_cooldown, 10),
                              })
                            }
                          >Update</Button>
                        </InputGroup>
                        <UncontrolledTooltip target={`resend-rating-cooldown`}>
                          Set time limit to resend rating in minutes
                        </UncontrolledTooltip>
                      </FormGroup>
                      <FormGroup>
                        <Label><Translate>Email Request</Translate></Label>
                        <FormGroup check>
                          <Input
                            type="checkbox"
                            id="rsbe"
                            checked={userData?.website?.rating_survey_by_email ? true : false}
                            onChange={() => this.ratingSendByEmailToggle()}
                            disabled={website_loading}
                          />
                          <Label check for="rsbe">
                            <Translate>Send rating request by email</Translate>
                          </Label>
                        </FormGroup>
                      </FormGroup>
                      <FormGroup>
                        <Label><Translate>Follow-up Comments</Translate> <Badge size="sm" color="warning">NEW</Badge></Label>
                        <FormGroup check>
                          <Input
                            type="checkbox"
                            id="check_followup_comments"
                            checked={userData?.website?.follow_up_enabled ? true : false}
                            onChange={
                              () => this.props.userWebsiteUpdate({
                                website_id: this.props.userData?.website?.website_id,
                                follow_up_enabled: !userData?.website?.follow_up_enabled,
                              })
                            }
                            disabled={website_loading}
                          />
                          <Label check for="check_followup_comments">
                            <Translate>Enable follow-up comments for each rating received</Translate>
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input
                            type="checkbox"
                            id="check_followup_comments_enabled"
                            checked={userData?.website?.follow_up_comment_enabled ? true : false}
                            onChange={
                              () => this.props.userWebsiteUpdate({
                                website_id: this.props.userData?.website?.website_id,
                                follow_up_comment_enabled: !userData?.website?.follow_up_comment_enabled,
                              })
                            }
                            disabled={website_loading || !userData?.website?.follow_up_enabled}
                          />
                          <Label check for="check_followup_comments_enabled">
                            <Translate>Replace <b>Support Comment</b> text with follow up comment</Translate>
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div>
                    <div>
                      <CardTitle><Translate>Customize Surveys</Translate></CardTitle>
                      <FormGroup className="mt-4">
                        <strong><Translate>Rating Symbol</Translate></strong>
                        <FormText className="ms-2"><Translate special_data="rating_symbol_type">Choose rating symbol that will be displayed</Translate></FormText>
                        <br />
                        <div className="hstack gap-5">
                          <FormGroup check>
                            <Label className="mb-0">
                              <Input
                                type="radio"
                                name="ratingSymbol"
                                checked={!userData?.website?.rating_symbol || userData?.website?.rating_symbol === "face"}
                                onChange={() =>  this.props.userWebsiteUpdate({
                                  website_id: userData?.website?.website_id,
                                  rating_symbol: "face",
                                })}
                              /> Face Expression
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label className="mb-0">
                              <Input
                                type="radio"
                                name="ratingSymbol"
                                checked={userData?.website?.rating_symbol === 'stars'}
                                onChange={() =>  this.props.userWebsiteUpdate({
                                  website_id: userData?.website?.website_id,
                                  rating_symbol: "stars",
                                })}
                              /> Stars
                            </Label>
                          </FormGroup>
                        </div>
                      </FormGroup>
                      <FormGroup className="mt-4">
                        <strong><Translate>Custom Message</Translate></strong>
                        <FormGroup check>
                          <Label>
                            <Input
                              type="checkbox"
                              onChange={() => this.toggleWebsiteSurveyTranslateActive()}
                              checked={userData?.website?.survey_templates_active ? true : false}
                              disabled={website_loading}
                            />
                            <Translate>Use custom text below for surveys</Translate>
                          </Label>
                        </FormGroup>
                        <CustomizeSurveys
                          userData={userData}
                          website_loading={website_loading}
                          userWebsiteUpdate={this.props.userWebsiteUpdate}
                          survey_templates_active={userData?.website?.survey_templates_active}
                          rating_symbol={this.state.rating_symbol}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
	state => ({
    userData: state.User.get("userData"),
    website_available_languages: state.Settings.get("website_available_languages"),
    website_available_languages_loading: state.Settings.get("website_available_languages_loading"),
    website_loading: state.User.get("website_loading"),
	}),
  {
    settingsGetAvailableLanguages,
    userWebsiteUpdate,
  }
)(Survey);
