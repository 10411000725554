import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
  Button, Card, CardBody, Form, FormGroup, Input, InputGroup, InputGroupText
} from 'reactstrap';
import { logo } from '../../assets/images';
import queryString from 'query-string';
import authActions from "../../redux/auth/actions";

const { userRegister } = authActions;

class Register extends Component {
  state = {
    showPassword: false,
    username: "",
    email: "",
    password: "",
    confirm_password: "",
  }

  toggleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  register = () => {
    const { location } = this.props;
    const { username, email, password, confirm_password } = this.state;
    let params = queryString.parse(location?.search);
    const data = {
      website_id: params.website_id,
      token: params?.token,
      state: params?.state,
      user_data: {
        username: username,
        email: email,
        password: password,
        confirm_password: confirm_password,
      }
    }
    this.props.userRegister(data)
  }

  validateInput = () => {
    const { username, email, password, confirm_password } = this.state;
    if(!username || !email || !password || !confirm_password){
      return false;
    }
    if(password !== confirm_password){
      return false;
    }

    return true;
  }

  displayError = () => {
    const { password, confirm_password } = this.state;
    if(password !== confirm_password && (password && confirm_password)){
      return "* Passwords need to match";
    }
    return null;
  }

  render() {
    const { username, email, password, confirm_password } = this.state;

    return (
      <main>
        <div className="centered-content-wrapper">
          <div className="text-center mb-4">
            <img src={logo} alt="Logo" />
            <h1 className="h5 mt-2">Dashboard</h1>
          </div>
          <Card className="border-none">
            <CardBody>
              <div className="auth-box">
                <h2 className="h5 mb-5 text-center">Create an account</h2>
                <Form>
                  <FormGroup>
                    <Input
                      placeholder="Username"
                      value={username}
                      onChange={(e) => this.setState({username: e.target.value.toLowerCase()})}
                      invalid={!username}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      placeholder="Email"
                      value={email}
                      onChange={(e) => this.setState({email: e.target.value})}
                      invalid={!email}
                    />
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="addon-white">
                      <Input
                        type={this.state.showPassword ? "text" : "password"}
                        placeholder="Password"
                        autoComplete="new-password"
                        value={password}
                        onChange={(e) => this.setState({password: e.target.value})}
                        invalid={!password || !confirm_password || (password !== confirm_password)}
                      />
                      <InputGroupText role="button" onClick={this.toggleShowPassword}>
                        <span className={`icon icon-${this.state.showPassword ? 'invisible' : 'visible'}`} />
                      </InputGroupText>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="addon-white">
                      <Input
                        type={this.state.showPassword ? "text" : "password"}
                        placeholder="Confirm Password"
                        autoComplete="new-password"
                        value={confirm_password}
                        onChange={(e) => this.setState({confirm_password: e.target.value})}
                        invalid={!password || !confirm_password || (password !== confirm_password)}
                      />
                      <InputGroupText role="button" onClick={this.toggleShowPassword}>
                        <span className={`icon icon-${this.state.showPassword ? 'invisible' : 'visible'}`} />
                      </InputGroupText>
                    </InputGroup>
                    <div style={{color: "red", fontWeight: "bold"}}>{this.displayError()}</div>
                  </FormGroup>
                  <Button
                    color="primary"
                    block
                    onClick={() => this.register()}
                    disabled={this.props.registration_working || !this.validateInput()}
                  >Create account</Button>
                </Form>
              </div>
              {this.props.registration_data?.error &&
                  <div style={{color: "red", fontWeight: "bold"}}>{this.props.registration_data?.error}</div>
              }
              <p className="text-center mt-3 mb-0">
                Already have account? <Link to="/login">Sign in</Link>
              </p>
            </CardBody>
          </Card>

          <footer className="small text-muted mt-5">Copyright 2022 Rating Fish</footer>
        </div>
      </main>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      registration_working: state.Auth.get("registration_working"),
      registration_data: state.Auth.get("registration_data"),
    }),
    {
      userRegister,
    }
  )(Register)
);
