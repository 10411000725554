import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  Card, CardBody, CardTitle, Button, Badge, Modal, ModalHeader,
  ModalBody, ModalFooter, FormGroup, Label, Input, InputGroup, InputGroupText,
  Spinner, UncontrolledTooltip, FormFeedback
} from 'reactstrap';
import SidebarNav from './SidebarNav';
import Translate from '../../shared/components/Translate';
import { translate_text } from "../../helpers/translation/utils";
import BootstrapTable from 'react-bootstrap-table-next';
//import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ConfirmModal from '../../shared/components/ConfirmModal';
import userActions from "../../redux/user/actions";
import dashboardActions from "../../redux/dashboard/actions";
import Select, { components } from 'react-select';

const {
  userEmployeesLoad,
  userEmployeeUpdate,
  userEmployeeAdd,
} = userActions;

const { dashboardOperators } = dashboardActions;

const CustomOption = props => {
  return (
    <components.Option {...props} className="profile-operator profile-operator-option">
      <span className="avatar" style={{ backgroundImage: `url(${props.data.img_url})` }}></span>
      <div className="text">
        <strong className="name">{props.data.label}</strong>
        <span className="email">{props.data.email}</span>
      </div>
    </components.Option>
  )
}

class EmployeeAccounts extends Component {
  state = {
    sizePerPage: 10,
    sortOrder: 'desc',
    sortField: 'id',
    show_new_pw: false,
    showEmployeeModal: false,
    showConfirmDialog: false,
    mode: "add",
    employee_data: {},
    deleteId: null,
    showAgentModal: false,
    editId: null,
    editField: null,
    showChangePassword: null,
    show_edit_pw: false,
    edit_new_pw: "",
    edit_confirm_pw: ""
  }

  editMode = (id, field) => {
    this.setState({
      editId: id,
      editField: field
    })
    console.log("edit id: ", id)
    console.log("edit field: ", field)
  }

  generateColumns = () => {
    const { userData } = this.props;
    const IconEdit = () => <span className="icon icon-edit bg-secondary" role="button" />

    const columns = [
      {
        dataField: 'first_name',
        text: translate_text("First Name", userData?.settings?.language),
        formatter: (cell, row) => (
          <div className="hstack gap-2">
            <span>{cell}</span>
            <IconEdit />
          </div>
        )
      },
      {
        dataField: 'last_name',
        text: translate_text("Last Name", userData?.settings?.language),
        formatter: (cell, row) => (
          <div className="hstack gap-2">
            <span>{cell}</span>
            <IconEdit />
          </div>
        )
      },
      {
        dataField: 'username',
        text: translate_text("Username", userData?.settings?.language),
        editable: false
      },
      {
        dataField: 'email',
        text: translate_text("Email", userData?.settings?.language),
        editable: false
      },
      {
        dataField: 'is_admin',
        text: translate_text("Admin Access", userData?.settings?.language),
        style: { width: "10%" },
        editable: false,
        formatter: (cell, row) => (
          <FormGroup switch>
            <Input
              type="switch"
              checked={row.is_employee_admin ? true : false}
              onChange={(e) => this.props.userEmployeeUpdate({
                employee_id: row.id,
                is_employee_admin: !row.is_employee_admin,
              })}
            />
            <Label check>{row.is_employee_admin ? "Yes" : "No"}</Label>
          </FormGroup>
        )
      },
      {
        dataField: 'agent_id',
        text: translate_text("Linked Agent", userData?.settings?.language),
        editable: false,
        formatter: (cell, row) => this.getOperatorFromId(row)
      },
      {
        dataField: 'is_employee_active',
        text: translate_text("Status", userData?.settings?.language),
        editable: false,
        formatter: (cell, row) => (
          <FormGroup switch>
            <Input
              type="switch"
              checked={cell}
              onChange={(e) => this.toggleUserActivate(row)}
            />
            <span>{cell ? <Badge color="success">Active</Badge> : <Badge color="dark">Inactive</Badge>}</span>
          </FormGroup>
        )
      },
      {
        dataField: 'df1',
        isDummyField: true,
        text: translate_text("Actions", userData?.settings?.language),
        editable: false,
        formatter: (cell ,row) => (
          <div className="d-flex gap-3">
            {/* {row.is_employee_active ?
              <Button
                color="warning"
                size="sm"
                onClick={() => this.toggleUserActivate(row)}
                disabled={this.props.employees_loading}
              ><Translate>Deactivate</Translate></Button> :
              <Button
                color="success"
                size="sm"
                onClick={() => this.toggleUserActivate(row)}
                disabled={this.props.employees_loading}
              ><Translate>Activate</Translate></Button>
            } */}
            <span
              className="icon icon-lock"
              id={`password-${row.id}`}
              role="button"
              onClick={(e) => {
                e.stopPropagation()
                this.setState({ showChangePassword: row })
              }}
            />
            <UncontrolledTooltip target={`password-${row.id}`}>Change password</UncontrolledTooltip>
            <span
              id={`delete-${row.id}`}
              className="icon icon-trash bg-danger"
              role="button"
              onClick={() => this.setState({ deleteId: row.id })}
              disabled={this.props.employees_loading || row.is_employee_active}
            />
            <UncontrolledTooltip target={`delete-${row.id}`}>Delete employee account</UncontrolledTooltip>
            {/* <Button
              color="danger"
              size="sm"
              onClick={() => this.setState({ deleteId: row.id })}
              disabled={this.props.employees_loading || row.is_employee_active}
            >
              <Translate>Delete</Translate>
            </Button> */}
          </div>
        )
      },
    ];
    return columns;
  }

  passwordRequirementsCheck = (value) => {
    // check min chars
    if(value.length < 8) {
      return <Translate special_data="pw_check_length">Password length need to be at least 8 characters</Translate>;
    }

    // check if has uppercase
    if(value.toLowerCase() === value) {
      return <Translate special_data="pw_check_uppercase">Password need to contain at least one uppercase character</Translate>;
    }

    // check if has number
    const matches = value.match(/\d+/g);
    if (matches === null) {
      return <Translate special_data="pw_check_number">Password need to contain at least one number</Translate>;
    }

    // check if has symbol
    const matches2 = value.match(/[!-/:-@[-`{-~]/gm);
    if (matches2 === null) {
      return <Translate special_data="pw_check_symbol">Password need to contain at least one symbol</Translate>;
    }
    return null;
  }

  checkErrorEmployeeData = () => {
    const { employee_data } = this.state;
    if(employee_data?.username?.length < 8 || !employee_data?.username){
      return <Translate special_data="pw_check_username">Username need to be at least 8 characters long</Translate>;
    }
    if(!employee_data?.email?.includes("@")){
      return <Translate special_data="pw_check_email">Email need to be provided</Translate>;
    }
    if(!employee_data?.id_operator){
      return "Operater need to be selected";
    }
    return this.passwordRequirementsCheck(employee_data?.password ? employee_data?.password : "")
  }

  toggleUserActivate = (row) => {
    this.props.userEmployeeUpdate({
      is_employee_active: !row.is_employee_active,
      employee_id: row.id,
    })
  }

  toggleShowPassword = (name) => {
    this.setState({ [name]: !this.state[name] })
  }

  toggleEmployeeModal = () => {
    this.setState({
      showEmployeeModal: !this.state.showEmployeeModal,
      employee_data: {},
    })
  }

  componentDidMount = () => {
    this.props.dashboardOperators({filter_option: "all"});
    this.props.userEmployeesLoad();
  }

  updateEmployeeData = (key, data) => {
    var ed = {...this.state.employee_data}
    ed[key] = data;
    this.setState({ employee_data: ed });
  }

  addNewEmployee = () => {
    const data = {
      user_data: this.state.employee_data,
    }
    this.props.userEmployeeAdd(data)
    this.toggleEmployeeModal();
  }

  confirmDeleteEmployee = () => {
    this.props.userEmployeeUpdate({
      deleted: true,
      employee_id: this.state.deleteId,
    })

    this.setState({ deleteId: null })
  }

  displayOperators = () => {
    const { operators_data } = this.props;
    if(!operators_data){ return [] }
    return operators_data.map(item => {
      return {
        value: item.id,
        label: `${item.first_name} ${item.last_name}`,
        img_url: item.avatar,
      }
    })
  }

  getOperatorFromId = (row) => {
    if(!row?.id_operator){
      return(
        <Button
          color="link"
          size="sm"
          className="p-0"
          onClick={() => this.setState({ showAgentModal: row })}
        >Select agent</Button>
      )
    }
    const { operators_data } = this.props;
    const operator = operators_data.find(item => item.id === row.id_operator);
    if(!operator){
      return(
        <Button
          color="link"
          size="sm"
          className="p-0"
          onClick={() => this.setState({ showAgentModal: row })}
        >Select agent</Button>
      )
    }
    return (
      <React.Fragment>
        <div className="hstack gap-2">
          <div className="profile-operator gap-2">
            <span className="avatar" style={{ backgroundImage: `url(${operator.avatar})` }}></span>
            <div className="text">
              <strong className="name">{`${operator.first_name} ${operator.last_name}`}</strong>
            </div>
          </div>
          <span
            role="button"
            className="icon icon-8 icon-close bg-danger"
            onClick={() => this.props.userEmployeeUpdate({
              employee_id: row.id,
              id_operator: 0,
            })}
          />
        </div>
      </React.Fragment>
    )
  }

  closeChangePassword = () => {
    this.setState({
      showChangePassword: null,
      edit_new_pw: "",
      edit_confirm_pw: "",
      show_edit_pw: false
    })
  }

  render() {
    const { employees_loading, employees } = this.props;
    const { employee_data } = this.state;

    //console.log("Employee Data:", employee_data);
    //console.log("Employees:", employees);

    return (
      <div className="main-content view-employee">
        <div className="page-content">
        <div className="settings-area">
          <div className="settings-menu">
            <SidebarNav />
          </div>
          <div className="settings-content">
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <CardTitle className="mb-0"><Translate>Employee Accounts</Translate></CardTitle>
                  <Button
                    color="primary"
                    onClick={() => {
                      this.toggleEmployeeModal();
                      this.setState({ mode: "add" })
                    }}
                    ><Translate>Add new employee</Translate></Button>
                </div>
                <div className="table-wrapper">
                  {employees_loading &&
                  <div className="card-loading" style={{ height: "100%", top: 0 }}>
                    <div className="loading-indicator">
                      <Spinner color="secondary" /> <span>Loading...</span>
                    </div>
                  </div>
                  }
                  <BootstrapTable
                    keyField="id"
                    columns={this.generateColumns()}
                    bordered={false}
                    classes="table-employee"
                    wrapperClasses="table-responsive"
                    data={employees ? employees : []}
                    cellEdit={cellEditFactory({
                      mode: 'click',
                      blurToSave: true,
                      afterSaveCell: (oldValue, newValue, row, col) => {
                        this.props.userEmployeeUpdate({
                          employee_id: row.id,
                          [col.dataField]: newValue,
                        });
                      }
                    })}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
        </div>
        <Modal isOpen={this.state.showEmployeeModal} toggle={this.toggleEmployeeModal}>
          <ModalHeader toggle={this.toggleEmployeeModal}>
            {this.state.mode === "add" ?
            <Translate>Add new employee</Translate>
            :
            <Translate>Edit employee</Translate>
            }
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label><Translate>Link to agent</Translate></Label>
              <Select
                placeholder="Select agent..."
                options={this.displayOperators()}
                components={{ Option: CustomOption }}
                isClearable
                onChange={(selected, action) => {
                  if(action?.action === 'clear'){
                    this.updateEmployeeData("id_operator", null)
                  } else {
                    this.updateEmployeeData("id_operator", selected.value)
                  }
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label><Translate>Username</Translate></Label>
              <Input
                onChange={(e) => this.updateEmployeeData("username", e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label><Translate>Email</Translate></Label>
              <Input
                type="email"
                onChange={(e) => this.updateEmployeeData("email", e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label><Translate>First Name</Translate></Label>
              <Input
                onChange={(e) => this.updateEmployeeData("first_name", e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label><Translate>Last Name</Translate></Label>
              <Input
                onChange={(e) => this.updateEmployeeData("last_name", e.target.value)}
              />
            </FormGroup>
            <React.Fragment>
              <FormGroup>
                <Label><Translate>Password</Translate></Label>
                <InputGroup>
                  <Input
                    autoComplete="new-password"
                    type={this.state.show_new_pw ? "text" : "password"}
                    onChange={(e) => this.updateEmployeeData("password", e.target.value)}
                  />
                  <InputGroupText
                    className="cursor-pointer"
                    onClick={() => this.toggleShowPassword("show_new_pw")}
                  >
                    <span className={`icon icon-${this.state.show_new_pw ? 'invisible' : 'visible'}`} />
                  </InputGroupText>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label><Translate>Confirm Password</Translate></Label>
                <InputGroup>
                  <Input
                    type={this.state.show_new_pw ? "text" : "password"}
                    value={this.state.new_pw_confirm}
                    invalid={employee_data?.password !== employee_data?.confirm_password}
                    onChange={(e) => this.updateEmployeeData("confirm_password", e.target.value)}
                  />
                  <InputGroupText
                    className="cursor-pointer"
                    onClick={() => this.toggleShowPassword("show_new_pw")}
                  >
                    <span className={`icon icon-${this.state.show_new_pw ? 'invisible' : 'visible'}`} />
                  </InputGroupText>
                </InputGroup>
                <InputGroup>
                  <div style={{ color: "red" }}>
                    {employee_data?.password !== employee_data?.confirm_password
                      ? <Translate special_data="pw_check_match">Passwords need to match</Translate>
                      : null
                    }
                    <br />
                    {this.checkErrorEmployeeData()}
                  </div>
                </InputGroup>
              </FormGroup>
            </React.Fragment>
          </ModalBody>
          <ModalFooter>
            <Button color="light" onClick={this.toggleEmployeeModal}><Translate>Cancel</Translate></Button>
            <Button
              color="primary"
              onClick={() => this.addNewEmployee()}
              disabled={
                employees_loading
                  || !!this.checkErrorEmployeeData()
                  || (employee_data?.password !== employee_data?.confirm_password)
              }
            ><Translate>Create employee account</Translate></Button>
          </ModalFooter>
        </Modal>
        <ConfirmModal
          message="Are you sure want to delete this employee?"
          yesColor="danger"
          isOpen={this.state.deleteId}
          toggle={() => this.setState({ deleteId: null })}
          onConfirm={this.confirmDeleteEmployee}
        />
        <Modal isOpen={this.state.showAgentModal ? true : false} toggle={() => this.setState({ showAgentModal: null })}>
          <ModalHeader
            toggle={() => this.setState({ showAgentModal: null })}
          >Link employee to agent</ModalHeader>
          <ModalBody>
            <Select
              placeholder="Select agent..."
              options={this.displayOperators()}
              components={{ Option: CustomOption }}
              isClearable
              onChange={(selected, action) => {
                if(action?.action === 'clear'){
                  this.props.userEmployeeUpdate({
                    employee_id: this.state.showAgentModal.id,
                    id_operator: 0,
                  })
                } else {
                  this.props.userEmployeeUpdate({
                    employee_id: this.state.showAgentModal.id,
                    id_operator: selected.value,
                  })
                }
                this.setState({ showAgentModal: null });
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.setState({ showAgentModal: null })}>Cancel</Button>
            <Button color="primary">Link agent</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.showChangePassword ? true : false} toggle={this.closeChangePassword}>
          <ModalHeader toggle={this.closeChangePassword}>
            Change employee password
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label><Translate>Password</Translate></Label>
              <InputGroup>
                <Input
                  autoComplete="new-password"
                  type={this.state.show_edit_pw ? "text" : "password"}
                  value={this.state.edit_new_pw}
                  onChange={(e) => this.setState({ edit_new_pw: e.target.value })}
                />
                <InputGroupText
                  className="cursor-pointer"
                  onClick={() => this.toggleShowPassword("show_edit_pw")}
                >
                  <span className={`icon icon-${this.state.show_edit_pw ? 'invisible' : 'visible'}`} />
                </InputGroupText>
              </InputGroup>
              <FormFeedback invalid className="d-block">{this.passwordRequirementsCheck(this.state.edit_new_pw)}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label><Translate>Confirm Password</Translate></Label>
              <InputGroup>
                <Input
                  type={this.state.show_edit_pw ? "text" : "password"}
                  value={this.state.edit_confirm_pw}
                  onChange={(e) => this.setState({ edit_confirm_pw: e.target.value })}
                />
                <InputGroupText
                  className="cursor-pointer"
                  onClick={() => this.toggleShowPassword("show_edit_pw")}
                >
                  <span className={`icon icon-${this.state.show_edit_pw ? 'invisible' : 'visible'}`} />
                </InputGroupText>
              </InputGroup>
              {this.state.edit_new_pw !== this.state.edit_confirm_pw
                ? <FormFeedback invalid className="d-block">
                    <Translate special_data="pw_check_match">Passwords need to match</Translate>
                  </FormFeedback>
                : null
              }
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.closeChangePassword}>Cancel</Button>
            <Button color="primary">Change password</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default connect(
	state => ({
    userData: state.User.get("userData"),
    employees_loading: state.User.get("employees_loading"),
    employees: state.User.get("employees"),
    operators_data: state.Dashboard.get("operators_data"),
    operators_data_loadings: state.Dashboard.get("operators_data_loadings"),
	}),
  {
    userEmployeesLoad,
    userEmployeeUpdate,
    userEmployeeAdd,
    dashboardOperators,
  }
)(EmployeeAccounts);
