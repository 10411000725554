import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  Card, CardBody, Row, Spinner, Col, Input, FormGroup, Label, Button, UncontrolledPopover, PopoverBody,
  Nav, NavItem, NavLink as NavLinkTab, TabContent, TabPane, Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { amazing, bad, great, ok, terrible, gold, silver, bronze } from '../../assets/images';
import RatingsTable from './RatingsTable';
import Select from "react-select";
import DateIntervalDropdown from '../../shared/components/DateIntervalDropdown';
import './style.css';
import dashboardActions from "../../redux/dashboard/actions";
import moment from "moment";
import userActions from "../../redux/user/actions";
import Translate from "../../shared/components/Translate";
import TimeRangePicker from '@wojtekmaj/react-timerange-picker/dist/TimeRangePicker';
import '@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css';
import ReactWordcloud from 'react-wordcloud';
import ReactApexChart from "react-apexcharts";

const { userUpdateSettings } = userActions;

const {
  dashboardTopStats,
  dashboardOperators,
  dashboardListOperators,
  dashboardOperatorsTopPerformers,
  dashboardOperatorsRatio,
  dashboardExportOperators,
  dashboardOperatorWordCloud,
  operatorRatingUpdate,
} = dashboardActions;

const ratingMap = {
  1 : { emoji: terrible, text: "Terrible" },
  2 : { emoji: bad, text: "Bad" },
  3 : { emoji: ok, text: "OK" },
  4 : { emoji: great, text: "Great" },
  5 : { emoji: amazing, text: "Amazing" }
}

class Dashboard extends Component {
  state = {
    selectedInterval: {
      value: `${moment().startOf('month').format("YYYYMMDD")}-${moment().format("YYYYMMDD")}`,
      label: <Translate>This Month</Translate>
    },
    showIntervalDropdown: false,
    range: {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      key: 'selection'
    },
    ratings_table_state: {
      page: 1,
      sizePerPage: 10,
      loading: false,
      sortOrder: 'desc',
      sortField: 'id',
    },
    user_id: null,
    hourlyFilterActive: false,
    hours_range: null,
    activeTabId: "1",
    ratingFilter: null,
    word_cloud_sentiment: 'all'
  }

  toggleIntervalDropdown = () => {
    this.setState({
      showIntervalDropdown: !this.state.showIntervalDropdown
    })
  }

  customRangesChange = (ranges) => {
    this.setState({
      range: ranges['selection'],
    })
  }

  selectInterval = (selected) => {
    this.setState({ selectedInterval: selected })
    this.searchData({
      interval: selected.value,
    })
    this.props.dashboardOperatorWordCloud({
      interval: selected.value,
      all: true,
      website_id: this.props.userData.website_id,
      sentiment: this.props.userData?.settings?.dashboard_settings?.word_cloud_sentiment,
    })
  }

  applyRange = () => {
    const { range } = this.state;

    const start = moment(range.startDate).format("YYYYMMDD");
    const end = moment(range.endDate).format("YYYYMMDD");
    this.selectInterval({value: `${start}-${end}`, label: <Translate>Custom</Translate>});
  }

  componentDidMount = () => {
    const { userData } = this.props;

    const today = moment().format("YYYYMMDD");
    const start_month = moment().startOf('month').format("YYYYMMDD");
    const interval = `${start_month}-${today}`;

    this.props.dashboardTopStats({interval: interval});
    this.props.dashboardListOperators({
      interval: interval,
      hide_non_rated: userData?.settings?.dashboard_settings?.hide_non_rated,
      hide_non_commented: userData?.settings?.dashboard_settings?.hide_non_commented,
    });
    this.props.dashboardOperators({filter_option: "active"})
    this.props.dashboardOperatorsTopPerformers({
      interval: interval,
      count: 3,
    })
    this.props.dashboardOperatorsRatio({interval: interval})
    this.props.dashboardOperatorWordCloud({
      interval: interval,
      all: true,
      website_id: this.props.userData.website_id,
      sentiment: userData?.settings?.dashboard_settings?.word_cloud_sentiment,
    })
  }

  createOperatorDropdown = () => {
    const { operators_data } = this.props;
    var data = [
      {value: null, label: <Translate>All reps</Translate>}
    ]
    if(operators_data){
      operators_data.forEach(item => {
        data.push({
          value: item.user_id,
          label: item.first_name,
        })
      })
    }
    return data
  }

  searchData = (data) => {
    const {
      ratings_table_state,
      user_id,
      selectedInterval,
      hours_range,
      ratingFilter,
    } = this.state;

    const { userData } = this.props;

    this.props.dashboardTopStats({
      user_id: data.hasOwnProperty("user_id") ? data.user_id : user_id,
      interval: data.interval ? data.interval : selectedInterval.value
    });

    this.props.dashboardListOperators({
      user_id: data.hasOwnProperty("user_id") ? data.user_id : user_id,
      count: userData?.settings?.dashboard_settings?.size_per_page
        ? userData?.settings?.dashboard_settings?.size_per_page
        : ratings_table_state.sizePerPage,
      page: ratings_table_state.page,
      sort_field: ratings_table_state.sortField,
      sort: ratings_table_state.sortOrder,
      interval: data.interval ? data.interval : selectedInterval.value,
      hide_non_rated: Object.keys(data).includes("hide_non_rated")
        ? data.hide_non_rated ? "yes" : null
        : userData?.settings?.dashboard_settings?.hide_non_rated
          ? "yes"
          : null,
      hide_non_commented: Object.keys(data).includes("hide_non_commented")
        ? data.hide_non_commented ? "yes" : null
        : userData?.settings?.dashboard_settings?.hide_non_commented
          ? "yes"
          : null,
      hour_interval: hours_range
        ? hours_range[0] && hours_range[1]
          ? `${hours_range[0]}-${hours_range[1]}`
          : null
        : null,
      rating: Object.keys(data).includes("rating")
        ? data.rating
        : ratingFilter
          ? ratingFilter
          : null,
    });

    this.props.dashboardOperatorsTopPerformers({
      interval: data.interval ? data.interval : selectedInterval.value,
      count: 3,
    })

    this.props.dashboardOperatorsRatio({
      interval: data.interval ? data.interval : selectedInterval.value,
    })

    this.props.dashboardOperatorWordCloud({
      interval: data.interval ? data.interval : selectedInterval.value,
      all: data.hasOwnProperty("user_id")
        ? null
        : user_id
          ? null
          : true,
      user_id: data.hasOwnProperty("user_id") ? data.user_id : user_id,
      website_id: this.props.userData.website_id,
      rating: Object.keys(data).includes("rating")
        ? data.rating
        : ratingFilter
          ? ratingFilter
          : null,
      sentiment: userData?.settings?.dashboard_settings?.word_cloud_sentiment,
    })
  }

  hideNonRated = () => {
    const { userData } = this.props;
    const hide_non_rated = userData?.settings?.dashboard_settings?.hide_non_rated;
    const data = {
      "dashboard_settings": {
        hide_non_rated: !hide_non_rated
      }
    }
    this.props.userUpdateSettings(data);
    this.searchData({hide_non_rated: !hide_non_rated})
  }

  hideNonCommented = () => {
    const { userData } = this.props;
    const hide_non_commented = userData?.settings?.dashboard_settings?.hide_non_commented;
    const data = {
      "dashboard_settings": {
        hide_non_commented: !hide_non_commented
      }
    }
    this.props.userUpdateSettings(data);
    this.searchData({hide_non_commented: !hide_non_commented})
  }

  hideNonRecommended = () => {
    const { userData } = this.props;
    const hide_non_recommended = userData?.settings?.dashboard_settings?.hide_non_recommended;
    const data = {
      "dashboard_settings": {
        hide_non_recommended: !hide_non_recommended
      }
    }
    this.props.userUpdateSettings(data);
    this.searchData({hide_non_recommended: !hide_non_recommended})
  }

  selectOperator = (selected) => {
    this.setState({ user_id: selected.value })
    this.searchData({
      user_id: selected.value,
    });
  }

  setRatingTableState = (state) => {
    var old_state = this.state.ratings_table_state;
    this.setState({ ratings_table_state: {...old_state, ...state}})
  }

  createTimeDropdown = () => {
    const today = moment().format("YYYYMMDD");
    const start_month = moment().startOf('month').format("YYYYMMDD");
    const start_year = moment().startOf('year').format("YYYYMMDD");
    const start_quarter = moment().startOf('quarter').format("YYYYMMDD");
    const end_quarter = moment().endOf('quarter').format("YYYYMMDD");

    const options = [
      {value: `${start_month}-${today}`, label: <Translate>This Month</Translate>},
      {value: `${start_quarter}-${end_quarter}`, label: <Translate>This Quarter</Translate>},
      {value: `${start_year}-${today}`, label: <Translate>This Year</Translate>},
    ]
    return options;
  }

  updateRatingTableSettings = (update_data) => {
    const data = {
      "dashboard_settings": update_data,
    }
    this.props.userUpdateSettings(data);
  }

  dashboardExportOperators = () => {
    const {
      selectedInterval,
      ratingFilter,
      user_id,
    } = this.state;

    const { userData } = this.props;

    this.props.dashboardExportOperators({
      interval: selectedInterval.value,
      hide_non_rated: userData?.settings?.dashboard_settings?.hide_non_rated,
      hide_non_commented: userData?.settings?.dashboard_settings?.hide_non_commented,
      rating: ratingFilter ? ratingFilter : 0,
      user: user_id ? user_id : null,
    });
  }

  generateDataWordCloudList = () => {
    const { operators_data_word_cloud } = this.props;
    var data = {
      data: [],
      categories: [],
    }
    if(!operators_data_word_cloud){ return data; }

    var sorted = operators_data_word_cloud.sort((a,b) => b.value - a.value).slice(0, 20);
    data.data = sorted.map(item => item.value);
    data.categories = sorted.map(item => item.text);
    return data;
  }

  toggleFilter = (rating) => {
    const { activeFilter } = this.state;
    this.setState({ ratingFilter: (rating === activeFilter || rating === null) ? null : rating })
    this.searchData({ rating: (rating === activeFilter || rating === null) ? null : rating });
  }

  selectSentiment = (sentiment) => {
    const { selectedInterval, user_id, ratingFilter } = this.state;
    this.props.userUpdateSettings({
      "dashboard_settings": {
        word_cloud_sentiment: sentiment
      }
    });
    this.props.dashboardOperatorWordCloud({
      interval: selectedInterval.value,
      all: user_id ? null : true,
      user_id: user_id,
      website_id: this.props.userData.website_id,
      rating: ratingFilter ? ratingFilter : null,
      sentiment: sentiment,
    })
  }

  render() {
    const {
      dashboard_top_data,
      dashboard_top_data_loading,
      operators_data_top_performers,
      operators_data_ratio,
      user_data_saving,
      userData,
      operators_data_word_cloud,
    } = this.props;

    return (
      <div className="main-content view-dashboard">
        <div className="page-header">
          <h2 className="page-title"><Translate>Dashboard</Translate> <span className="title-info"><Translate>data as of</Translate>: {moment().format("MMMM Do, YYYY")} </span></h2>
          <div className="controls">
            <div style={{ minWidth: "10rem" }}>
              <Select
                options={this.createOperatorDropdown()}
                defaultValue={{value: null, label: <Translate>All reps</Translate>}}
                isClearable={false}
                onChange={(e) => this.selectOperator(e)}
              />
            </div>
            <DateIntervalDropdown
              isOpen={this.state.showIntervalDropdown}
              toggle={this.toggleIntervalDropdown}
              selected={this.state.selectedInterval}
              options={this.createTimeDropdown()}
              onSelect={this.selectInterval}
              showCustom
              customRanges={[this.state.range]}
              customRangesChange={this.customRangesChange}
              applyRange={this.applyRange}
              rightDropdown
            />
            <div className="hstack gap-1">
              <FormGroup check className="mb-0 me-1">
                <Input
                  type="checkbox"
                  id="hourlyFilter"
                  value={this.state.hourlyFilterActive}
                  onChange={() => this.setState({
                    hourlyFilterActive: !this.state.hourlyFilterActive,
                    hours_range: null
                  })}
                />
                <Label check for="hourlyFilter" className="position-relative">
                  <Translate>Set hours</Translate>
                  <span className="icon icon-info" id="hours-popover" />
                </Label>
                <UncontrolledPopover
                  target="hours-popover"
                  placement="top"
                  trigger="hover"
                >
                  <PopoverBody>
                    <Translate
                      special_data="dashboard_hours_info"
                    >
                      Narrow your search by defining certain hours to search surveys from
                    </Translate>
                  </PopoverBody>
                </UncontrolledPopover>
              </FormGroup>
              <TimeRangePicker
                format="h a"
                hourPlaceholder="H"
                disableClock={true}
                disabled={!this.state.hourlyFilterActive}
                value={this.state.hours_range}
                onChange={(value) => this.setState({ hours_range: value })}
              />
              <Button
                size="sm"
                disabled={!this.state.hourlyFilterActive || this.props.operators_data_loading}
                onClick={() => this.searchData({})}
              ><Translate>Go</Translate></Button>
            </div>
          </div>
        </div>

        <div className="page-content">
          <Row>
            <Col lg={5}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center" style={{ minHeight: '3.9rem' }}>
                    <div className="metric-simple">
                      <span className="value">{operators_data_ratio?.operators_count}</span>
                      <h6 className="title"><Translate>Number of Reps</Translate></h6>
                    </div>
                    <div className="metric-simple">
                      <span className="value">{operators_data_ratio?.comments_ratio}%</span>
                      <h6 className="title"><Translate>Comment Ratio</Translate></h6>
                    </div>
                    <div className="metric-simple">
                      <span className="value">{operators_data_ratio?.rating_ratio}%</span>
                      <h6 className="title"><Translate>Rating Ratio</Translate></h6>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <h3 className="page-section-title"><Translate>Top Performing Reps</Translate> <span className="title-info"><Translate>based on time filter</Translate></span></h3>
              <div className="top-ranks">
              {operators_data_top_performers &&
                operators_data_top_performers.map((item, idx) => {
                  if(idx === 0){
                    return (
                      <Card
                        className="card-top-operator first"
                        key={`card-top-operator-${idx}`}
                      >
                        <CardBody>
                          <span className="trophy"><img src={gold} alt="Gold" /></span>
                          <div className="profile-operator">
                            <span
                              className="avatar"
                              style={{ backgroundImage: `url(${item?.avatar})` }}
                            >
                            </span>
                            <div className="text">
                              <span className="name">{item?.first_name}</span>
                            </div>
                          </div>
                          <div className="achievement">
                            <div className="achievement-item">
                              <img src={amazing} alt="Amazing" />
                              <span className="percentage">{item?.stats["5_avg"]}%</span>
                              <span className="survey-number">({item?.stats['5'] ? item?.stats['5'] : 0})</span>
                            </div>
                            <div className="achievement-item">
                              <img src={great} alt="Great" />
                              <span className="percentage">{item?.stats["4_avg"]}%</span>
                              <span className="survey-number">({item?.stats['4'] ? item?.stats['4'] : 0})</span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    )
                  } else {
                    return (
                      <Card
                        className="card-top-operator"
                        key={`card-top-operator-${idx}`}
                      >
                        <CardBody>
                          <span className="trophy">
                            {idx < 3 &&
                              <img src={idx === 1 ? silver : bronze } alt={idx === 1 ? "Silver" : "Bronze"} />
                            }
                          </span>
                          <div className="profile-operator">
                            <span
                              className="avatar"
                              style={{ backgroundImage: `url(${item?.avatar})` }}
                            >
                            </span>
                            <div className="text">
                              <span className="name">{item?.first_name}</span>
                            </div>
                          </div>
                          <div className="achievement">
                            <div className="achievement-item">
                              <img src={amazing} alt="Amazing" />
                              <span className="percentage">{item?.stats["5_avg"]}%</span>
                              <span className="survey-number">({item?.stats['5'] ? item?.stats['5'] : 0})</span>
                            </div>
                            <div className="achievement-item">
                              <img src={great} alt="Great" />
                              <span className="percentage">{item?.stats["4_avg"]}%</span>
                              <span className="survey-number">({item?.stats['4'] ? item?.stats['4'] : 0})</span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    )
                  }
              })}
              {operators_data_top_performers.length < 2 &&
              <Card className="card-top-operator info">
                <CardBody>
                  <span className="icon icon-24 icon-info bg-secondary" style={{ flex: '0 0 auto'}} />
                  <span>
                    <Translate
                      special_data="dashboard_operator_info"
                    >
                      Your company has more than one support rep, however only one has been rated thus far.
                      Let's give it some time for more ratings to come through in this case.
                    </Translate>
                  </span>
                </CardBody>
              </Card>
              }
              </div>

              <div className="hstack justify-content-between flex-wrap">
                <h3 className="page-section-title mb-0"><Translate>Sentiment</Translate></h3>
                <Nav tabs className="card-tabs">
                  <Dropdown
                    nav
                    isOpen={this.state.cloudDropdownOpen}
                    toggle={() => this.setState({ cloudDropdownOpen: !this.state.cloudDropdownOpen })}
                  >
                    <DropdownToggle nav caret className={this.state.activeTabId === '1' ? 'active' : ''}>
                      <span className="text-capitalize">{userData?.settings?.dashboard_settings?.word_cloud_sentiment ? userData?.settings?.dashboard_settings?.word_cloud_sentiment : "All"}</span> sentiment
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        active={!userData?.settings?.dashboard_settings?.word_cloud_sentiment}
                        onClick={() => this.selectSentiment("")}
                      >All sentiment</DropdownItem>
                      <DropdownItem
                        active={userData?.settings?.dashboard_settings?.word_cloud_sentiment === "positive"}
                        onClick={() => this.selectSentiment("positive")}
                      >Positive sentiment</DropdownItem>
                      <DropdownItem
                        active={userData?.settings?.dashboard_settings?.word_cloud_sentiment === "negative"}
                        onClick={() => this.selectSentiment("negative")}
                      >Negative sentiment</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <NavItem>
                    <NavLinkTab
                      active={this.state.activeTabId === "2"}
                      onClick={() => this.setState({ activeTabId: "2" })}
                    >Top 20   Words</NavLinkTab>
                  </NavItem>
                </Nav>
              </div>
              <Card className="card-has-tab">
                <TabContent activeTab={this.state.activeTabId}>
                  <TabPane tabId="1">
                    {this.state.activeTabId === "1" &&
                      <CardBody>
                        <p>What customers are saying about your support.</p>
                        <div className="word-cloud">
                          <ReactWordcloud
                            words={operators_data_word_cloud}
                            options={{
                              padding: 8,
                              rotations: 0,
                              rotationAngles: [0, 0],
                              deterministic: true,
                              fontFamily: "arial",
                              fontWeight: "bold",
                              fontSizes: [16, 60],
                              scale: "linear",
                              enableTooltip: false,
                            }}
                          />
                        </div>
                      </CardBody>
                    }
                  </TabPane>
                  <TabPane tabId="2">
                    {this.state.activeTabId === "2" &&
                    <CardBody>
                      <ReactApexChart
                        type="bar"
                        height={400}
                        series={[{
                          name: "Volume",
                          color: "#c0d5f1",
                          data: this.generateDataWordCloudList()['data'],
                        }]}
                        options={{
                          chart: {
                            type: "bar",
                            zoom: {
                              enabled: false
                            },
                            toolbar: {
                              show: false
                            },
                          },
                          grid: {
                            yaxis: {
                              lines: {
                                show: false
                               }
                             },
                          },
                          plotOptions: {
                            bar: {
                              horizontal: true,
                              borderRadius: 3,
                              dataLabels: {
                                position: 'top'
                              }
                            }
                          },
                          dataLabels: {
                            enabled: true,
                            offsetX: -6,
                            offsetY: 1,
                            style: {
                              fontSize: '10px',
                              colors: ['#2f80ed']
                            }
                          },
                          xaxis: {
                            categories: this.generateDataWordCloudList()['categories'],
                          },
                        }}
                      />
                    </CardBody>
                    }
                  </TabPane>
                </TabContent>
              </Card>
            </Col>
            <Col lg={7}>
              <Card>
                <CardBody>
                  {dashboard_top_data_loading &&
                    <div className="loading-indicator">
                      <Spinner color="secondary" /> <span><Translate>Loading...</Translate></span>
                    </div>
                  }
                  <div className={`metric-rating-group ${this.state.ratingFilter !== null ? "filter-active" : ""}`}>
                    {!dashboard_top_data_loading
                        && dashboard_top_data
                        && dashboard_top_data.map((item, idx) =>
                          <div
                            className={`metric-rating ${this.state.ratingFilter === item.rating ? 'active' : ''}`}
                            key={`metric-rating-list-${idx}`}
                          >
                            <span
                              className="icon icon-8 bg-secondary icon-close metric-rating-clear-filter"
                              role="button"
                              onClick={() => this.toggleFilter(null)}
                            ></span>
                            <img
                              src={ratingMap[item.rating].emoji}
                              alt={ratingMap[item.rating].text}
                              title={ratingMap[item.rating].text}
                              role="button"
                              onClick={() => this.toggleFilter(item.rating)}
                            />
                            <span className="percentage">{item.percentage}%</span>
                            <span className="number">({item.count})</span>
                          </div>
                    )}
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <RatingsTable
                    dashboardListOperators={this.props.dashboardListOperators}
                    setRatingTableState={this.setRatingTableState}
                    sizePerPage={
                      userData?.settings?.dashboard_settings?.size_per_page
                        ? userData?.settings?.dashboard_settings?.size_per_page
                        : this.state.ratings_table_state.sizePerPage
                    }
                    operators={this.props.operators}
                    operators_loading={this.props.operators_loading}
                    sortField={this.state.ratings_table_state.sortField}
                    sortOrder={this.state.ratings_table_state.sortOrder}
                    selectedInterval={this.state.selectedInterval}
                    user_id={this.state.user_id}
                    hideNonRated={this.hideNonRated}
                    hideNonCommented={this.hideNonCommented}
                    hideNonRecommended={this.hideNonRecommended}
                    hide_non_rated={
                      userData?.settings?.dashboard_settings?.hide_non_rated
                        ? userData?.settings?.dashboard_settings?.hide_non_rated
                        : false
                    }
                    hide_non_commented={
                      userData?.settings?.dashboard_settings?.hide_non_commented
                        ? userData?.settings?.dashboard_settings?.hide_non_commented
                        : false
                    }
                    hide_non_recommended={
                      userData?.settings?.dashboard_settings?.hide_non_recommended
                        ? userData?.settings?.dashboard_settings?.hide_non_recommended
                        : false
                    }
                    userData={userData}
                    updateRatingTableSettings={this.updateRatingTableSettings}
                    dashboardExportOperators={this.dashboardExportOperators}
                    user_data_saving={user_data_saving}
                    operatorRatingUpdate={this.props.operatorRatingUpdate}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    dashboard_top_data: state.Dashboard.get("dashboard_top_data"),
    dashboard_top_data_loading: state.Dashboard.get("dashboard_top_data_loading"),
    operators_data: state.Dashboard.get("operators_data"),
    operators_data_loading: state.Dashboard.get("operators_data_loading"),
    operators: state.Dashboard.get("operators"),
    operators_loading: state.Dashboard.get("operators_loading"),
    operators_data_top_performers: state.Dashboard.get("operators_data_top_performers"),
    operators_data_top_performers_loading: state.Dashboard.get("operators_data_top_performers_loading"),
    operators_data_ratio: state.Dashboard.get("operators_data_ratio"),
    operators_data_ratio_loading: state.Dashboard.get("operators_data_ratio_loading"),
    userData: state.User.get("userData"),
    user_data_saving: state.User.get("user_data_saving"),
    operators_data_word_cloud: state.Dashboard.get("operators_data_word_cloud"),
  }),
  {
    dashboardTopStats,
    dashboardOperators,
    dashboardListOperators,
    dashboardOperatorsTopPerformers,
    dashboardOperatorsRatio,
    userUpdateSettings,
    dashboardExportOperators,
    dashboardOperatorWordCloud,
    operatorRatingUpdate,
  }
)(Dashboard);
