import React, { Component } from 'react';
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Users from './Users';
import './style.css';
import Transparency from './Transparency';
import adminActions from "../../redux/admin/actions";
import AnnouncementAdmin from "./AnnouncementAdmin";

const { adminWebsiteCount } = adminActions;

class Admin extends Component {
  state = {
    activeTab: "1"
  }

  toggleTab = (id) => {
    this.setState({ activeTab: id })
  }

  componentDidMount = () => {
    this.props.adminWebsiteCount();
  }

  render() {
    const { website_count } = this.props;

    return (
      <div className="main-content view-admin">
        <h2 className="page-title">Rating Fish Admin {website_count ? `(active users ${website_count})` : null}</h2>
        <div className="page-content">
          <Card className="card-tabs">
            <CardHeader>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    active={this.state.activeTab === "1"}
                    onClick={() => this.toggleTab("1")}
                  >Users</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={this.state.activeTab === "2"}
                    onClick={() => this.toggleTab("2")}
                  >Transparency</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={this.state.activeTab === "3"}
                    onClick={() => this.toggleTab("3")}
                  >Announcements</NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  {this.state.activeTab === '1' &&
                    <Users />
                  }
                </TabPane>
                <TabPane tabId="2">
                  {this.state.activeTab === '2' &&
                    <Transparency />
                  }
                </TabPane>
                <TabPane tabId="3">
                  {this.state.activeTab === '3' &&
                    <AnnouncementAdmin />
                  }
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    website_count: state.Admin.get("website_count"),
  }),
  {
    adminWebsiteCount,
  }
)(Admin);
