const dashboardActions = {
  DASHBOARD_TOP_STATS: 'DASHBOARD_TOP_STATS',
  DASHBOARD_LIST_OPERATORS: 'DASHBOARD_LIST_OPERATORS',
  DASHBOARD_OPERATORS: 'DASHBOARD_OPERATORS',
  DASHBOARD_OPERATORS_TOP_PERFORMERS: 'DASHBOARD_OPERATORS_TOP_PERFORMERS',
  DASHBOARD_OPERATORS_RATIO: 'DASHBOARD_OPERATORS_RATIO',
  DASHBOARD_OPERATORS_EXCLUDE: 'DASHBOARD_OPERATORS_EXCLUDE',
  DASHBOARD_OPERATORS_EXCLUDE_SUCCESS: 'DASHBOARD_OPERATORS_EXCLUDE_SUCCESS',
  DASHBOARD_OPERATORS_EXCLUDE_FAILED: 'DASHBOARD_OPERATORS_EXCLUDE_FAILED',
  DASHBOARD_OPERATOR_REVIEW: 'DASHBOARD_OPERATOR_REVIEW',
  DASHBOARD_OPERATOR_WORD_CLOUD: 'DASHBOARD_OPERATOR_WORD_CLOUD',
  DASHBOARD_OPERATOR_REVIEW_UPDATE: 'DASHBOARD_OPERATOR_REVIEW_UPDATE',
  DASHBOARD_EXPORT_OPERATORS: 'DASHBOARD_EXPORT_OPERATORS',
  OPERATOR_REVIEW_GENERATE: 'OPERATOR_REVIEW_GENERATE',
  OPERATOR_REVIEW_SEND_EMAIL: 'OPERATOR_REVIEW_SEND_EMAIL',
  OPERATOR_REVIEW_SCHEDULED_GET_ALL: 'OPERATOR_REVIEW_SCHEDULED_GET_ALL',
  OPERATOR_REVIEW_SCHEDULED_CREATE: 'OPERATOR_REVIEW_SCHEDULED_CREATE',
  OPERATOR_REVIEW_SCHEDULED_DELETE: 'OPERATOR_REVIEW_SCHEDULED_DELETE',
  OPERATOR_REVIEW_SCHEDULED_UPDATE: 'OPERATOR_REVIEW_SCHEDULED_UPDATE',
  WEBHOOKS_GET: "WEBHOOKS_GET",
  WEBHOOK_CREATE: "WEBHOOK_CREATE",
  WEBHOOK_DELETE: "WEBHOOK_DELETE",
  OPERATOR_RATING_UPDATE: 'OPERATOR_RATING_UPDATE',
  OPERATOR_RATING_EXTERNAL_GET: 'OPERATOR_RATING_EXTERNAL_GET',
  OPERATOR_RATING_EXTERNAL_UPDATE: 'OPERATOR_RATING_EXTERNAL_UPDATE',

  operatorRatingExternalUpdate: (data) => ({
    type: dashboardActions.OPERATOR_RATING_EXTERNAL_UPDATE,
    data
  }),

  operatorRatingExternalGet: (data) => ({
    type: dashboardActions.OPERATOR_RATING_EXTERNAL_GET,
    data
  }),

  operatorRatingUpdate: (data) => ({
    type: dashboardActions.OPERATOR_RATING_UPDATE,
    data
  }),

  webhookDelete: (data) => ({
    type: dashboardActions.WEBHOOK_DELETE,
    data
  }),

  webhookCreate: (data) => ({
    type: dashboardActions.WEBHOOK_CREATE,
    data
  }),

  webhooksGet: (data) => ({
    type: dashboardActions.WEBHOOKS_GET,
    data
  }),

  operatorReviewScheduledUpdate: (data) => ({
    type: dashboardActions.OPERATOR_REVIEW_SCHEDULED_UPDATE,
    data
  }),

  operatorReviewScheduledDelete: (data) => ({
    type: dashboardActions.OPERATOR_REVIEW_SCHEDULED_DELETE,
    data
  }),

  operatorReviewScheduledCreate: (data) => ({
    type: dashboardActions.OPERATOR_REVIEW_SCHEDULED_CREATE,
    data
  }),

  operatorReviewScheduledGetAll: (data) => ({
    type: dashboardActions.OPERATOR_REVIEW_SCHEDULED_GET_ALL,
    data
  }),

  operatorReviewSendEmail: (data) => ({
    type: dashboardActions.OPERATOR_REVIEW_SEND_EMAIL,
    data
  }),

  operatorReviewGenerate: (data) => ({
    type: dashboardActions.OPERATOR_REVIEW_GENERATE,
    data
  }),

  dashboardExportOperators: (data) => ({
    type: dashboardActions.DASHBOARD_EXPORT_OPERATORS,
    data
  }),

  dashboardOperatorReviewUpdate: (data) => ({
    type: dashboardActions.DASHBOARD_OPERATOR_REVIEW_UPDATE,
    data
  }),

  dashboardOperatorWordCloud: (data) => ({
    type: dashboardActions.DASHBOARD_OPERATOR_WORD_CLOUD,
    data
  }),

  dashboardOperatorReview: (data) => ({
    type: dashboardActions.DASHBOARD_OPERATOR_REVIEW,
    data
  }),

  dashboardOperatorsExclude: (data) => ({
    type: dashboardActions.DASHBOARD_OPERATORS_EXCLUDE,
    data
  }),
  dashboardOperatorsExcludeSuccess: (data) => ({
    type: dashboardActions.DASHBOARD_OPERATORS_EXCLUDE_SUCCESS,
    data
  }),
  dashboardOperatorsExcludeError: (data) => ({
    type: dashboardActions.DASHBOARD_OPERATORS_EXCLUDE_FAILED,
    data
  }),

  dashboardOperatorsRatio: (data) => ({
    type: dashboardActions.DASHBOARD_OPERATORS_RATIO,
    data
  }),

  dashboardOperatorsTopPerformers: (data) => ({
    type: dashboardActions.DASHBOARD_OPERATORS_TOP_PERFORMERS,
    data
  }),

  dashboardOperators: (data) => ({
    type: dashboardActions.DASHBOARD_OPERATORS,
    data
  }),

  dashboardListOperators: (data) => ({
    type: dashboardActions.DASHBOARD_LIST_OPERATORS,
    data
  }),

  dashboardTopStats: (data) => ({
    type: dashboardActions.DASHBOARD_TOP_STATS,
    data
  }),
}

export default dashboardActions;
