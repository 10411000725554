import React, { Component } from 'react';
import { Button, PopoverBody, UncontrolledPopover, Spinner, Badge, ButtonGroup, UncontrolledTooltip, Table } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  amazing,
  bad,
  great,
  ok,
  terrible,
  //iconSlack
} from '../../assets/images';
import './style.css';
import { momentDateToISOFormatConversion } from "../../helpers/utils";
import { translate_text } from "../../helpers/translation/utils";
import Translate from "../../shared/components/Translate";
import { FlagIcon } from 'react-flag-kit';
import { getCode } from "country-list";

const ratingMap = {
  0 : { emoji: null, text: "" },
  1 : { emoji: terrible, text: "Terrible" },
  2 : { emoji: bad, text: "Bad" },
  3 : { emoji: ok, text: "OK" },
  4 : { emoji: great, text: "Great" },
  5 : { emoji: amazing, text: "Amazing" }
}

class RatingsTable extends Component {
  state = {
    expanded: []
  }

  displayReccomendationRating = (cell) => {
    var data = null;
    if(!cell){ return null }
    let color = "";
    if(cell > 9) {
      color = "success";
      cell = 10;
    } else if( cell >= 6 && cell <= 9) {
      color = "warning";
      cell = 7;
    } else if(cell <= 5 ) {
      color = "danger";
      cell = 5;
    }
    if(color){
      data = <Badge color={color} className="badge-nps">{cell}</Badge>
    }
    return data;
  }

  generateColumns = () => {
    const { userData, operators_loading } = this.props;

    const columns = [
      {
        dataField: 'user_id',
        text: translate_text("Agent", userData?.settings?.language),
        sort: true,
        classes: (cell, row, rowIndex, colIndex) => {
          if (this.state.expanded.includes(row.id)) return 'col-expanded';
          return '';
        },
        formatter: (cell, row) => (
          <div className="profile-operator">
            <div className="text">
              <span className="name">{row.operator_name}</span>
            </div>
            {row.multiple_ratings &&
            <span
              className="expand-indicator"
              onClick={() => this.handleBtnExpandClick(row) }
            >
              {this.state.expanded.includes(row.id) ? <span className="icon icon-minus" /> : <span className="icon icon-plus" />}
            </span>
            }
          </div>
        )
      },
      {
        dataField: 'created_at',
        text: translate_text("Date/time", userData?.settings?.language),
        sort: true,
        formatter: (cell, row) => (
          momentDateToISOFormatConversion(cell, "MM/DD/YY hh:mma", true)
        )
      },
      {
        dataField: 'customer_name',
        text: translate_text("Customer", userData?.settings?.language),
        sort: true,
        formatter: (cell, row) => (
          <div className="profile-customer">
            {row.customer_country &&
            <FlagIcon
              size={24}
              code={userData?.website?.intercom_token
                ? getCode(row.customer_country)
                : row.customer_country
              }
              className="country-img"
            />
            }
            <span className="name text-truncate" style={{ width: '6rem' }} title={cell}>{cell}</span>
          </div>
        )
      },
      {
        dataField: 'reccomendation_rating',
        text: translate_text("NPS", userData?.settings?.language),
        sort: true,
        headerClasses: 'text-center',
        classes: 'text-center',
        formatter: (cell, row) => (
          <div>{this.displayReccomendationRating(cell)}</div>
        )
      },
      {
        dataField: 'rating',
        text: translate_text("Rating", userData?.settings?.language),
        sort: true,
        headerClasses: 'text-center',
        classes: 'text-center',
        formatter: (cell, row) => (
          <div className="rating-emoji">
            {cell ?
            <img
              src={ratingMap[cell].emoji}
              alt={ratingMap[cell].text}
              title={ratingMap[cell].text}
            />
            :
            <span className="no-rating">
              <Button
                color="link"
                onClick={() => this.props.operatorRatingUpdate({
                  website_id: row.website_id,
                  session_id: row.session_id,
                  rating_id: row.id,
                })}
                disabled={operators_loading}
              >
                <span
                  className={`icon icon-user-refresh bg-primary ${operators_loading ? "animate-spin" : ""}`}
                />
              </Button>
            </span>
            }
            {row.segmentation &&
            <React.Fragment>
              <span className="segment-dot" id={`segment-dot-${row.id}`}></span>
                <UncontrolledPopover
                  target={`segment-dot-${row.id}`}
                  placement="top"
                  trigger="hover"
                >
                  <PopoverBody>
                    {row.segmentation}
                  </PopoverBody>
                </UncontrolledPopover>
              </React.Fragment>
            }
          </div>
        )
      },
      {
        dataField: 'comment',
        text: translate_text("Comment", userData?.settings?.language),
        sort: false,
        headerClasses: 'text-center',
        classes: 'text-center',
        formatter: (cell, row) => (
          cell ?
          <div>
            <Button className="text-truncate" color="link" size="sm" id={`popover-${row.id}`}><Translate>{cell}</Translate></Button>
            <UncontrolledPopover
              target={`popover-${row.id}`}
              placement="top"
              trigger="hover"
            >
              <PopoverBody>
                {cell}
              </PopoverBody>
            </UncontrolledPopover>
          </div>
          : <small className="text-muted">N/A</small>
        )
      },
      {
        dataField: 'link',
        text: translate_text("Chat", userData?.settings?.language),
        sort: false,
        classes: 'text-center',
        formatter: (cell, row) => (
          <a
            href={`https://app.crisp.chat/website/${row.website_id}/inbox/${row.session_id}/`}
            target="_blank"
            rel="noreferrer"
          ><span className="icon icon-16 icon-link-external bg-primary" title="Read chat" /></a>
        )
      },
    ]
    return columns;
  }

  handleTableChange = (type, newState) => {
    this.props.setRatingTableState({
      sizePerPage: newState.sizePerPage,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
      page: newState.page,
    });

    this.props.updateRatingTableSettings({
      size_per_page: newState.sizePerPage,
    });

    this.props.dashboardListOperators({
      count: newState.sizePerPage,
      page: newState.page,
      sort_field: newState.sortField,
      sort: newState.sortOrder,
      interval: this.props.selectedInterval.value,
      user_id: this.props.user_id,
    });

  }

  handleBtnExpandClick = (row) => {
    if (!this.state.expanded.includes(2)) {
      this.setState(() => ({
        expanded: [...this.state.expanded, row.id]
      }));
    } else {
      this.setState(() => ({
        expanded: this.state.expanded.filter(x => x !== row.id)
      }));
    }
  }

  handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      this.setState(() => ({
        expanded: [...this.state.expanded, row.id]
      }));
    } else {
      this.setState(() => ({
        expanded: this.state.expanded.filter(x => x !== row.id)
      }));
    }
  }

  expandRowAction = () => {
    const expandRow = {
      className: "child-row",
      renderer: row => (
        row.multiple_ratings_data ?
        <Table>
          <tbody>
            {row.multiple_ratings_data.map((item, idx) => {
              return (
                <tr key={`rating_child_${row.id}_${idx}`}>
                  <td>
                    <div className="profile-operator">
                      <div className="text">
                        <span className="name">{row.operator_name}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    {momentDateToISOFormatConversion(item.created_at, "MM/DD/YY hh:mma", true)}
                  </td>
                  <td>
                    <div className="profile-customer">
                      {item.customer_country &&
                      <FlagIcon
                        size={24}
                        code={item.customer_country}
                        className="country-img"
                      />
                      }
                      <span className="name text-truncate" style={{ width: '6rem' }} title={item.customer_name}>{item.customer_name}</span>
                    </div>
                  </td>
                  <td className="text-center">
                    {this.displayReccomendationRating(item.reccomendation_rating)}
                  </td>
                  <td className="text-center">
                    <div className="rating-emoji">
                      <img
                        src={ratingMap[item.rating].emoji}
                        alt={ratingMap[item.rating].text}
                        title={ratingMap[item.rating].text}
                      />
                    </div>
                  </td>
                  <td className="text-center">
                    {item.comment ?
                      <div>
                        <Button className="text-truncate" color="link" size="sm" id={`popover-${row.id}-${item.id}-1`}><Translate>{item.comment}</Translate></Button>
                        <UncontrolledPopover
                          target={`popover-${row.id}-${item.id}-1`}
                          placement="top"
                          trigger="hover"
                        >
                          <PopoverBody>
                            {item.comment}
                          </PopoverBody>
                        </UncontrolledPopover>
                      </div>
                      : <small className="text-muted">N/A</small>
                    }
                  </td>
                  <td className="text-center"></td>
                </tr>
              )})}
          </tbody>
        </Table>
        : null
      ),
      expanded: this.state.expanded,
      onExpand: this.handleOnExpand
    };
    return expandRow;
  }

  render() {
    const {
      operators,
      operators_loading,
      sizePerPage,
      hide_non_rated,
      hide_non_commented,
      user_data_saving,
      hide_non_recommended,
      userData,
    } = this.props;

    return (
      <div className="table-wrapper">
      <div className="table-controls mb-4">
        <div className="hstack gap-4">
          <Button
            className="btn btn-sm"
            color="light"
            onClick={() => this.props.dashboardExportOperators()}
          >
            <Translate>Export</Translate> (.csv)
          </Button>
          <div className="survey-top-countries">
            <span>Top countries:</span>
            <div className="flags">
              {userData?.settings?.top_countries_aggregated &&
                userData?.settings?.top_countries_aggregated.map(item => {
                  return (
                    <FlagIcon
                      key={`flag-${item}`}
                      size={28}
                      code={item}
                      className="flag"
                    />
                  )
                })
              }
            </div>
          </div>
        </div>
        <ButtonGroup size="sm">
          <Button
            id="btnNPS"
            color="light"
            active={hide_non_recommended}
            onClick={() => this.props.hideNonRecommended()}
            disabled={user_data_saving}
          >
            NPS
          </Button>
          <Button
            id="btnRating"
            color="light"
            active={hide_non_rated}
            onClick={() => this.props.hideNonRated()}
            disabled={user_data_saving}
          >
            <span className="icon icon-star" />
          </Button>
          <Button
            id="btnComment"
            color="light"
            active={hide_non_commented}
            onClick={() => this.props.hideNonCommented()}
            disabled={user_data_saving}
          >
            <span className="icon icon-chat" />
          </Button>
        </ButtonGroup>
        <UncontrolledTooltip
          target="btnNPS"
        >
          <Translate>Show only with NPS</Translate>
        </UncontrolledTooltip>
        <UncontrolledTooltip
          target="btnRating"
        >
          <Translate>Show only with rating</Translate>
        </UncontrolledTooltip>
        <UncontrolledTooltip
          target="btnComment"
        >
          <Translate>Show only with comment</Translate>
        </UncontrolledTooltip>
      </div>
      {operators_loading &&
      <div className="loading-indicator">
        <Spinner color="secondary" /> <span><Translate>Loading...</Translate></span>
      </div>
      }
      <BootstrapTable
        remote
        keyField="id"
        columns={this.generateColumns()}
        bordered={false}
        classes="table-ratings"
        expandRow={this.expandRowAction()}
        data={operators?.items ? operators.items : []}
        pagination={paginationFactory({
          page: operators?.current_page ? operators?.current_page : 1,
          sizePerPage: sizePerPage,
          totalSize: operators?.total
            ? operators?.total
            : operators?.items
              ? operators?.items.length
              : 0
        })}
        sort={
          {
            dataField: this.props.sortField,
            order: this.props.sortOrder,
          }
        }
        onTableChange={this.handleTableChange}
      />
      </div>
    )
  }
}

export default RatingsTable;
