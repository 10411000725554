import React, { Component } from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import './style.css';
import Translate from "../../shared/components/Translate";

class SidebarNav extends Component {
  render() {
    return (
      <div className="sidebar-nav">
        <Nav vertical>
          <NavItem>
            <NavLink className="nav-link" to="/rating/settings/my_account"><span className="icon icon-user" /> <Translate>My Account</Translate></NavLink>
          </NavItem>
          {/*
          <NavItem>
            <NavLink className="nav-link" to="/rating/my_plan"><BsReceipt className="icon" /> My Plan</NavLink>
          </NavItem>
          */}
          <NavItem>
            <NavLink className="nav-link" to="/rating/settings/integrations"><span className="icon icon-slack" /> <Translate>Slack Integrations</Translate></NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="nav-link" to="/rating/settings/segmentation"><span className="icon icon-segments" /> <Translate>Segmentation</Translate></NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="nav-link" to="/rating/settings/survey"><span className="icon icon-survey" /> <Translate>Survey</Translate></NavLink>
          </NavItem>
          {this.props.userData?.id_user_type === 1 &&
          <NavItem>
            <NavLink className="nav-link" to="/rating/settings/employee_accounts"><span className="icon icon-users" /> <Translate>Employee Accounts</Translate></NavLink>
          </NavItem>
          }
          <NavItem>
            <NavLink className="nav-link" to="/rating/settings/filter_sources"><span className="icon icon-filter" /> <Translate>Filter Sources</Translate></NavLink>
          </NavItem>
          {this.props.userData?.id_user_type === 1 &&
          <NavItem>
            <NavLink className="nav-link" to="/rating/settings/api"><span className="icon icon-api" /><Translate>API Access</Translate></NavLink>
          </NavItem>
          }
          {this.props.userData?.id_user_type === 1 &&
            <NavItem>
              <NavLink className="nav-link" to="/rating/settings/webhooks"><span className="icon icon-webhooks" /><Translate>Webhooks</Translate></NavLink>
            </NavItem>
            }
        </Nav>
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
  }),
  {
  }
)(SidebarNav);
