import React, { Component } from 'react';
import {DropdownToggle, DropdownMenu, DropdownItem, Dropdown, Button } from 'reactstrap';
import { DateRangePicker } from 'react-date-range';
import format from 'date-fns/format';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './style.css';

class DateIntervalDropdown extends Component {
  state = {
    calendarOpen: false
  }

  closeCalendar = (e) => {
    e.stopPropagation();
    this.setState({ calendarOpen: false })
  }

  render() {
    const {
      options,
      selected,
      isOpen,
      toggle,
      onSelect,
      showCustom,
      customRanges,
      customRangesChange,
      applyRange,
      className,
      rightDropdown
    } = this.props;

    return (
      <div className={`date-interval-dropdown ${className ? className : ""}`}>
        <Dropdown isOpen={isOpen} toggle={toggle}>
          <DropdownToggle size="sm" caret>
            {selected.label} 
            {selected.label.props.children === "Custom" && <span> : {format(customRanges[0].startDate, "MM/dd/yyyy")} - {format(customRanges[0].endDate, "MM/dd/yyyy")}</span> }
          </DropdownToggle>
          <DropdownMenu 
            end={rightDropdown ? true : false}
          >
            {options.map((item, idx) => (
              <DropdownItem
                active={item.value === selected.value}
                key={idx} tag="a"
                onClick={(e) => {
                  onSelect({value: item.value, label: item.label})
                  this.closeCalendar(e)
                }}>{item.label}</DropdownItem>
            ))}
            {showCustom &&
              <DropdownItem
                tag="a"
                active={selected.label.props.children === 'Custom'}
                onClick={() => this.setState({ calendarOpen: true })}
                toggle={false}
              >
                Custom
                {(this.state.calendarOpen || selected.label.props.children === 'Custom') &&
                  <div className="mt-2">
                    <DateRangePicker
                      months={2}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      direction="horizontal"
                      showDateDisplay={false}
                      ranges={customRanges}
                      onChange={customRangesChange}
                    />
                    <div className="text-right">
                      <Button size="sm" color="dark" className="me-2"
                        onClick={(e) => {
                          this.closeCalendar(e);
                          toggle();
                        }}
                      >Cancel</Button>
                      <Button size="sm" color="primary"
                        onClick={(e) => {
                          this.closeCalendar(e);
                          applyRange();
                          toggle();
                        }}
                      >Apply</Button>
                    </div>
                  </div>
                }
              </DropdownItem>
            }
          </DropdownMenu>
        </Dropdown>
      </div>
    )
  }
}

export default DateIntervalDropdown;
