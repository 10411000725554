import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Card, CardBody, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import Translate from '../../shared/components/Translate';
import { translate_text } from "../../helpers/translation/utils";
import BootstrapTable from 'react-bootstrap-table-next';
import Select from 'react-select';
//import { EditorState, ContentState } from 'draft-js';
//import { Editor } from 'react-draft-wysiwyg';
import { NavLink } from "react-router-dom";
import ConfirmModal from '../../shared/components/ConfirmModal';
import dashboardActions from "../../redux/dashboard/actions";

const {
  operatorReviewScheduledGetAll,
  operatorReviewScheduledDelete,
  operatorReviewScheduledUpdate,
} = dashboardActions;

const period_options = [
  {value: "last_month", label: 'Last Month'},
  {value: "last_quarter", label: 'Last Quarter'},
]

const deliver_options = [
  {value: 'email', label: 'Email'},
  //{value: 'slack', label: 'Slack'},
]

const repeat_options = [
  {value: 'month', label: 'Monthly'},
  {value: 'qtr', label: 'Quarterly'},
]

class Reviews extends Component {
  state = {
    reviews_loading: false,
    edit_prompt_id: null,
    edit_content_id: null,
    //editorState: EditorState.createEmpty(),
    delete_id: null
  }

  generateColumns = () => {
    const { userData, operator_review_scheduled_loading } = this.props;
    const columns = [
      {
        dataField: 'user_id',
        text: translate_text("Agent", userData?.settings?.language),
        formatter: (cell, row) => (
          <div>{row?.operator_data?.first_name}</div>
        )
      },
      {
        dataField: 'review_period',
        text: translate_text("Review Period", userData?.settings?.language),
        formatter: (cell, row) => (
          <Select
            options={period_options}
            value={period_options.filter(item => item.value === cell)[0]}
            onChange={(value) => this.props.operatorReviewScheduledUpdate({
              id: row.id,
              review_period: value.value,
            })}
            isDisabled={operator_review_scheduled_loading}
            isSearchable={false}
          />
        )
      },
      {
        dataField: 'deliver_to',
        text: translate_text("Deliver to", userData?.settings?.language),
        formatter: (cell, row) => (
          <Select
            options={deliver_options}
            value={deliver_options.filter(item => item.value === cell)[0]}
            onChange={(value) => this.props.operatorReviewScheduledUpdate({
              id: row.id,
              deliver_to: value.value,
            })}
            isDisabled={operator_review_scheduled_loading}
            isSearchable={false}
          />
        )
      },
      {
        dataField: 'repeat_frequency',
        text: translate_text("Repeat", userData?.settings?.language),
        formatter: (cell, row) => (
          <Select
            options={repeat_options}
            value={repeat_options.filter(item => item.value === cell)[0]}
            onChange={(value) => this.props.operatorReviewScheduledUpdate({
              id: row.id,
              repeat_frequency: value.value,
            })}
            isDisabled={operator_review_scheduled_loading}
            isSearchable={false}
          />
        )
      },
      /*
      {
        dataField: 'prompt',
        text: translate_text("Ai Prompt", userData?.settings?.language),
        headerClasses: 'text-center',
        classes: 'text-center',
        formatter: (cell, row) => (
          <Button
            size="sm"
            onClick={() => this.setState({ edit_prompt_id: row.review_id })}
          >Edit</Button>
        )
      },
      */
      {
        dataField: 'df1',
        isDummyField: true,
        text: translate_text("Delete", userData?.settings?.language),
        headerClasses: 'text-center',
        classes: 'text-center',
        formatter: (cell, row) => (
          <Button
            size="sm"
            color="danger"
            onClick={() => this.setState({ delete_id: row.id })}
            disabled={operator_review_scheduled_loading}
          >Delete</Button>
        )
      }
    ]
    return  columns
  }

  componentDidMount = () => {
    this.props.operatorReviewScheduledGetAll();
  }

  render() {
    const {
      operator_review_scheduled,
    } = this.props;

    return (
      <div className="main-content view-reviews main-content-fixed-width">
        <div className="page-header">
          <h2 className="page-title"><Translate>Scheduled Reviews</Translate></h2>
          <div className="controls">
            <NavLink to="/rating/reviews/create" className="btn btn-primary">Create Review</NavLink>
          </div>
        </div>
        <div className="page-content">
          <Card>
            <CardBody className="table-wrapper">
              {this.state.reviews_loading &&
                <div className="card-loading">
                  <div className="loading-indicator">
                    <Spinner color="secondary" /> <span>Loading...</span>
                  </div>
                </div>
              }
              <BootstrapTable
                remote
                keyField="id"
                columns={this.generateColumns()}
                bordered={false}
                data={operator_review_scheduled ? operator_review_scheduled : []}
                noDataIndication={<p className="my-4 text-center"><Translate>You don't have any scheduled reviews, try to create one.</Translate></p>}
              />
            </CardBody>
          </Card>
        </div>
        {this.state.edit_prompt_id &&
        <Modal isOpen={true} toggle={() => this.setState({ edit_prompt_id: null })} centered>
          <ModalHeader toggle={() => this.setState({ edit_prompt_id: null })}>Edit Ai prompt</ModalHeader>
          <ModalBody>
            <Input
              type="textarea"
              rows="4"
              value={""}
              onChange={(e) => console.log(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.setState({ edit_prompt_id: null })}>Close</Button>
            <Button color="primary">Save changes</Button>
          </ModalFooter>
        </Modal>
        }
        <ConfirmModal
          message="Are you sure want to delete this scheduled review?"
          yesColor="danger"
          isOpen={this.state.delete_id !== null}
          toggle={() => this.setState({ delete_id: null })}
          onConfirm={() => {
            this.props.operatorReviewScheduledDelete({
              schedule: this.state.delete_id,
            })
            this.setState({ delete_id: null });
          }}
        />
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    operator_review_scheduled: state.Dashboard.get("operator_review_scheduled"),
    operator_review_scheduled_loading: state.Dashboard.get("operator_review_scheduled_loading"),
  }),
  {
    operatorReviewScheduledGetAll,
    operatorReviewScheduledDelete,
    operatorReviewScheduledUpdate,
  }
)(Reviews);
