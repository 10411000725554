import React, { Component } from 'react';
import { connect } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { momentDisplayAge } from "../../helpers/utils";
import {
  Badge,
  Button,
  Input,
  Label,
  //InputGroup,
  Spinner,
  UncontrolledPopover,
  PopoverBody,
  FormGroup,
} from 'reactstrap';
import adminActions from "../../redux/admin/actions";
import authActions from "../../redux/auth/actions";
import { FlagIcon } from 'react-flag-kit';
//import { getName as geCountryName } from "country-list";

const { adminGetUsers, adminUpdateAppSettings } = adminActions;
const { adminLogin } = authActions;

class Users extends Component {
  state = {
    page: 1,
    sizePerPage: 50,
    sortOrder: 'desc',
    sortField: 'id',
    search_data: "",
    show_only_active: true,
    search_used: false
  }

  componentDidMount = () => {
    this.searchData();
  }

  searchData = (data) => {
    const { userData } = this.props;
    this.props.adminGetUsers({
      count: userData?.app_settings?.admin_pagination_count
        ? userData?.app_settings?.admin_pagination_count
        : this.state.sizePerPage,
      only_active: data?.show_only_active
        ? data?.show_only_active === 'true'
          ? true
          : ""
        : this.state.show_only_active,
      query: data?.search_data?.trim()
        ? data?.search_data?.trim()
        : ""
    });
    this.setState({ search_used: data?.search_data !== undefined })
  }

  displayCountry = (row) => {
    const lng = row?.settings?.language;
    if(!lng){ return null; }
    return (
      <span>{lng}&nbsp;
        <FlagIcon
          size={14}
          code={lng}
        /></span>
    )
  }

  handleTableChange = (type, newState) => {
    this.setState({
      sizePerPage: newState.sizePerPage,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
      page: newState.page,
    });

    this.props.adminGetUsers({
      count: newState.sizePerPage,
      page: newState.page,
      sort_field: newState.sortField,
      sort: newState.sortOrder,
    });

    this.props.adminUpdateAppSettings({
      admin_pagination_count: newState.sizePerPage,
    })
  }

  generateColumns = (login_working) => {
    const columns = [
      {
        dataField: 'username',
        text: 'Username',
      },
      {
        dataField: 'country',
        text: 'Country',
        formatter: (cell, row) => (
          this.displayCountry(row)
        )
      },
      {
        dataField: 'website_ratings',
        text: '# of ratings',
      },
      {
        dataField: 'website_reps',
        text: '# of reps',
      },
      {
        dataField: 'website_employees',
        text: '# of employees',
      },
      {
        dataField: 'website_slack',
        text: 'Slack?',
      },
      {
        dataField: 'created_at',
        text: 'Account age',
        formatter: (cell, row) => (
          <small>{momentDisplayAge(cell)}</small>
        )
      },
      {
        dataField: 'is_subscription_active',
        text: 'Subscription',
        sort: true,
        formatter: (cell, row) => (
          cell ? <Badge color="success">Active</Badge> : <Badge>Inactive</Badge>
        )
      },
      {
        dataField: 'website_domain',
        text: 'Website Domain',
      },
      {
        dataField: 'additional_data',
        text: "Data",
        sort: false,
        headerClasses: 'text-center',
        classes: 'text-center',
        formatter: (cell, row) => (
          <div>
            <Button color="link" size="sm" id={`popover-${row.id}`}>View</Button>
            <UncontrolledPopover
              target={`popover-${row.id}`}
              placement="top"
              trigger="hover"
            >
              <PopoverBody>
                <div><b>Website ID: </b>{row.website_id}</div>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        )
      },
      {
        dataField: 'tools',
        text: 'Tools',
        formatter: (cell, row) => (
          <Button
            color="primary"
            className="btn btn-primary btn-sm"
            disabled={login_working}
            onClick={() => this.props.adminLogin({id: row.id})}
          >Admin Login</Button>
        )
      },
    ];
    return columns;
  }

  render() {
    const {
      users_list,
      users_list_loading,
      login_working,
      userData,
    } = this.props;

    return (
      <div className="admin-users">
        <h3 className="admin-title">Users Data</h3>
        <div className="hstack gap-3 mb-3">
            <Label>Search user</Label>
            <Input
              placeholder="Username"
              value={this.state.search_data}
              onChange={(e) => this.setState({search_data: e.target.value})}
              style={{ maxWidth: "20rem" }}
            />
            <FormGroup check>
              <Label check>
              <Input
                type="checkbox"
                checked={this.state.show_only_active}
                onChange={(e) => {
                  this.setState({show_only_active: !this.state.show_only_active});
                  this.searchData({show_only_active: !this.state.show_only_active ? "true" : "false"});
                }}
              /> Show only active users
              </Label>
            </FormGroup>
            <Button
              color="primary"
              onClick={() => this.searchData({search_data: this.state.search_data})}
              disabled={users_list_loading || !this.state.search_data}
            >
              Search
            </Button>
        </div>
        {(this.state.search_used && !users_list_loading) &&
        <div className="hstack gap-3 mb-3">
          <span>Showing users with query "<strong>{this.state.search_data}</strong>"</span>
          <Button
            color="link"
            size="sm"
            onClick={() => {
              this.setState({
                search_data: "",
                search_used: false
              })
              this.searchData()
            }}
          >Clear search</Button>
        </div>
        }
        <div className="table-wrapper">
          {users_list_loading &&
          <div className="card-loading">
            <div className="loading-indicator">
              <Spinner color="secondary" /> <span>Loading...</span>
            </div>
          </div>
          }
          {/*
          <div className="table-controls">
            <InputGroup style={{ maxWidth: "20rem" }}>
              <Input placeholder="Search username" />
              <Button color="primary">Search</Button>
            </InputGroup>
          </div>
          */}
          <BootstrapTable
            remote
            keyField="id"
            columns={this.generateColumns(login_working)}
            bordered={false}
            classes="table-users"
            data={users_list?.items ? users_list?.items : []}
            pagination={paginationFactory({
              page: this.state.page,
              sizePerPage: userData?.app_settings?.admin_pagination_count
                ? userData?.app_settings?.admin_pagination_count
                : this.state.sizePerPage,
              totalSize: users_list?.total ? users_list?.total : 0
            })}
            sort={
              {
                dataField: this.state.sortField,
                order: this.state.sortOrder,
              }
            }
            onTableChange={this.handleTableChange}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    users_list: state.Admin.get("users_list"),
    users_list_loading: state.Admin.get("users_list_loading"),
    login_working: state.Auth.get("login_working"),
    userData: state.User.get("userData"),
  }),
  {
    adminGetUsers,
    adminLogin,
    adminUpdateAppSettings,
  }
)(Users);
