import { Map } from "immutable";

const initState = new Map({
  users_list: [],
  users_list_loading: false,
  app_settings_loading: false,
  website_count: null,
  file_url: null,
  file_uploading: false,
  announcements: [],
  announcements_working: false,
});

export default function adminReducer(state = initState, action) {
	switch (action.type) {

		case "ADMIN_ANNOUNCEMENT_DELETE":
			return state
				.set("announcements_working", true)
		case "ADMIN_ANNOUNCEMENT_DELETE_SUCCESS":
			return state
        .set("announcements", [...state.get("announcements").filter(item => item.id !== action.data.data.id)])
				.set("announcements_working", false)
		case "ADMIN_ANNOUNCEMENT_DELETE_FAILED":
			return state
				.set("announcements_working", false)

		case "ADMIN_ANNOUNCEMENT_UPDATE":
			return state
				.set("announcements_working", true)
		case "ADMIN_ANNOUNCEMENT_UPDATE_SUCCESS":
      let announcements_update = [...state.get("announcements")]
      announcements_update = announcements_update.map(item => {
        if(item.id === action.data.data.id){
          return action.data.data;
        }
        return item;
      })
			return state
        .set("announcements", [...announcements_update])
				.set("announcements_working", false)
		case "ADMIN_ANNOUNCEMENT_UPDATE_FAILED":
			return state
				.set("announcements_working", false)

		case "ADMIN_ANNOUNCEMENT_ALL":
			return state
				.set("announcements_working", true)
		case "ADMIN_ANNOUNCEMENT_ALL_SUCCESS":
			return state
        .set("announcements", [...action.data.data])
				.set("announcements_working", false)
		case "ADMIN_ANNOUNCEMENT_ALL_FAILED":
			return state
				.set("announcements_working", false)

		case "ADMIN_ANNOUNCEMENT_CREATE":
			return state
				.set("announcements_working", true)
		case "ADMIN_ANNOUNCEMENT_CREATE_SUCCESS":
      let announcements_create = [...state.get("announcements")]
      announcements_create.unshift(action.data.data)
			return state
        .set("announcements", [...announcements_create])
				.set("announcements_working", false)
		case "ADMIN_ANNOUNCEMENT_CREATE_FAILED":
			return state
				.set("announcements_working", false)

		case "ADMIN_FILE_UPLOAD":
			return state
				.set("file_url", null)
				.set("file_uploading", true)
		case "ADMIN_FILE_UPLOAD_SUCCESS":
			return state
				.set("file_url", action.data.data?.file)
				.set("file_uploading", false)
		case "ADMIN_FILE_UPLOAD_FAILED":
			return state
				.set("file_url", null)
				.set("file_uploading", false)

		case "ADMIN_WEBSITE_COUNT":
			return state
				.set("website_count", null)
		case "ADMIN_WEBSITE_COUNT_SUCCESS":
			return state
				.set("website_count", action.data.data)
		case "ADMIN_WEBSITE_COUNT_FAILED":
			return state
				.set("website_count", null)

		case "ADMIN_UPDATE_APP_SETTINGS":
			return state
				.set("app_settings_loading", true)
		case "ADMIN_UPDATE_APP_SETTINGS_SUCCESS":
			return state
				.set("app_settings_loading", false)
		case "ADMIN_UPDATE_APP_SETTINGS_FAILED":
			return state
				.set("app_settings_loading", false)

		case "ADMIN_GET_USERS":
			return state
				.set("users_list", [])
				.set("users_list_loading", true)
		case "ADMIN_GET_USERS_SUCCESS":
			return state
				.set("users_list", action.data.users)
				.set("users_list_loading", false)
		case "ADMIN_GET_USERS_FAILED":
			return state
				.set("users_list", [])
				.set("users_list_loading", false)

		default:
			return state;
  }
}
