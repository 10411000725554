import React, { Component } from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Alert, Button, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavItem, UncontrolledDropdown } from 'reactstrap';
import { crispLogo, intercomLogo, logo } from '../../../assets/images';
import './style.css';
import 'react-toastify/dist/ReactToastify.css';
import Translate from "../../../shared/components/Translate";
import authActions from "../../../redux/auth/actions";
import userActions from "../../../redux/user/actions";
import Announcement from "../Announcement";

const {
  userGetData,
  userAlertDismiss,
  userAnnouncementGet,
  userAnnouncementDismiss,
} = userActions;
const { userLogout } = authActions;

class TopBar extends Component {
  state = {
    user_data_loaded: false,
    partner: 'intercom'
  }

  componentDidMount = () => {
    if(!this.props.userData?.id_user){
      this.props.userGetData();
      this.props.userAnnouncementGet();
    }
  }

  componentDidUpdate = (pp) => {
    if(!pp.userData?.id_user && !this.state.user_data_loaded){
      this.props.userGetData();
      this.setState({ user_data_loaded: true });
    }
  }

  render() {
    const { userData, user_settings_loading } = this.props;
    console.log("userData:", userData);

    return (
      <div className="top-bar">
        <Navbar expand="lg">
          <div className="d-flex align-items-center gap-2">
            <NavbarBrand href="/rating/dashboard">
              <img
                src={logo}
                alt="Logo"
              />
            </NavbarBrand>
            <span className="text-warning">&#43;</span>
            { userData?.website?.intercom_token ?
              <img src={intercomLogo} alt="Intercom Logo" height={22} />
            :
              <img src={crispLogo} alt="Crisp Logo" height={22} />
            }
          </div>
          {userData?.top_alert &&
            <Alert color="danger" className="global-alert">
              <span className="text">{userData?.top_alert?.message}</span>
              <Button
                color="danger"
                onClick={() => this.props.userAlertDismiss({id: userData?.top_alert?.id})}
                disabled={user_settings_loading}
              >Dismiss</Button>
            </Alert>
          }
          {this.props.announcement?.id &&
            <Announcement
              isOpen={this.props.announcement?.id ? true : false}
              toggle={() => this.props.userAnnouncementDismiss()}
              data={this.props.announcement}
            />
          }
          <Nav navbar className="ms-auto">
            <NavItem>
              {userData?.website?.domain &&
              <a
                href={`//${userData?.website?.domain}`}
                className="web-info nav-link"
                target="_blank"
                rel="noreferrer"
              >
                {userData?.website?.logo &&
                <img
                  src={userData?.website?.logo}
                  alt=""
                  className="web-logo"
                />
                }
                {!userData?.website?.logo && userData?.website?.name &&
                  <span className="hstack gap-1"><span>{userData?.website?.name}</span> <span className="icon icon-16 icon-link-external bg-primary" /></span>
                }
              </a>
              }
            </NavItem>
            <NavItem>
              <UncontrolledDropdown inNavbar>
                <DropdownToggle caret nav>
                  <Translate>Settings</Translate>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem tag="span">
                    <NavLink to="/rating/settings/integrations"><span className="icon icon-slack" /> <Translate>Slack Integration</Translate></NavLink>
                  </DropdownItem>
                  <DropdownItem tag="span">
                    <NavLink to="/rating/settings/segmentation"><span className="icon icon-segments" /> <Translate>Segmentation</Translate></NavLink>
                  </DropdownItem>
                  <DropdownItem tag="span">
                    <NavLink to="/rating/settings/survey"><span className="icon icon-survey" /> <Translate>Survey</Translate></NavLink>
                  </DropdownItem>
                  {this.props.userData?.id_user_type === 1 &&
                  <DropdownItem tag="span">
                    <NavLink to="/rating/settings/employee_accounts"><span className="icon icon-users" /> <Translate>Employee Accounts</Translate></NavLink>
                  </DropdownItem>
                  }
                  <DropdownItem tag="span">
                    <NavLink to="/rating/settings/filter_sources"><span className="icon icon-filter" /> <Translate>Filter Sources</Translate></NavLink>
                  </DropdownItem>
                  <DropdownItem tag="span">
                    <NavLink to="/rating/settings/api"><span className="icon icon-api" /> <Translate>API Access</Translate></NavLink>
                  </DropdownItem>
                  <DropdownItem tag="span">
                    <NavLink to="/rating/settings/webhooks"><span className="icon icon-webhooks" /> <Translate>Webhooks</Translate></NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
            <NavItem>
              <UncontrolledDropdown inNavbar>
                <DropdownToggle caret nav>
                  <Translate>Welcome</Translate>, {userData?.username}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag="span">
                    <NavLink to="/rating/settings/my_account"><span className="icon icon-user" /> <Translate>My Account</Translate></NavLink>
                  </DropdownItem>
                  <DropdownItem tag="span">
                    <NavLink to="/rating/settings/my_account"><span className="icon icon-lock" /> <Translate>Change Password</Translate></NavLink>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem tag="span">
                    <button
                      className="btn btn-link text-danger"
                      onClick={() => {
                        this.props.userLogout();
                        window.$crisp.push(["do", "session:reset"]);
                      }}
                    ><span className="icon icon-logout bg-danger" /> <Translate>Logout</Translate></button>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
          </Nav>
        </Navbar>
        <ToastContainer />
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    user_settings_loading: state.User.get("user_settings_loading"),
    announcement: state.User.get("announcement"),
  }),
  {
    userLogout,
    userGetData,
    userAlertDismiss,
    userAnnouncementGet,
    userAnnouncementDismiss,
  }
)(TopBar);
