const settingsActions = {
  SETTINGS_AVAILABLE_LANGUAGES_GET: 'SETTINGS_AVAILABLE_LANGUAGES_GET',

  settingsGetAvailableLanguages: (data) => ({
    type: settingsActions.SETTINGS_AVAILABLE_LANGUAGES_GET,
    data
  }),
}

export default settingsActions;
