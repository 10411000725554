import React, { Component } from 'react';
import {DropdownToggle, DropdownMenu, Dropdown } from 'reactstrap';
import { Calendar } from 'react-date-range';
import format from 'date-fns/format';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Translate from '../Translate';

class DateDropdown extends Component {
  render() {
    const {
      isOpen,
      toggle,
      value,
      onChange,
      className,
      rightDropdown
    } = this.props;
    
    return (
      <div className={`date-dropdown ${className ? className : ""}`}>
        <Dropdown isOpen={isOpen} toggle={toggle}>
          <DropdownToggle 
            size="sm" 
            caret 
            outline
            color="light"
          >
            {value !== null ? format(value, "MM/dd/yyyy") : <Translate>Select date</Translate>}
          </DropdownToggle>
          <DropdownMenu 
            end={rightDropdown ? true : false}
          >
           <Calendar
            date={value}
            onChange={onChange}
           />
          </DropdownMenu>
        </Dropdown>
      </div>
    )
  }
}

export default DateDropdown;