import React, { Component } from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import { Nav, NavItem, Button, Badge } from 'reactstrap';
import './style.css';
import { testimonialAuthor } from '../../../assets/images';
import userActions from "../../../redux/user/actions";
import moment from "moment";
import Translate from "../Translate";

const { userUpdateSettings } = userActions;

class LeftNavigation extends Component {

  render() {
    const { userData, user_settings_loading } = this.props;

    return (
      <div className="left-navigation">
        <Nav vertical>
          {userData?.is_admin &&
          <NavItem>
            <NavLink
              exact to="/rating/admin"
              className="nav-link"
            >
              <span className="icon-duo icon-duo-lock" /> <span>ADMIN</span>
            </NavLink>
          </NavItem>
          }
          <NavItem>
            <NavLink
              exact to="/rating/dashboard"
              className="nav-link"
            >
              <span className="icon-duo icon-duo-home" /> <span><Translate>Dashboard</Translate></span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="/rating/reviews"
              className="nav-link"
            >
              <span className="icon-duo icon-duo-review" /> <span><Translate>Reviews</Translate></span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              exact
              to="/rating/all_surveys"
              className="nav-link"
            >
              <span className="icon-duo icon-duo-survey" /> <span><Translate>All Surveys</Translate></span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              exact
              to="/rating/operators"
              className="nav-link"
            >
              <span className="icon-duo icon-duo-support" /> <span><Translate>Support Agents</Translate></span>
            </NavLink>
            <Nav vertical>
              <NavItem>
                <NavLink
                  exact
                  to="/rating/operators/excluded"
                  className="nav-link"
                >• <Translate>Excluded</Translate> <Badge>{userData?.website?.dashboard_exclude_users_list?.length}</Badge></NavLink>
              </NavItem>
            </Nav>
          </NavItem>
          <NavItem>
            <NavLink
              exact
              to="/rating/settings/survey"
              className="nav-link"
            >
              <span className="icon-duo icon-duo-wrench" /> <span><Translate>Customize Surveys</Translate></span>
            </NavLink>
          </NavItem>
          {this.props.userData?.id_user_type === 1 &&
          <NavItem>
            <NavLink
              exact
              to="/rating/settings/employee_accounts"
              className="nav-link"
            >
              <span className="icon-duo icon-duo-users" /> <span><Translate>Employee Accounts</Translate></span>
            </NavLink>
          </NavItem>
          }
          <NavItem>
            <a href="https://ratingfish.helpkit.so/" target="_blank" className="nav-link" rel="noreferrer noopener"><span className="icon-duo icon-duo-help" /> <span><Translate>Help Guide</Translate></span></a>
          </NavItem>
        </Nav>
        {userData?.settings?.testimonials_displayed !== undefined
          && (
            userData?.settings?.testimonials_displayed === null
            || moment().diff(moment(userData?.settings?.testimonials_displayed || "2000-01-01"), 'days') > 30
          )
              ?
              <div className="testimonial-cta">
                <Button
                  className="btn-link btn-close"
                  onClick={(e) => this.props.userUpdateSettings({testimonials_displayed: moment().toISOString()})}
                  disabled={user_settings_loading}
                />
                <p className="mb-1 cta-title">Loving Rating Fish?  We would be beyond thrilled if you could leave us a video review.</p>
                <a
                  href="https://senja.io/p/rating-fish/r/EMqw4k"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-sm btn-secondary mb-3"
                ><Translate>Leave Review</Translate></a>
                <a
                  href="https://senja.io/p/rating-fish/r/EMqw4k"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="testimonial-top">
                    <img src={testimonialAuthor} alt="" className="img-fluid" />
                    <div className="info">
                      <div>
                        <span className="name">Jared L. Herman</span>
                        <span className="title">Co-founder</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-bottom">
                    "Rating Fish provides me real-time insights of what customers think about our support agents."
                  </div>
                </a>
              </div>
              : null

        }
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    user_settings_loading: state.User.get("user_settings_loading"),
  }),
  {
    userUpdateSettings,
  }
)(LeftNavigation);
