export const languages = [
  {label:'Afrikanns', value: 'AF'},
  {label:'Albanian', value: 'SQ'},
  {label:'Arabic', value: 'AR'},
  {label:'Armenian', value: 'HY'},
  {label:'Basque', value: 'EU'},
  {label:'Bengali', value: 'BN'},
  {label:'Bulgarian', value: 'BG'},
  {label:'Catalan', value: 'CA'},
  {label:'Cambodian', value: 'KM'},
  {label:'Chinese (Mandarin)', value: 'ZH'},
  {label:'Croation', value: 'HR'},
  {label:'Czech', value: 'CS'},
  {label:'Danish', value: 'DA'},
  {label:'Dutch', value: 'NL'},
  {label:'English', value: 'EN'},
  {label:'Estonian', value: 'ET'},
  {label:'Fiji', value: 'FJ'},
  {label:'Finnish', value: 'FI'},
  {label:'French', value: 'FR'},
  {label:'Georgian', value: 'KA'},
  {label:'German', value: 'DE'},
  {label:'Greek', value: 'EL'},
  {label:'Gujarati', value: 'GU'},
  {label:'Hebrew', value: 'HE'},
  {label:'Hindi', value: 'HI'},
  {label:'Hungarian', value: 'HU'},
  {label:'Icelandic', value: 'IS'},
  {label:'Indonesian', value: 'ID'},
  {label:'Irish', value: 'GA'},
  {label:'Italian', value: 'IT'},
  {label:'Japanese', value: 'JA'},
  {label:'Javanese', value: 'JW'},
  {label:'Korean', value: 'KO'},
  {label:'Latin', value: 'LA'},
  {label:'Latvian', value: 'LV'},
  {label:'Lithuanian', value: 'LT'},
  {label:'Macedonian', value: 'MK'},
  {label:'Malay', value: 'MS'},
  {label:'Malayalam', value: 'ML'},
  {label:'Maltese', value: 'MT'},
  {label:'Maori', value: 'MI'},
  {label:'Marathi', value: 'MR'},
  {label:'Mongolian', value: 'MN'},
  {label:'Nepali', value: 'NE'},
  {label:'Norwegian', value: 'NO'},
  {label:'Persian', value: 'FA'},
  {label:'Polish', value: 'PL'},
  {label:'Portuguese', value: 'PT'},
  {label:'Punjabi', value: 'PA'},
  {label:'Quechua', value: 'QU'},
  {label:'Romanian', value: 'RO'},
  {label:'Russian', value: 'RU'},
  {label:'Samoan', value: 'SM'},
  {label:'Serbian', value: 'SR'},
  {label:'Slovak', value: 'SK'},
  {label:'Slovenian', value: 'SL'},
  {label:'Spanish', value: 'ES'},
  {label:'Swahili', value: 'SW'},
  {label:'Swedish ', value: 'SV'},
  {label:'Tamil', value: 'TA'},
  {label:'Tatar', value: 'TT'},
  {label:'Telugu', value: 'TE'},
  {label:'Thai', value: 'TH'},
  {label:'Tibetan', value: 'BO'},
  {label:'Tonga', value: 'TO'},
  {label:'Turkish', value: 'TR'},
  {label:'Ukranian', value: 'UK'},
  {label:'Urdu', value: 'UR'},
  {label:'Uzbek', value: 'UZ'},
  {label:'Vietnamese', value: 'VI'},
  {label:'Welsh', value: 'CY'},
  {label:'Xhosa', value: 'XH'},
]