import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import moment from "moment";
import DateIntervalDropdown from '../../shared/components/DateIntervalDropdown';
import { Card, CardBody, CardTitle, Label, Table, Button } from 'reactstrap';
import { amazing, bad, great, ok, terrible } from '../../assets/images';
import { NavLink } from 'react-router-dom';
import ReactWordcloud from 'react-wordcloud';
import dashboardActions from "../../redux/dashboard/actions";
import OperatorEditInfoModal from './OperatorEditInfoModal';
import userActions from "../../redux/user/actions";
import Translate from "../../shared/components/Translate";

const {
  dashboardOperatorReview,
  dashboardOperatorWordCloud,
  dashboardOperatorReviewUpdate,
} = dashboardActions;

const {
  userSlackGetUsers,
} = userActions;

class OperatorReview extends Component {
  state = {
    selectedInterval: {value: 'current_month', label: <Translate>This Month</Translate>},
    showIntervalDropdown: false,
    range: {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      key: 'selection'
    },
    showEditOperatorModal: false
  }

  componentDidMount = () => {
    const data = {
      user_id: this.props.match?.params?.operator_id,
    }
    this.props.dashboardOperatorReview(data);
    this.props.dashboardOperatorWordCloud(data);
    this.props.userSlackGetUsers();
  }

  toggleIntervalDropdown = () => {
    this.setState({
      showIntervalDropdown: !this.state.showIntervalDropdown
    })
  }

  customRangesChange = (ranges) => {
    this.setState({
      range: ranges['selection'],
    })
  }

  selectInterval = (selected) => {
    this.setState({ selectedInterval: selected })
    const data = {
      user_id: this.props.match?.params?.operator_id,
      interval: selected.value,
    }
    this.props.dashboardOperatorReview(data);
  }

  applyRange = () => {
    const { range } = this.state;

    const start = moment(range.startDate).format("YYYYMMDD");
    const end = moment(range.endDate).format("YYYYMMDD");
    this.selectInterval({value: `${start}-${end}`, label: <Translate>Custom</Translate>});
  }

  createTimeDropdown = () => {
    const today = moment().format("YYYYMMDD");
    const start_month = moment().startOf('month').format("YYYYMMDD");
    const start_year = moment().startOf('year').format("YYYYMMDD");
    const start_quarter = moment().startOf('quarter').format("YYYYMMDD");
    const end_quarter = moment().endOf('quarter').format("YYYYMMDD");

    const options = [
      {value: `${start_month}-${today}`, label: <Translate>This Month</Translate>},
      {value: `${start_quarter}-${end_quarter}`, label: <Translate>This Quarter</Translate>},
      {value: `${start_year}-${today}`, label: <Translate>This Year</Translate>},
    ]
    return options;
  }

  editOperator = (user_id) => {
    this.setState({ showEditOperatorModal: true })
  }

  render() {
    const { operators_data_review, operators_data_word_cloud } = this.props;

    //console.log('OPERATOR DATA REVIEW:', operators_data_review);

    return (
      <div className="main-content view-single-operator main-content-fixed-width">
        <div className="page-header">
          <div>
            <h2 className="page-title"><Translate>Review for</Translate> {operators_data_review?.operator?.first_name}</h2>
              <NavLink to="/rating/operators" className="hstack gap-1 mt-1"><span className="icon icon-back bg-primary" /> <span><Translate>Back</Translate></span></NavLink>
          </div>
          <div className="controls">
            <div>
              <Label><Translate>Period</Translate></Label>
              <DateIntervalDropdown
                isOpen={this.state.showIntervalDropdown}
                toggle={this.toggleIntervalDropdown}
                selected={this.state.selectedInterval}
                options={this.createTimeDropdown()}
                onSelect={this.selectInterval}
                showCustom
                customRanges={[this.state.range]}
                customRangesChange={this.customRangesChange}
                applyRange={this.applyRange}
                rightDropdown
              />
            </div>
            {/*
            <div>
              <Label>Share</Label>
              <div className="hstack gap-1">
                <button type="button" className="btn btn-white"><BsPrinter /></button>
                <button type="button" className="btn btn-white"><BsEnvelope /></button>
                <button type="button" className="btn btn-white"><BsSlack /></button>
              </div>
            </div>
            */}
          </div>
        </div>
        <div className="page-content">
          <Card className="card-review">
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <CardTitle className="mb-0"><Translate>Basic Info</Translate></CardTitle>
                <div>
                  <Button
                    color="link"
                    className="text-secondary p-0"
                    onClick={() => this.editOperator(operators_data_review?.operator?.user_id)}
                  ><span className="icon icon-edit bg-secondary" /> <Translate>Edit Info</Translate></Button>
                </div>
              </div>
              <div className="profile-operator">
                <span
                  className="avatar"
                  style={{ backgroundImage: `url(${operators_data_review?.operator?.avatar})` }}
                >
                </span>
                <div className="text ms-3">
                  <Table borderless size="sm" className="align-middle">
                    <tbody>
                      <tr>
                        <th><Translate>Name</Translate>:</th>
                        <td>
                          {operators_data_review?.operator?.first_name} {operators_data_review?.operator?.last_name}
                          {operators_data_review?.operator?.slack_id &&
                            <span className="slack text-muted ms-2"><span className="icon icon-slack" /></span>
                          }
                        </td>
                      </tr>
                      <tr>
                        <th><Translate>Location</Translate>:</th>
                        <td>{operators_data_review?.operator?.location_country}</td>
                      </tr>
                      <tr>
                        <th><Translate>Tenure</Translate>:</th>
                        <td>
                          {
                            operators_data_review?.operator?.active_since
                              ? moment(operators_data_review?.operator?.active_since).format("L")
                              : null
                          }
                        </td>
                      </tr>
                      <tr>
                        <th><Translate>Language</Translate>:</th>
                        <td>{operators_data_review?.operator?.language}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="card-review">
            <CardBody>
              <CardTitle><Translate>Review Result</Translate></CardTitle>
              {operators_data_review?.review_data ?
                <p>
                  <Translate special_data="operator_review_review_result_1">During this review period you have had</Translate> <strong>{operators_data_review?.review_data?.stats?.total_conversations}</strong> conversations with customers.&nbsp;
                  <strong>{operators_data_review?.review_data?.stats?.sum}</strong>&nbsp;
                  <Translate special_data="operator_review_review_result_3">
                    of those customers left a ratings for you. Below is the rating distribution for your performance in this review period.
                  </Translate>
                </p>
                :
                <p>
                  <Translate special_data="operator_review_review_result_1">During this review period you have had</Translate> <strong><Translate>zero</Translate></strong> <Translate special_data="operator_review_review_result_2"> conversations with customers</Translate>.
                </p>
              }
              <div className="achievement">
                <div className="achievement-item">
                  <img src={amazing} alt="Amazing" />
                  <span className="percentage">{operators_data_review?.review_data?.rating_5_avg || 0}%</span>
                </div>
                <div className="achievement-item">
                  <img src={great} alt="Great" />
                  <span className="percentage">{operators_data_review?.review_data?.rating_4_avg || 0}%</span>
                </div>
                <div className="achievement-item">
                  <img src={ok} alt="OK" />
                  <span className="percentage">{operators_data_review?.review_data?.rating_3_avg || 0}%</span>
                </div>
                <div className="achievement-item">
                  <img src={bad} alt="Bad" />
                  <span className="percentage">{operators_data_review?.review_data?.rating_2_avg || 0}%</span>
                </div>
                <div className="achievement-item">
                  <img src={terrible} alt="Terrible" />
                  <span className="percentage">{operators_data_review?.review_data?.rating_1_avg || 0}%</span>
                </div>
              </div>
            </CardBody>
          </Card>
          {operators_data_review?.review_data &&
          <Card>
            <CardBody>
              <CardTitle><Translate>Word Cloud</Translate></CardTitle>
                <p><Translate special_data="operator_review_review_result_4">Below is your captured word cloud based on reviews left for you during last 30 days</Translate>.</p>
              <div className="word-cloud">
                <ReactWordcloud
                  words={operators_data_word_cloud}
                  options={{
                    padding: 1,
                    rotations: 0,
                    rotationAngles: [0, 0],
                    deterministic: true,
                    fontFamily: "arial",
                    fontWeight: "bold",
                    fontSizes: [16, 60],
                    scale: "linear",
                    enableTooltip: false,
                  }}
                />
              </div>
            </CardBody>
          </Card>
        }
        </div>
        <OperatorEditInfoModal
          isOpen={this.state.showEditOperatorModal}
          toggle={() => this.setState({ showEditOperatorModal: !this.state.showEditOperatorModal })}
          slack_users={this.props.slack_users}
          slack_users_loading={this.props.slack_users_loading}
          operator={operators_data_review.operator}
          dashboardOperatorReviewUpdate={this.props.dashboardOperatorReviewUpdate}
        />
      </div>
    )
  }
}

export default connect(
  state => ({
    operators_data_review: state.Dashboard.get("operators_data_review"),
    operators_data_review_loading: state.Dashboard.get("operators_data_review_loading"),
    operators_data_word_cloud: state.Dashboard.get("operators_data_word_cloud"),
    slack_users: state.User.get("slack_users"),
    slack_users_loading: state.User.get("slack_users_loading"),
  }),
  {
    dashboardOperatorReview,
    dashboardOperatorWordCloud,
    userSlackGetUsers,
    dashboardOperatorReviewUpdate,
  }
)(withRouter(OperatorReview));
