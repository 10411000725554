import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { FormGroup, Input, Button, Form, Card, CardBody } from 'reactstrap';
import { logo } from '../../assets/images';
import authActions from "../../redux/auth/actions";
import { withRouter } from "react-router";

const { userPasswordForgot } = authActions;

class ForgotPassword extends Component {
  state = {
    emailSent: false,
    email: "",
  }

  sendEmail = () => {
    this.props.userPasswordForgot({email: this.state.email})
    this.setState({ emailSent: true })
  }

  render() {
    return (
      <main>
        <div className="centered-content-wrapper">
          <div className="text-center mb-4">
            <img src={logo} alt="Logo" />
            <h1 className="h5 mt-2">Dashboard</h1>
          </div>
          <Card className="border-none">
            <CardBody>
              <div className="auth-box">
                {!this.state.emailSent ?
                <React.Fragment>
                  <div className="text-center mb-5">
                    <h2 className="h5">Reset Password</h2>
                    <p>Enter your email address and we'll send you the instructions for resetting the password.</p>
                  </div>
                  <Form>
                    <FormGroup>
                      <Input
                        placeholder="Email"
                        value={this.state.email}
                        onChange={(e) => this.setState({ email: e.target.value })}
                      />
                    </FormGroup>
                    <Button
                      color="primary"
                      block
                      onClick={this.sendEmail}
                      disabled={
                        !this.state.email
                          || !/\S+@\S+\.\S+/.test(this.state.email)
                          || this.props.reset_password_working
                      }
                    >Reset password</Button>
                  </Form>
                </React.Fragment>
                :
                <div className="text-center mb-5">
                  <h2 className="h5">Email Sent</h2>
                  <p>
                    We have sent password reset instructions to your email. <br/>
                    Did not receive the email? <strong>Please check your spam folder.</strong>
                  </p>
                  <Link to="/login">Sign In</Link>
                </div>
                }
              </div>
            </CardBody>
          </Card>
          {!this.state.emailSent && <Link to="/login" className="pt-3">Sign in</Link>}
        </div>
      </main>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      reset_password_working: state.Auth.get("reset_password_working"),
    }),
    {
      userPasswordForgot,
    }
  )(ForgotPassword)
);
