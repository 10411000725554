const authActions = {
	USER_LOGIN: 'USER_LOGIN',
	USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
	USER_LOGIN_ERROR: 'USER_LOGIN_ERROR',

	USER_LOGOUT: 'USER_LOGOUT',

	USER_REFRESH_TOKEN: 'USER_REFRESH_TOKEN',
	USER_REFRESH_TOKEN_SUCCESS: 'USER_REFRESH_TOKEN_SUCCESS',
	USER_REFRESH_TOKEN_ERROR: 'USER_REFRESH_TOKEN_ERROR',

	USER_REFRESH_TOKEN_LOCAL: 'USER_REFRESH_TOKEN_LOCAL',
	USER_REGISTER: 'USER_REGISTER',

	ADMIN_LOGIN: 'ADMIN_LOGIN',
	ADMIN_LOGIN_SUCCESS: 'ADMIN_LOGIN_SUCCESS',
	ADMIN_LOGIN_ERROR: 'ADMIN_LOGIN_ERROR',

	USER_PASSWORD_FORGOT: 'USER_PASSWORD_FORGOT',
	USER_PASSWORD_FORGOT_SUCCESS: 'USER_PASSWORD_FORGOT_SUCCESS',
	USER_PASSWORD_FORGOT_ERROR: 'USER_PASSWORD_FORGOT_ERROR',

	USER_PASSWORD_FORGOT_RESET: 'USER_PASSWORD_FORGOT_RESET',
	USER_PASSWORD_FORGOT_RESET_SUCCESS: 'USER_PASSWORD_FORGOT_RESET_SUCCESS',
	USER_PASSWORD_FORGOT_RESET_ERROR: 'USER_PASSWORD_FORGOT_RESET_ERROR',

	userPasswordForgotReset: data => ({
		type: authActions.USER_PASSWORD_FORGOT_RESET,
		data
	}),
	userPasswordForgotResetSuccess: data => ({
		type: authActions.USER_PASSWORD_FORGOT_RESET_SUCCESS,
		data
	}),
	userPasswordForgotResetError: data => ({
		type: authActions.USER_PASSWORD_FORGOT_RESET_ERROR,
		data
	}),

	userPasswordForgot: data => ({
		type: authActions.USER_PASSWORD_FORGOT,
		data
	}),
	userPasswordForgotSuccess: data => ({
		type: authActions.USER_PASSWORD_FORGOT_SUCCESS,
		data
	}),
	userPasswordForgotError: data => ({
		type: authActions.USER_PASSWORD_FORGOT_ERROR,
		data
	}),

	adminLogin: data => ({
		type: authActions.ADMIN_LOGIN,
		data
	}),
	adminLoginSuccess: data => ({
		type: authActions.ADMIN_LOGIN_SUCCESS,
		data
	}),
	adminLoginError: data => ({
		type: authActions.ADMIN_LOGIN_ERROR,
		data
	}),

	userRegister: (data) => ({
		type: authActions.USER_REGISTER,
    data,
	}),

	userRefreshTokenLocal: () => ({
		type: authActions.USER_REFRESH_TOKEN_LOCAL,
	}),

	userRefreshToken: () => ({
		type: authActions.USER_REFRESH_TOKEN,
	}),
	userRefreshTokenSuccess: data => ({
		type: authActions.USER_REFRESH_TOKEN_SUCCESS,
		data
	}),
	userRefreshTokenError: () => ({
		type: authActions.USER_REFRESH_TOKEN_ERROR,
	}),

	userLogout: (data) => ({
		type: authActions.USER_LOGOUT,
		data
	}),

	userLogin: data => ({
		type: authActions.USER_LOGIN,
		data
	}),
	userLoginSuccess: data => ({
		type: authActions.USER_LOGIN_SUCCESS,
		data
	}),
	userLoginError: data => ({
		type: authActions.USER_LOGIN_ERROR,
		data
	}),
}

export default authActions;

