const adminActions = {
  ADMIN_GET_USERS: 'ADMIN_GET_USERS',
  ADMIN_UPDATE_APP_SETTINGS: 'ADMIN_UPDATE_APP_SETTINGS',
  ADMIN_UPDATE_APP_SETTINGS_SUCCESS: 'ADMIN_UPDATE_APP_SETTINGS_SUCCESS',
  ADMIN_UPDATE_APP_SETTINGS_FAILED: 'ADMIN_UPDATE_APP_SETTINGS_FAILED',
  ADMIN_WEBSITE_COUNT: 'ADMIN_WEBSITE_COUNT',
  ADMIN_FILE_UPLOAD: 'ADMIN_FILE_UPLOAD',
  ADMIN_FILE_UPLOAD_SUCCESS: 'ADMIN_FILE_UPLOAD_SUCCESS',
  ADMIN_FILE_UPLOAD_FAILED: 'ADMIN_FILE_UPLOAD_FAILED',
  ADMIN_ANNOUNCEMENT_CREATE: 'ADMIN_ANNOUNCEMENT_CREATE',
  ADMIN_ANNOUNCEMENT_ALL: 'ADMIN_ANNOUNCEMENT_ALL',
  ADMIN_ANNOUNCEMENT_UPDATE: 'ADMIN_ANNOUNCEMENT_UPDATE',
  ADMIN_ANNOUNCEMENT_DELETE: 'ADMIN_ANNOUNCEMENT_DELETE',

  adminAnnouncementDelete: (data) => ({
    type: adminActions.ADMIN_ANNOUNCEMENT_DELETE,
    data
  }),

  adminAnnouncementUpdate: (data) => ({
    type: adminActions.ADMIN_ANNOUNCEMENT_UPDATE,
    data
  }),

  adminAnnouncementAll: (data) => ({
    type: adminActions.ADMIN_ANNOUNCEMENT_ALL,
    data
  }),

  adminAnnouncementCreate: (data) => ({
    type: adminActions.ADMIN_ANNOUNCEMENT_CREATE,
    data
  }),

  adminFileUploadSuccess: (data) => ({
    type: adminActions.ADMIN_FILE_UPLOAD_SUCCESS,
    data
  }),

  adminFileUploadFailed: (data) => ({
    type: adminActions.ADMIN_FILE_UPLOAD_FAILED,
    data
  }),

  adminFileUpload: (data) => ({
    type: adminActions.ADMIN_FILE_UPLOAD,
    data
  }),

  adminWebsiteCount: (data) => ({
    type: adminActions.ADMIN_WEBSITE_COUNT,
    data
  }),
  adminUpdateAppSettings: (data) => ({
    type: adminActions.ADMIN_UPDATE_APP_SETTINGS,
    data
  }),
  adminUpdateAppSettingsSuccess: (data) => ({
    type: adminActions.ADMIN_UPDATE_APP_SETTINGS_SUCCESS,
    data
  }),
  adminUpdateAppSettingsFailed: (data) => ({
    type: adminActions.ADMIN_UPDATE_APP_SETTINGS_FAILED,
    data
  }),

  adminGetUsers: (data) => ({
    type: adminActions.ADMIN_GET_USERS,
    data
  }),
}

export default adminActions;
