import React, { Component } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import Translate from '../Translate';

class ConfirmModal extends Component {
  render() {
    const {
      message,
      yesColor,
      noColor,
      isOpen,
      toggle,
      onConfirm
    } = this.props;
    return (
      <Modal isOpen={isOpen} centered>
        <ModalBody>
          <p className="text-center h6">
            <Translate>
              {message}
            </Translate>
          </p>
          <div className="d-flex justify-content-center mt-4 gap-2">
            <Button 
              color={noColor ? noColor : "secondary"}
              onClick={toggle}
            >
              <Translate>No</Translate>
            </Button>
            <Button 
              color={yesColor ? yesColor : "success"}
              onClick={onConfirm}
            >
              <Translate>Yes</Translate>
            </Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

export default ConfirmModal